import React, { useState } from 'react'

const MasteryViewFormativeAssessments = () => {

  const [change1, setChange1] = useState(false);
  const [change2, setChange2] = useState(false);
  const [change3, setChange3] = useState(false);
  const [change4, setChange4] = useState(false);

  const clickMe1 = () => {
    setChange1(!change1)
    setChange2(false)
    setChange3(false)
    setChange4(false)

  }
  const clickMe2 = () => {
    setChange1(false)
    setChange2(!change2)
    setChange3(false)
    setChange4(false)

  }
  const clickMe3 = () => {
    setChange1(false)
    setChange2(false)
    setChange3(!change3)
    setChange4(false)

  }

  const clickMe4 = () => {
    setChange1(false)
    setChange2(false)
    setChange3(false)
    setChange4(!change4)

  }


  return (

    <>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#24a159" }} >
          <div className="container p-1">
            <div className="row p-2 text-white d-flex justify-content-between">
              <div className='col-lg-2'>
                <h5 className='ps-lg-3' style={{ fontSize: "1.125rem", fontWeight: "700", letterSpacing: "2px" }} > MASTERY </h5>
              </div>
              <div className="col-lg-4">
                <div className='ps-lg-3'>
                  <h5 className='' style={{ fontSize: "1.125rem", fontWeight: "700", letterSpacing: "2px" }} > Mastery View Formative Assessments </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container p-5">
          <div className="row p-4 pt-5 d-flex justify-content-around ">
            <div className="col-lg-5">
              <div>
                <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >PRE-BUILT FORMATIVE ASSESSMENTS</p>
                <h2 className='h1FontFamily' style={{ fontSize: "3.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Assessment.  <span className='' style={{ color: "#24a159", letterSpacing: "1px", fontWeight: "800" }}> To the Power of Student Mastery. </span></h2>
              </div>
            </div>
            <div className="col-lg-5">
              <p className='mb-5' style={{ fontSize: "1.2rem", color: "#143d50" }}> Mastery View Formative Assessments are short, expertly-created formatives that clearly and efficiently reveal students’ mastery of key learning standards. Available in select states for the 2022-23 school year. </p>
              <div className='pt-4 '>
                <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S CONNECT <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container mt-5 mb-5 p-4">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-5 p-0">
                <img className='imgRESOPNSIVE' src={require("../../img/mvfa1.png")} alt='' />
                <div className='ps-lg-5 pt-5 ms-lg-2'>
                  <p className='fontFamilyHeader' style={{ fontSize: "1.55rem", color: '#287a9f', fontWeight: "800", lineHeight: "1.3" }} > " With assessment based on the DCM—such as the Mastery View Formative Assessments—we can get to the heart of what students know and don’t know and give teachers the reliable and actionable data they need. Item Response Theory (IRT) just can’t do that." </p>
                  <h6 className='fontFamilyHeader pt-2' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "800" }} > — Jonathan Templin, Ph.D. </h6>
                  <h6 className='fontFamilyHeader' style={{ fontSize: "1.13rem", color: '#287a9f', fontWeight: "400" }}> Creator of the Diagnostic Classification Model and Professor of Educational Measurement and Statistics at the University of Iowa </h6>
                </div>
              </div>
              <div className="col-lg-6  shadow-lg PLUS-SMALL"  >
                <h2 className='ps-5 ms-4  h1FontFamily mt-5' style={{ color: "#156380", fontSize: "2.5rem", lineHeight: "1.2" }} > Bring effective assessment to your classroom. </h2>
                <div className="table p-4 d-flex justify-content-center">
                  <div className='w-100 ' >
                    <div className='pt-4 '  >
                      <div className='w-100 p-0'  >
                        <button className='text-start bg-body  w-100  p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe1() }}> <h3 className='fs-5'> <b>{change1 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span>  <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className=' ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >  Valid, Reliable, and Informative </span></b></h3></button>
                        <div className=''>
                          {
                            change1 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > While appearing like a familiar online quiz, Mastery View Formative Assessments determine mastery of learning standards using a unique reliability algorithm based on the Diagnostic Classification Model (DCM). </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe2() }}><h3 className='fs-5'> <b> {change2 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Rigorous and Standards-Aligned </span> </b></h3></button>
                        <div >
                          {
                            change2 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Designed by assessment and curriculum experts, Mastery View Formative Assessments are designed to guarantee rigor and are tightly aligned to state learning standards. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start  w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe3() }}> <h3 className='fs-5'><b> {change3 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i></span> : <span> <i className="fa-solid fa-plus " style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Less Testing, more Instruction. </span> </b></h3></button>
                        <div >
                          {
                            change3 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Accurately measure student mastery and academic progress with fewer questions—giving teachers high-quality, actionable insights with assessments that fit within the instructional cycle.  </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe4() }}><h3 className='fs-5'> <b> {change4 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Delivered in Mastery Connect </span> </b></h3></button>
                        <div >
                          {
                            change4 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Administer Mastery View Formatives and receive immediate, actionable feedback about student progress in an intuitive, visual way with Mastery Connect reports and analytics. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row pt-5 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > UTILIZE STIMULUS FUNDS </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Identify learning needs and inform teaching with valid and reliable assessments. </h2>
                  <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.2rem", }} > School closures and remote learning reduced instructional time and disrupted student progress. To move forward, teachers need reliable assessment data to identify learning needs, address potential learning loss, and drive personalized learning plans. That’s where the Mastery View Formative Assessments come in. With Mastery View Formatives, educators can accurately assess student mastery and use informative, actionable data to meet each student’s academic needs. </p>
                </div>
              </div>
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/mvfa2.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row pt-5 d-flex justify-content-around ">
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/mvfa3.png")} alt='' />
              </div>

              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > A NEW WAY TO ASSESS </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > A game-changer for understanding standards mastery. </h2>
                  <p className='pt-1 pb-3' style={{ color: "#143d50", fontSize: "1.3rem", lineHeight: "1.4" }} > A researched-backed, statistically-driven assessment model has made rigorous through-course assessment possible, providing an alternative to traditional testing. With Mastery View Formative Assessments, educators can utilize meaningful and reliable formative data in real-time to personalize the learning experience for each student. </p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Re-teaching</span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Remediation</span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Intervention</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row pt-5 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > POWERFUL MATH & ELA ASSESSMENTS </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Reliably assess learning needs. </h2>
                  <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.1rem", }} > Mastery View Formative Assessments address Math and ELA in Grades 3-8, as well as Algebra I and English I & II. Let us show you which assessments are available in your state. </p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} > For Math, each Mastery View Formative is <p className='ps-lg-4 ms-lg-2'>focused on a single priority learning standard</p> </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600", lineHeight: "1.1" }} >For ELA, each Mastery View Formative is <p className='ps-lg-4 ms-lg-2'> focused on reading comprehension of informational and/or literary texts, assessed through a variety of genres </p> </span></p>
                </div>
              </div>
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/mib3.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row p-lg-5 ">
              <div className="col-lg-4 col-md-12">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE ALL PRODUCTS</p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Explore the Entire Mastery Product Family </h2>
                  <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > Drive learning outcomes and standards mastery with high quality assessments and actionable insights that inform and improve teaching and learning. </p>
                  <div className='pt-4 pb-5'>
                    <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="row">
                  <div className="col-lg">
                    <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2rem", fontWeight: "800" }} > Mastery <br /> Connect </h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.45rem", }} > Drive student outcomes with actionable, standards-based insights that improve teaching and learning, leading to even more helpful insights and even better teaching and learning. </p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg pt-5">
                      <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                        <div className='p-3'>
                          <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                          <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Mastery View Predictive Assessments</h6>
                          <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Benchmarks that mirror state assessment blueprints, reflect the standards that students have learned, and provide educators with meaningful insights into student progress.</p>
                          <div className=''>
                            <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                <div className="row">
                  <div className="col-lg ">
                    <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Mastery View Formative Assessments</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > A collection of expert-developed, tightly-aligned formative assessments designed with high-quality items that evaluate student mastery.</p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5 ">
            <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
            <div className="row d-flex justify-content-center p-5">

              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/mvfa4.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Ebooks & Buyer’s Guides</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }} > Your Guide to MasteryView Assessments </h4>
                    <div className='pt-lg-5'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/lms4.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Blog Articles</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }}>Rethinking Assessment: Key Considerations for the Future</h4>
                    <div className='pt-lg-3'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/mvfa5.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Blog Articles</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }} > An Intro to MasteryConnect, Instructure's AMS </h4>
                    <div className='pt-lg-5 '>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
          <div className="container  p-lg-5 ">
            <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
              <div className='text-lg-center pb-lg-4'>
                <h2 className='pb-2 UbuntuFONTFAMILY' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready for the next big thing in <br /> assessments? </h2>
                <div className='pt-4 '>
                  <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>

  )
}

export default MasteryViewFormativeAssessments