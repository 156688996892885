import React from 'react'

const ProfessionalDevelopment = () => {
  return (
    <>
    
      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-4">
            <div className="row p-5 pt-5 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >K–12 PROFESSIONAL DEVELOPMENT</p>
                  <h2 className='h1FontFamily' style={{ fontSize: "3.6rem", color: "#287a9f", fontWeight: "800", lineHeight: "1" }} > Better PD in Education = <span className='' style={{ color: "#e4060f", letterSpacing: "1px", fontWeight: "800" }}> Better Teaching and Learning. </span></h2>
                  <p className='mb-4' style={{ fontSize: "1.2rem", color: "#143d50" }}> Quality teaching is the #1 in-school factor in student achievement. Educators need high-quality professional development to help them grow and learn, and the Instructure Learning Platform helps you deliver it at scale. </p>
                  <div className='pt-2 '>
                    <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400", letterSpacing: "2px" }}> REQUEST A DEMO <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/pd1.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row p-4 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > PD THAT BENEFITS EVERYONE </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Professional development tools to help teachers (and students) progress. </h2>
                  <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > The Instructure Learning Platform allows state and district leaders to scale guaranteed, professional development curriculum that promotes collective educator efficacy and accelerates student achievement. Our platform delivers engaging content designed just for teachers via flexible options that fit their busy schedules. </p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Canvas LMS for delivering high-quality PD and <span className='ps-4 ms-2'> analytics. </span> </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Canvas Studio for creating, storing, and sharing <span className='ps-4 ms-2'>video</span>. </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Canvas Catalog for organizing PD across the <span className='ps-4 ms-2'>state or district.</span> </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Consulting on the best PD plan for your district <span className='ps-4 ms-2'>or school.</span> </span></p>
                </div>
              </div>
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/pd2.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-lg-5">
            <div className="row p-lg-4  d-flex justify-content-around ">

              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/pd3.png")} alt='' />
              </div>

              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > LEADERSHIP DEVELOPMENT </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > The best leaders are nonstop learners. </h2>
                  <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Through the Center for Leadership and Learning, Instructure brings school and district leaders together from all over the world. Together, we discuss and share insights about how to improve communication, consistency, culture, and collective teacher efficacy. The Center for Leadership & Learning can help you take your pedagogy to the next level. </p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Dive into leadership strategies for <span className='ps-4 ms-2'> implementing meaningful change.. </span> </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Build a network of other progressive K–12 <span className='ps-4 ms-2'> leaders. </span>. </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Get inspiration to kick off (or recharge) school <span className='ps-4 ms-2'> initiatives. </span> </span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row p-4 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > MAKE IT OFFICIAL </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Become a Certified Canvas Educator. </h2>
                  <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Get to know Canvas LMS inside and out, and get a certification to make your mastery official. Our Certified Canvas Educator program is a series of online courses that will strengthen your hands-on Canvas teaching experience as you learn best practices for online and blended learning. </p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Certify your Canvas LMS knowledge. </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Learn how to create responsive learning <span className='ps-4 ms-2'> environments. </span>. </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Get customized content for school educators.  </span></p>
                </div>
              </div>
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/pd4.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row p-lg-5 ">
              <div className="col-lg-4 col-md-12">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE ALL PRODUCTS</p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Discover the Instructure Learning Platform </h2>
                  <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > The Instructure Learning Platform delivers what you need to enrich, support, and connect every aspect of teaching and learning. </p>
                  <div className='pt-4 pb-5'>
                    <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="row">
                  <div className="col-lg ">
                    <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas LMS</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Course materials. Communication tools. Participation and grades. Data and insights. All part of Canvas LMS. Ready to support learning anywhere. </p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg pt-5">
                    <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms2.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Elevate Analytics </h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > The analytics solution that combines data from multiple sources and makes it easy for districts to put the right information into the hands of educators and administrators. </p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#0097d3", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                <div className="row">
                  <div className="col-lg ">
                    <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Mastery Connect</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Drive student outcomes with actionable, standards-based insights that improve teaching and learning, leading to even more helpful insights and even better teaching and learning.</p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg ">
                    <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms3.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Impact by Instructure</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Improve technology adoption, support teachers and students in their tech use, and evaluate the impact of educational technology on student engagement.</p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#f76400", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5 ">
            <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
            <div className="row d-flex justify-content-center p-5">
              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px" }} src={require("../../img/ass5.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Blog Articles</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }} >Professional Development: Using Canvas as a Resource and a Delivery Platform</h4>
                    <div className='pt-lg-4'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px" }} src={require("../../img/ass7.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Case Studies</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }}>Building Accessibility, Community, & Opportunity</h4>
                    <div className='pt-lg-5 mt-lg-5'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
          <div className="container  p-lg-5 ">
            <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
              <div className='text-lg-center pb-lg-4'>
                <h2 className='pb-2 SolutionDropDownFontFamily' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to jumpstart the perpetual- <br /> improvement machine? </h2>
                <div className='pt-4 '>
                  <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default ProfessionalDevelopment