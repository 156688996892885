import React, { useState } from 'react'

const ElevateK12 = () => {

  const [change1, setChange1] = useState(false);
  const [change2, setChange2] = useState(false);
  const [change3, setChange3] = useState(false);
  const [change4, setChange4] = useState(false);
  const [change5, setChange5] = useState(false);

  const clickMe1 = () => {
    setChange1(!change1)
    setChange2(false)
    setChange3(false)
    setChange4(false)
    setChange5(false)
  }
  const clickMe2 = () => {
    setChange1(false)
    setChange2(!change2)
    setChange3(false)
    setChange4(false)
    setChange5(false)
  }
  const clickMe3 = () => {
    setChange1(false)
    setChange2(false)
    setChange3(!change3)
    setChange4(false)
    setChange5(false)
  }

  const clickMe4 = () => {
    setChange1(false)
    setChange2(false)
    setChange3(false)
    setChange4(!change4)
    setChange5(false)
  }

  const clickMe5 = () => {
    setChange1(false)
    setChange2(false)
    setChange3(false)
    setChange4(false)
    setChange5(!change5)
  }

  return (

    <>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#0097d3" }} >
          <div className="container p-2">
            <div className="row p-2 text-white d-flex justify-content-between">
              <div className='col-lg-2'>
                <h5 className='ps-lg-3' style={{ fontSize: "1.125rem", fontWeight: "600", letterSpacing: "2px" }} >ELEVATE </h5>
              </div>
              <div className="col-lg-4">
                <div className='ps-lg-5 '>
                  <h5 className='ps-lg-5 ' style={{ fontSize: "1.125rem", fontWeight: "600", letterSpacing: "2px" }} > Elevate K-12 Analytics </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container p-5">
          <div className="row p-4 pt-5 d-flex justify-content-around ">
            <div className="col-lg-5">
              <div>
                <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >COMPREHENSIVE ANALYTICS</p>
                <h2 className='h1FontFamily' style={{ fontSize: "3.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Insights to Improve Outcomes. <span className='' style={{ color: "#0097d3", letterSpacing: "1px", fontWeight: "800" }}> For All Students. </span></h2>
              </div>
            </div>
            <div className="col-lg-5">
              <p className='mb-4' style={{ fontSize: "1.2rem", color: "#143d50" }}> Elevate K-12 Analytics is the analytic application that combines data from key district data sources and provides access to highly interactive visualizations of integrated data so educators can measure, manage, and improve student outcomes.</p>
              <div className='pt-4 '>
                <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> REQUEST A DEMO <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row p-4  d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Uncover student needs. </h2>
                  <p className='pt-3' style={{ color: "#143d50", fontSize: "1.25rem", }} > Provide educators with access to comprehensive, integrated data, so they fully understand what is affecting the success of their students. </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="row shadow-lg" style={{ backgroundColor: "#fff", borderRadius: "10px 10px 10px 10px" }} >
                  <div className="col-lg-6 p-0">
                    <img className='w-100 ' style={{ borderRadius: "10px 0px 0px 10px", height: "15rem" }} src={require("../../img/e12-1.png")} alt='' />
                  </div>
                  <div className="col-lg-5  mt-4"  >
                    <div className='ps-3'>
                      <h2 className='h1FontFamily ' style={{ fontSize: "1.4rem", color: "#143d50", lineHeight: "1.4" }} > Using Comprehensive Data to Impact Student Sucess </h2>
                    </div>
                    <div className='ps-1 pt-lg-3'>
                      <button type="button" class="btn btn-link text-decoration-none pt-2" style={{ color: "red", fontWeight: "600", fontSize: ".875rem" }} > READ E-BOOK <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container  p-lg-4">
            <div className="row d-flex justify-content-around">
              <div className="col-lg-5">
                <img className='elevate12' src={require("../../img/e12-2.png")} alt='' />
                <div className='ps-lg-5 ms-lg-2 '>
                  <p className='fontFamilyHeader pt-lg-3' style={{ fontSize: "1.50rem", color: '#287a9f', fontWeight: "800", lineHeight: "1.4" }} > " The dashboards, scorecards and watchlists provide insights into student performance that otherwise would take much longer to realize or run the risk of going unnoticed." </p>
                  <h6 className='fontFamilyHeader pt-2' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "800" }} > —Jonathan Hulbert </h6>
                  <h6 className='fontFamilyHeader' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "400" }}> Director of Accountability and School Transformation, East Central Independent School District, San Antonio, TX </h6>
                </div>
              </div>
              <div className="col-lg-6 PLUS-MINUS-CATALOG p-5 shadow-lg" style={{ backgroundColor: "#fff", borderRadius: "10px 10px 10px 10px" }} >
                <h2 className='ps-5 p-3 h1FontFamily pt-5' style={{ color: "#156380", fontSize: "2.5rem", lineHeight: "1.2" }} > Increase the value and power of your district data.</h2>
                <div className="table p-4 d-flex justify-content-center">
                  <div className='w-100' >
                    <div className=' '  >
                      <div className='w-100 p-0'  >
                        <button className='text-start bg-body  w-100  p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe1() }}> <h3 className='fs-5'> <b>{change1 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span>  <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className=' ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Data You Need, In One Place </span></b></h3></button>
                        <div className=''>
                          {
                            change1 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Elevate K-12 Analytics seamlessly combines key data from disparate sources and systems—like SIS, assessments, behavioral, LMS, attendance, and more—for flexible, rich, real-time analysis. No more siloed data. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe2() }}><h3 className='fs-5'> <b> {change2 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Instant Insights </span> </b></h3></button>
                        <div >
                          {
                            change2 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Hundreds of pre-defined metrics, teacher-ready reports, and outcome-based visualizations delivered out of the box that can be customized for classroom needs, so educators start using them on day one. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start  w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe3() }}> <h3 className='fs-5'><b> {change3 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i></span> : <span> <i className="fa-solid fa-plus " style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Dashboards Anyone Can Use </span> </b></h3></button>
                        <div >
                          {
                            change3 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Give administrators and teachers the information they need to make a difference and drive student success. Elevate dashboards, charts, and visuals are easy to explore, filter, and drill into, so educators of all skill levels can see, analyze, and understand data. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe4() }}><h3 className='fs-5'> <b> {change4 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >  Comprehensive, Multi-year Analytics </span> </b></h3></button>
                        <div >
                          {
                            change4 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Longitudinal analysis is critical and requires integrated, historical data that can be explored by educators and data analysts alike. Elevate K-12 Analytics supports multi-year analytics without the investment required to build and maintain a custom data warehouse. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe5() }}><h3 className='fs-5'> <b> {change5 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Built to Industry Standards </span> </b></h3></button>
                        <div >
                          {
                            change5 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Integrated with the Ed-Fi® data standard and technology to ensure cost-effective, sustained interoperability between district source systems and data sources at scale. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-lg-5">
            <div className="row p-lg-4  d-flex justify-content-around ">

              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/e12-3.png")} alt='' />
              </div>

              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > ACTIONABLE DATA </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Put the right information in the hands of educators at the right time. </h2>
                  <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.1rem", }} > Data-driven instruction depends on comprehensive, useful data. To answer the most impactful questions, teachers need access to a complete picture of their students. With Elevate K-12 Analytics, staff get the information they really need, when they need it (in a user-friendly format). </p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Student scorecards with all data on one screen </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Automated watchlists and early warning reports </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Customizable district accountability dashboards </span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row p-4 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > ASSESSMENT DATA </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Enrich and analyze assessment results. </h2>
                  <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.2rem", }} > Elevate K-12 Analytics easily brings together formative, benchmark, and summative assessment scores from Mastery Connect and other sources with a wide range of other critical data from across the district, such as grades, attendance, ELL and special education enrollment, and disciplinary history. </p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Correlate state test scores to district  <span className='ps-lg-4 ms-lg-2'>assessments</span> </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Identify subgroups for monitoring and  <span className='ps-lg-4 ms-lg-2'>intervention</span>  </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Spot trends in your classroom or the district </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Monitor programs and quantify impact on  <span className='ps-lg-4 ms-lg-2'>outcomes</span>  </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Track progress and growth from year-to-year </span></p>
                </div>
              </div>
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/e12-4.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#F2F8FA" }} >
          <div className="container p-lg-5">

            <div className='d-flex justify-content-center text-center'>
              <div className='' style={{ maxWidth: "40%" }} >
                <p className='' style={{ color: "#143d50", fontWeight: "800", letterSpacing: "2px" }} >MORE FEATURES</p>
                <h4 className='SolutionDropDown pt-2' style={{ color: "#156380", fontSize: "2.3rem", fontWeight: "800", letterSpacing: "2px" }} > More to love about Elevate K-12 Analytics: </h4>
              </div>
            </div>

            <div className="row p-lg-5 d-flex justify-content-center">
              <div className="col-lg-4 col-md-6 ">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Information at Your Fingertips </h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > A secure browser-based interface allows users to easily access the data they need. Anytime, anywhere, from any device. </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Insights at Every Level </h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Data views at the district, school, classroom, and student level provide leaders a line of sight into key metrics while giving teachers the data they need to support each of their students. </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > No Programming Skills Required </h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Rich and flexible analytics that make it easy for users to customize, personalize, and share reports and visuals based on their own classroom needs. </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 ">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Actionable Data </h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Say goodbye to stale reports and siloed information. Elevate K-12 Analytics provides access to up-to-date data so educators can quickly identify programs and students that need attention, as soon as they need it. </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Role-Based Security </h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Roles can be automatically synchronized with district staff positions to ensure PII is always maintained as district staff changes. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-lg-5">
            <div className="row p-lg-5 ">
              <div className="col-lg-4 col-md-12">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE ALL PRODUCTS</p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Discover the Instructure Learning Platform </h2>
                  <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > The Instructure Learning Platform delivers what you need to enrich, support, and connect every aspect of teaching and learning. </p>
                  <div className='pt-4 pb-5'>
                    <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="row">
                  <div className="col-lg">
                    <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.2rem", fontWeight: "800" }} >Canvas LMS</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.35rem", }} > Course materials. Communication tools. Participation and grades. Data and insights. All part of Canvas LMS. Ready to support learning anywhere. </p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg pt-5">
                      <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                        <div className='p-3'>
                          <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms2.png")} alt='' />
                          <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Elevate Data Quality</h6>
                          <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Ensures district data is accurate, complete, and up to date by implementing a continuous, visible process of data-quality improvement.</p>
                          <div className=''>
                            <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#0097d3", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                <div className="row">
                  <div className="col-lg ">
                    <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Mastery Connect</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Drive student outcomes with actionable, standards-based insights that improve teaching and learning, leading to even more helpful insights and even better teaching and learning.</p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-lg-5 ">
            <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
            <div className="row d-flex justify-content-center p-lg-5">
              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/mc7.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Ebooks & Buyer’s Guides</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }} > Address Inequities and Learning Gaps with Integrated Assessment Analytics</h4>
                    <div className='pt-lg-2'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/lms4.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Blog Articles</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }}>Instructure + Ed-Fi: Transforming Education Through Data Interoperability</h4>
                    <div className='pt-lg-2'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/lms4.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Case Studies</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }}>Los Angeles Unified School District: Maximize Success with Elevate Data Quality</h4>
                    <div className='pt-lg-2'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
          <div className="container  p-lg-5 ">
            <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
              <div className='text-lg-center pb-lg-4'>
                <h2 className='pb-2 UbuntuFONTFAMILY' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to unlock the power of your data? </h2>
                <div className='pt-4 '>
                  <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
          <div className="container  p-lg-5 ">
            <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
              <div className='text-lg-center pb-lg-4'>
                <h2 className='pb-2 UbuntuFONTFAMILY' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to unlock the power of your data? </h2>
                <div className='pt-4 '>
                  <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

    </>

  )
}

export default ElevateK12