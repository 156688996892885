import React from 'react'

const Footer = () => {
    return (
        <>
        
            <section>
                <div className="container p-lg-5">
                    <div className="row p-3 d-flex justify-content-around">
                        <div className="col-lg-2 ">
                            <img className='w-100 ' style={{ height: "6rem" }} src={require("../img/HederFooterLogo.png")} alt="icloudsoft_logo" />
                            <div className='ps-lg-4 pt-lg-4 mt-lg-1'>
                                <h6 className='' style={{ color: "#4e6f7e", fontSize: ".875rem", fontWeight: "800", letterSpacing: "3px" }} >GLOBAL HQ</h6>
                                <p className='pt-lg-2' style={{ color: "#143d50", fontSize: ".875rem" }} >6330 South 3000 East Suite 700 Salt Lake City, UT 84121 USA</p>

                            </div>
                            <div className='ms-lg-2 pb-3'>
                                <div>
                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", letterSpacing: "1px", fontWeight: "700" }} >CONTACT US <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                </div>
                                <div>
                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", letterSpacing: "1px", fontWeight: "700" }} >9527468898 <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 pb-4">
                            <div className='pt-lg-5'>
                                <h6 className='width40' style={{ color: "#4e6f7e", fontSize: "1rem", fontWeight: "700" }} >INSTRUCTURE  LEARNING  PLATFORM</h6>
                                <div className='pt-2' style={{ color: "#143d50", fontSize: "1rem" }} >
                                    <p className=' mb-1'>Canvas LMS</p>
                                    <p className=' mb-1'>Canvas Studio</p>
                                    <p className=' mb-1'>Canvas Catalog</p>
                                    <p className=' mb-1'>Assessment</p>
                                    <p className=' mb-1'>Elevate Analytics</p>
                                    <p className=' mb-1'>Impact</p>
                                    <p className=' mb-1'>LearnPlatform</p>
                                    <p className=' mb-1'>Services</p>
                                    <p className=' mb-1'>Leadership Development</p>
                                    <p className=' mb-1'>All Products</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 pb-4">
                            <div className='pt-lg-5'>
                                <h6 className='' style={{ color: "#4e6f7e", fontSize: "1rem", fontWeight: "700" }}>RESOURCES</h6>
                                <div className='pt-2' style={{ color: "#143d50", fontSize: "1rem" }}>
                                    <p className=' mb-1'>Articles</p>
                                    <p className=' mb-1'>Research</p>
                                    <p className=' mb-1'>Infographics</p>
                                    <p className=' mb-1'>Guides & e-Books</p>
                                    <p className=' mb-1'>Webinars</p>
                                    <p className=' mb-1'>Videos</p>
                                    <p className=' mb-1'>Case Studies</p>
                                    <p className=' mb-2 mt-2' style={{ color: "#4e6f7e", fontSize: "1rem", fontWeight: "700" }} >CENTERS</p>
                                    <p className=' mb-1'>The Study Hall</p>
                                    <p className=' mb-1'>Our Community</p>
                                    <p className=' mb-1'>Canvas Network</p>
                                    <p className=' mb-1'>Integrations</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 pb-4">
                            <div className='pt-lg-5'>
                                <h6 className='' style={{ color: "#4e6f7e", fontSize: "1rem", fontWeight: "700" }}>EVENTS</h6>
                                <div className='pt-2' style={{ color: "#143d50", fontSize: "1rem" }}>
                                    <p className=' mb-1'>News & Events</p>
                                    <p className=' mb-1'>Webinars</p>
                                    <p className=' mb-1'>Media Resources</p>
                                    <p className=' mb-1'>Press Releases</p>
                                    <p className=' mb-2 mt-3' style={{ color: "#4e6f7e", fontSize: "1rem", fontWeight: "700" }} >CUSTOMERS</p>
                                    <p className=' mb-1'>K-12</p>
                                    <p className=' mb-1'>Higher Ed</p>
                                    <p className=' mb-1'>Corp Edu</p>
                                    <p className=' mb-1'>For Our Partners</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 pb-4">
                            <div className='pt-lg-5'>
                                <h6 className='' style={{ color: "#4e6f7e", fontSize: "1rem", fontWeight: "700" }}>ABOUT US</h6>
                                <div className='pt-2' style={{ color: "#143d50", fontSize: "1rem" }}>
                                    <p className=' mb-1'>Our Story</p>
                                    <p className=' mb-1'>Leadership</p>
                                    <p className=' mb-1'>Partners</p>
                                    <p className=' mb-1'>Careers</p>
                                    <p className=' mb-1'>Investor Relations</p>
                                    <p className=' mb-1'>Canvas Overview</p>
                                    <p className=' mb-1'>Evidence as a Service</p>
                                    <p className=' mb-1'>Privacy</p>
                                    <p className=' mb-1'>Security</p>
                                    <p className=' mb-1'>Accessibility</p>
                                    <p className=' mb-1'>Contact Us</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#E1E9F0" }} >
                    <div className="container">
                        <div className='text-center p-3 ps-lg-5 ms-lg-4 d-flex justify-content-start' >
                        Copyright © 2008-2023 instructure, Inc. All rights reserved. Various trademarks held by their respective owners.
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer