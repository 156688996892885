import React from 'react'

const ForStudentsParents = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-4">
                        <div className="row p-5 pt-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >FOR K–12 STUDENTS & FAMILIES</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "3.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1" }} > Teachers, Students, and Families <span className='' style={{ color: "#e4060f", letterSpacing: "1px", fontWeight: "800" }}> on the Same Page. </span></h2>
                                    <p className='mb-4 pt-2' style={{ fontSize: "1.2rem", color: "#143d50" }}> Think of Canvas LMS as your student's digital classroom. It's a simple, supportive system where teachers provide lessons and communication to students and families to keep the learning going strong—both in the classroom and online. </p>
                                    <div className='pt-2 '>
                                        <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400", letterSpacing: "2px" }}> LET'S CONNECT <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../../img/SP1.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-5">
                        <div className="row p-lg-4  d-flex justify-content-around ">

                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../../img/SP2.png")} alt='' />
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > FOR STUDENTS & FAMILIES </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > All you need to know in one place—your digital classroom. </h2>
                                    <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.2rem", }} > K–12 students and families want to know what kids need to do, when it’s due, and how they can get help when they need it. Canvas LMS keeps it all in one place. With one login. Plus we offer apps students and parents can use from their mobile devices. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-4 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > CLARITY & CONSISTENCY </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Be prepared for whatever’s next. </h2>
                                    <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Whether it’s today’s assessment, next week’s assignment, or an unexpected online video class, Canvas LMS is the open, easy-to-use system that supports every aspect of learning—and student success. </p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Homepages for every course </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Easy ways to communicate with teachers </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Clear expectations for all </span></p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../../img/SP3.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-5">
                        <div className="row p-lg-4  d-flex justify-content-around ">

                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../../img/SP4.png")} alt='' />
                                <div className='ps-lg-4 ms-lg-2'>
                                    <p className='fontFamilyHeader pt-lg-5' style={{ fontSize: "1.50rem", color: '#287a9f', fontWeight: "800", lineHeight: "1.4" }} > " It just goes to show that students can grow, succeed, and enjoy their learning if they're engaged and having fun. " </p>
                                    <h6 className='fontFamilyHeader pt-2' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "800" }} >  — Todd Miller </h6>
                                    <h6 className='fontFamilyHeader' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "400" }}> Teacher, Arlington Independent School District </h6>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > EASY TO LEARN, EASY TO USE </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > The tool that supports your student's learning journey. </h2>
                                    <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Learn Canvas LMS once—and count on it every step of the way. When adopted at the district level, Canvas LMS provides a consistent academic experience from kindergarten through high school and beyond. And it offers a simple way to connect with other digital tools (without a bazillion passwords). That means students can focus on learning the material in front of them instead of learning the technology all over again. </p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > One sign-on accesses other digital tools </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Unique features just for little learners </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Builds confidence as your child progresses in <span className='ps-4 ms-2'> education </span> </span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-5 ">
                        <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
                        <div className="row d-flex justify-content-center p-lg-5">
                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../../img/mc7.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Ebooks & Buyer’s Guides</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }} > Measuring What Matters: Validity and Reliability in Assessment </h4>
                                        <div className='pt-lg-3 mt-lg-4'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../../img/SP5.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Blog Articles</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }}>Ensure EdTech Efficacy with ESSA Evidence</h4>
                                        <div className='pt-lg-5 mt-lg-4'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../../img/FD3.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Case Studies</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }}>Gain Visibility into your Edtech Ecosystem with the Free Inventory Dashboard</h4>
                                        <div className='pt-lg-1'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
                    <div className="container  p-lg-5 ">
                        <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
                            <div className='text-lg-center pb-lg-4'>
                                <h2 className='pb-2 SolutionDropDownFontFamily' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready for Canvas? Canvas is ready for you. </h2>
                                <div className='pt-4 '>
                                    <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ForStudentsParents