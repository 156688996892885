import React from 'react'

const ForAdministrators = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-4">
                        <div className="row p-5 pt-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >FOR K-12 ADMINISTRATORS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "3.6rem", color: "#287a9f", fontWeight: "800", lineHeight: "1" }} > K–12 leadership. <span className='' style={{ color: "#e4060f", letterSpacing: "1px", fontWeight: "800" }}> To the power of Instructure. </span></h2>
                                    <p className='mb-4 pt-2' style={{ fontSize: "1.2rem", color: "#143d50" }}> Built on openness in every way, the Instructure Learning Platform was made to keep K–12 teachers, students, and parents engaged, connected, learning, and thriving through anything. </p>
                                    <div className='pt-2 '>
                                        <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400", letterSpacing: "2px" }}> LET'S CONNECT <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                    <div className='pt-4 '>
                                        <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#fff", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400", letterSpacing: "2px" }}> LET'S CONNECT <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/FA.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-5">
                        <div className="row p-lg-4  d-flex justify-content-around ">

                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/FA1.png")} alt='' />
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > FOR ADMINISTRATORS </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Actionable insights are essential. </h2>
                                    <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Who’s learning what? How can you better support your schools and teachers? Our platform gives you school and district-level insights into student engagement, academic performance, and a whole lot more, so you can use it to enact learning initiatives and improve outcomes. Not to mention supporting professional development that leads to even better student learning. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container p-5">
                        <div className="row p-4 d-flex justify-content-center text-center SolutionDropDownFontFamily">
                            <div className='SolutionDropDownFontFamily pb-lg-4'>
                                <h6 className='' style={{ color: "#143d50", fontSize: ".875rem", lineHeight: "1.5", letterSpacing: "1px", fontWeight: "700" }} >GIVE STUDENTS WHAT THEY NEED</h6>
                                <h6 className='pb-2' style={{ color: "#156380", fontSize: "3rem", lineHeight: "1.2", letterSpacing: "1px", fontWeight: "700" }} >We have you covered.</h6>
                                <p className='' style={{ color: "#444", fontSize: "1.125rem" }} >Supporting schools and districts in making learning personal. For <br /> every teacher and student. Every single day.</p>
                            </div>
                            <div className="col-lg-3">
                                <img className='w-100' src={require("../../img/FA2.png")} alt='' />
                                <h6 className='' style={{ color: "#156380", fontSize: "1.85rem", fontWeight: "800" }} >Learning Management</h6>
                                <div className=''>
                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <img className='w-100' src={require("../../img/FA3.png")} alt='' />
                                <h6 className='' style={{ color: "#156380", fontSize: "1.85rem", fontWeight: "800" }} >Assessment</h6>
                                <div className=''>
                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <img className='w-100' src={require("../../img/FA4.png")} alt='' />
                                <h6 className='' style={{ color: "#156380", fontSize: "1.85rem", fontWeight: "800" }} >Analytics</h6>
                                <div className=''>
                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <img className='w-100' src={require("../../img/FA5.png")} alt='' />
                                <h6 className='' style={{ color: "#156380", fontSize: "1.85rem", fontWeight: "800" }} >Professional Development</h6>
                                <div className=''>
                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-4 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > ONE PLATFORM </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > All the digital tools you need for every step of teaching and learning. </h2>
                                    <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > The Instructure Learning Platform is a collection of teaching-and-learning solutions that support you in driving success for both teachers and students. Whether you're tackling an equitable access initiative, supporting blended or online learning, or improving assessment practice, Instructure will help you reach your goals. And, because we play nice with others, we connect with your existing tools. </p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Provide consistency and powerful teaching <span className='ps-4 ms-2' > experiences with Canvas by Instructure </span> </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Drive standards-based teaching and learning <span className='ps-4 ms-2' > with Mastery by Instructure </span> </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Get the data and analytics you need with <span className='ps-4 ms-2' > Elevate by Instructure </span> </span></p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/FACOL1.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-5">
                        <div className="row p-lg-4  d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/FACOL2.png")} alt='' />
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > TEACHER PD </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Support professional development. </h2>
                                    <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Quality teaching is the #1 in-school factor in student achievement. Fortunately, our platform allows states and districts to scale guaranteed professional development curriculum that promotes collective educator efficacy, better equipping teachers to accelerate student achievement. </p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Deliver high-quality PD and analytics </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Get consulting on the best plan for your needs </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Organize PD efforts across the district or state </span></p>
                                </div>
                            </div>
                            <div className='p-lg-5 ps-lg-5 ms-lg-5'>
                                <p className=' SolutionDropDownFontFamily pb-2' style={{ fontSize: "1.425rem", color: "#287a9f", fontWeight: "800" }} > "As we move forward in our preparation for the school year, we are using Canvas to deliver personalized professional development at scale for all of our teachers. Although each teacher may be in a different place with their Canvas usage and knowledge, they should all have access to the same resources and materials needed for growth." </p>
                                <h6 className=' SolutionDropDownFontFamily' style={{ fontSize: "1.125rem", color: "#287a9f", fontWeight: "600" }} > — Keith Konyk </h6>
                                <h6 className=' SolutionDropDownFontFamily' style={{ fontSize: "1.125rem", color: "#287a9f", fontWeight: "400" }} > Assistant Superintendent at Elizabeth Forward School District </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-lg-5 ">
                            <div className="col-lg-4 col-md-12">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE ALL PRODUCTS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Discover the Instructure Learning Platform </h2>
                                    <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > The Instructure Learning Platform delivers what you need to enrich, support, and connect every aspect of teaching and learning. </p>
                                    <div className='pt-4 pb-5'>
                                        <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="row">
                                    <div className="col-lg ">
                                        <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas LMS</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Course materials. Communication tools. Participation and grades. Data and insights. All part of Canvas LMS. Ready to support learning anywhere. </p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg pt-5">
                                        <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms2.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Elevate Analytics </h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > The analytics solution that combines data from multiple sources and makes it easy for districts to put the right information into the hands of educators and administrators. </p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#0097d3", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                                <div className="row">
                                    <div className="col-lg ">
                                        <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.4rem", fontWeight: "800" }} >Mastery connect</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Drive student outcomes with actionable, standards-based insights that improve teaching and learning, leading to even more helpful insights and even better teaching and learning.</p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg ">
                                        <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms3.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Impact by Instructure</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Improve technology adoption, support teachers and students in their tech use, and evaluate the impact of educational technology on student engagement.</p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#f76400", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-5 ">
                        <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
                        <div className="row d-flex justify-content-center p-lg-5">
                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/mc7.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Ebooks & Buyer’s Guides</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }} > The LMS Workbook for K-12 Leaders </h4>
                                        <div className='pt-lg-5 mt-lg-5'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/lms4.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Blog Articles</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }}>Building an Effective Learning Ecosystem</h4>
                                        <div className='pt-lg-5 mt-lg-5'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/lms4.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Case Studies</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }}>New Caney Independent School District: Building a Learning Ecosystem With Canvas & MasteryConnect</h4>
                                        <div className='pt-lg-2'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
                    <div className="container  p-lg-5 ">
                        <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
                            <div className='text-lg-center pb-lg-4'>
                                <h2 className='pb-2 SolutionDropDownFontFamily' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to explore our learning ecosystem? </h2>
                                <div className='pt-4 '>
                                    <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ForAdministrators