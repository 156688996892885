import React from 'react'

const Mastery = () => {
    return (

        <>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#24a159" }} >
                    <div className="container p-2">
                        <div className="row p-2 text-white d-flex justify-content-between">
                            <div className='col-lg-2'>
                                <h5 className='ps-lg-3' style={{ fontSize: "1.125rem", fontWeight: "600", letterSpacing: "2px" }} >MASTERY </h5>
                            </div>
                            <div className="col-lg-4">
                                <div className='ps-lg-5 '>
                                    <h5 className='ps-lg-5 ' style={{ fontSize: "1.125rem", fontWeight: "600", letterSpacing: "2px" }} > Mastery Product Offerings </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-4">
                        <div className="row p-5 pt-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >MASTERY BY INSTRUCTURE</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "3.6rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Assessment. <span className='' style={{ color: "#24a159", letterSpacing: "1px", fontWeight: "800" }}> For Learning. </span></h2>
                                    <p className='mb-4' style={{ fontSize: "1.2rem", color: "#143d50" }}> Our philosophy is simple: thoughtful assessment makes a real, positive impact, and the better teachers understand where students are, the better they can help them succeed. So, how do you get the insights needed to drive learning forward? With Mastery by Instructure. </p>
                                    <div className='pt-4 '>
                                        <button className='SolutionDropDownFontFamily' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "12px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET’S CONNECT  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/mastery.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-5">

                        <div className='d-flex justify-content-center text-center'>
                            <div className='' style={{ maxWidth: "40%" }} >
                                <p className='' style={{ color: "#143d50", fontWeight: "800", letterSpacing: "2px" }} >OUR PRODUCTS</p>
                                <h4 className='UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "700", letterSpacing: "2px" }} > The Mastery Product Family </h4>
                                <p className='mt-3 h1FontFamily1' style={{ color: "#444", fontSize: "1.125rem" }} > Bringing together the assessment tools and quality content educators need to drive learning outcomes. </p>
                            </div>
                        </div>

                        <div className="row p-lg-5 ">
                            <div className="col-lg-4 col-md-6 ">
                                <div className='p-lg-3'>
                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Mastery Connect</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} >An assessment management system designed to show learning in an intuitive, visual way by tying assessment data to actionable insights.</p>
                                    <ul>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.2rem" }} >Clearly see students’ mastery of standards</li>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.2rem" }} >Inform instructional decisions</li>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.2rem" }} >Supercharge collaboration</li>
                                    </ul>
                                    <div className=''>
                                        <button type="button" class="btn btn-link text-decoration-none" style={{ fontSize: ".875rem", lineHeight: "1.2", letterSpacing: "1px", color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Mastery Item Bank</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Standards-based items that check for understanding, evaluate learning, and provide immediate feedback.</p>
                                    <ul>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.1rem" }} >Create formatives that work for you</li>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.1rem" }} >Leverage expertly-developed items</li>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.2rem" }} >Know what students know, by standard</li>
                                    </ul>
                                    <div className=''>
                                        <button type="button" class="btn btn-link text-decoration-none" style={{ fontSize: ".875rem", lineHeight: "1.2", letterSpacing: "1px", color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Mastery View Predictive Assessments</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Rigorous benchmarks that mirror state assessment blueprints and provide meaningful insights into student progress.  </p>
                                    <ul>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.2rem" }} > Measure standards mastery </li>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.2rem" }} > Make decisions based on reliable data </li>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.2rem" }} > Predict student outcomes on state tests </li>
                                    </ul>
                                    <div className=''>
                                        <button type="button" class="btn btn-link text-decoration-none" style={{ fontSize: ".875rem", lineHeight: "1.2", letterSpacing: "1px", color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row ps-lg-4 d-flex justify-content-center ">
                            <div className="col-lg-4 ">
                                <div className='p-lg-3'>
                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Mastery View Formative Assessments </h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > A collection of tightly-aligned formatives designed with high-quality items that effectively assess and address individual learning needs.</p>
                                    <ul>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.2rem" }} > Administer reliable classroom assessments </li>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.2rem" }} > Assess progress as part of the instructional cycle </li>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.2rem" }} > Understand learning needs and inform teaching </li>
                                    </ul>
                                    <div className=''>
                                        <button type="button" class="btn btn-link text-decoration-none" style={{ fontSize: ".875rem", lineHeight: "1.2", letterSpacing: "1px", color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 ">
                                <div className='p-lg-3'>
                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Mastery College Prep </h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > A proven practice test that provides insights to help students prepare students for success on the ACT. </p>
                                    <ul>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.2rem" }} >Predict ACT performance </li>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.2rem" }} >Mirrors the content and length of the ACT</li>
                                        <li className='pt-1 pb-3' style={{ fontSize: "1.2rem" }} >More data points than other practice tests</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row pt-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > INFORM AND IMPROVE INSTRUCTION </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Mastery has you covered. </h2>
                                    <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.1rem", }} > Assessment is key to the teaching and learning process. Understand what your students know through high-quality, effective assessments. Then use those meaningful insights to get students where they need to be. </p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Assess understanding</span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Identify needs</span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Make informed decisions</span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Accelerate learning for every student</span></p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/canvas2.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="conatiner-fluid">
                    <div className="container p-5">
                        <div className="row p-lg-5 ">
                            <div className="col-lg-4 col-md-12">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE OUR PRODUCTS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.8rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Discover the Instructure Learning Platform </h2>
                                    <p className='pt-3' style={{ color: "#143d50", fontSize: "1.25rem", lineHeight: "1.3" }} > The Instructure Learning Platform delivers what you need to enrich, support, and connect every aspect of teaching and learning.</p>
                                    <div className='pt-4 pb-5'>
                                        <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                                <div className="col-lg ">
                                    <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                        <div className='p-3'>
                                            <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms2.png")} alt='' />
                                            <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Elevate by Instructure</h6>
                                            <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Provide educators, administrators, and district leaders with actionable data coupled with powerful analytical tools so they can effectively measure, manage, and improve outcomes.</p>
                                            <div className=''>
                                                <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#0097d3", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="col-lg">
                                    <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                        <div className='p-3'>
                                            <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms3.png")} alt='' />
                                            <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Impact by Instructure</h6>
                                            <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Improve technology adoption, support teachers and students in their tech use, and evaluate the impact of educational technology on student engagement. </p>
                                            <div className=''>
                                                <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#f76400", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5 ">
                        <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
                        <div className="row d-flex justify-content-center p-5">
                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px",height:"12rem" }} src={require("../../img/lms4.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Blog Articles</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }} >Rethinking Assessment: Key Considerations for the Future</h4>
                                        <div className='pt-lg-5 mt-lg-4'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px",height:"12rem" }} src={require("../../img/ms4.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Ebooks & Buyer’s Guides</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }}>Key Strategies for Impactful Formative Assessment</h4>
                                        <div className='pt-lg-5 mt-lg-4'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px",height:"12rem" }} src={require("../../img/ms5.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Blog Articles</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }}>An Intro to MasteryView Assessments: Accelerate Learning with Better Assessment</h4>
                                        <div className='pt-lg-1'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
                    <div className="container  p-lg-5 ">
                        <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
                            <div className='text-lg-center pb-lg-4'>
                                <h2 className='pb-2 UbuntuFONTFAMILY' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to explore our learning ecosystem? </h2>
                                <div className='pt-4 '>
                                    <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default Mastery