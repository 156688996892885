import React from "react";
import "./TheStudyHall.css";
import { NavLink } from "react-router-dom";

function TheStudyHall() {
  return (
    <>
      <section
        style={{
          backgroundImage: `url(${require("../../img/mystudypage.jpg")})`,
          backgroundAttachment: "local",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: " center,center ",
        }}
      >
        <div className="container main-container">
          <div
            className="row p-lg-5 d-flex justify-content-around align-items-center"
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          >
            <div className="col-lg-6 p-4">
              <div>
                <p
                  style={{
                    fontSize: ".875rem",
                    lineHeight: "1.5",
                    textTransform: "uppercase",
                    letterSpacing: ".12em",
                    fontWeight: "700",
                    marginBottom: "5px",
                  }}
                >
                  Resource Center
                </p>
              </div>
              <div className="mb-3">
                <h1 className="UbuntuFONTFAMILY">
                  <p className="p-0 m-0 title-font-text" style={{}}>
                    The Instructure{" "}
                  </p>
                  <span id="font-text-red" className="title-font-text">
                    Study Hall
                  </span>
                </h1>
              </div>
              <div className="study-para-text">
                <p className="fontFamilyHeader">
                  Explore our content library to stay ahead of the learning
                  curve. The Instructure Study Hall is full of the thought
                  leadership articles, customer stories, research reports,
                  videos, infographics, and product overviews that K-12 and
                  Higher Education need to succeed.
                </p>
              </div>
            </div>

            <div className="col-lg-6 ">
              <div
                className="row d-flex align-items-center"
                style={{ padding: "15px" }}
              >
                <div className="col-lg-6 col-5">
                  <div className="">
                    <img
                      className="research-img"
                      src={require("../../img/Resources.jpg")}
                      alt="resource-contain"
                    ></img>
                  </div>
                </div>
                <div className="col-lg-6 col-6 reserch-content">
                  <div>
                    <h6 className="research-font">Research</h6>
                    <p className="h1FontFamily research-text">
                      State of Student Success and Engagement in Higher
                      Education
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="row d-flex  align-items-center"
                style={{ padding: "15px" }}
              >
                <div className="col-lg-6 col-5">
                  <div className="">
                    <img
                      className="research-img"
                      src={require("../../img/resourcePic-2.jpg")}
                      alt="resource-contain"
                    ></img>
                  </div>
                </div>
                <div className="col-lg-6 col-6 reserch-content">
                  <div>
                    <h6 className="research-font">Research</h6>
                    <p className="h1FontFamily research-text">
                      State of Assessment in K-12 Education
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="layout-container2 container p-lg-5">
          <div className="row d-flex p-lg-5 m-lg-3">
            <div className="col-lg-4  my-3">
              <div className="card-k12 ">
                <div className="card shadow-lg">
                  <img
                    className="card-img-top"
                    src={require("../../img/card-K12.jpg")}
                    alt="Card-k12"
                  />
                  <div className="card-body  d-flex ms-4 flex-column ">
                    <div className="mb-auto">
                      <h4
                        class="card-title h1FontFamily pb-4 mb-3 pt-3"
                        style={{
                          color: "#287a9f",
                          fontWeight: "600",
                          fontSize: "1.5rem",
                        }}
                      >
                        Amplify Teaching
                      </h4>
                    </div>
                    <p className="card-text pb-3">
                      <NavLink>
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none text-uppercase ps-0"
                          style={{ color: "red", fontWeight: "700" }}
                        >
                          Learn More
                          <i className="ms-3 fa-sharp fa-solid fa-arrow-right "></i>
                        </button>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4  my-3">
              <div className="card-k12 ">
                <div className="card shadow-lg">
                  <img
                    className="card-img-top"
                    src={require("../../img/card-he.jpg")}
                    alt="Card-k12"
                  />
                  <div className="card-body  d-flex ms-4 flex-column ">
                    <div className="mb-auto">
                      <h4
                        class="card-title h1FontFamily pb-4 mb-3 pt-3"
                        style={{
                          color: "#287a9f",
                          fontWeight: "600",
                          fontSize: "1.5rem",
                        }}
                      >
                        Elevate Learning
                      </h4>
                    </div>
                    <p className="card-text pb-3">
                      <NavLink>
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none text-uppercase ps-0"
                          style={{ color: "red", fontWeight: "700" }}
                        >
                          Learn More
                          <i className="ms-3 fa-sharp fa-solid fa-arrow-right "></i>
                        </button>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4  my-3">
              <div className="card-k12 ">
                <div className="card shadow-lg">
                  <img
                    className="card-img-top"
                    src={require("../../img/card-ce.jpg")}
                    alt="Card-k12"
                  />
                  <div className="card-body  d-flex ms-4 flex-column ">
                    <div className="mb-auto">
                      <h4
                        class="card-title h1FontFamily pb-4 mb-3 pt-3"
                        style={{
                          color: "#287a9f",
                          fontWeight: "600",
                          fontSize: "1.5rem",
                        }}
                      >
                        Intensify Impact
                      </h4>
                    </div>
                    <p className="card-text pb-3">
                      <NavLink>
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none text-uppercase ps-0"
                          style={{ color: "red", fontWeight: "700" }}
                        >
                          Learn More
                          <i className="ms-3 fa-sharp fa-solid fa-arrow-right "></i>
                        </button>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container layout-container-thre p-lg-5">
          <div className="row d-flex p-lg-5">
            <div className="col-lg-4 col-md-6 p-3">
              <div className="card-k12 ">
                <div className="card shadow-lg">
                  <img
                    className="card-img-top"
                    src={require("../../img/research-article.jpg")}
                    alt="Card-k12"
                  />
                  <div className="card-body  d-flex ms-4 flex-column ">
                    <div className="mb-auto">
                      <h4
                        class="card-title h1FontFamily pb-4 mb-3 pt-3"
                        style={{
                          color: "#287a9f",
                          fontWeight: "600",
                          fontSize: "1.5rem",
                        }}
                      >
                        Blogs By and For Educators and Admins
                      </h4>
                    </div>
                    <p className="card-text pb-3">
                      <NavLink>
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none text-uppercase ps-0"
                          style={{ color: "red", fontWeight: "700" }}
                        >
                          read on
                          <i className="ms-3 fa-sharp fa-solid fa-arrow-right "></i>
                        </button>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-3 ">
              <div className="card-k12 ">
                <div className="card shadow-lg">
                  <img
                    className="card-img-top"
                    src={require("../../img/research-slider-img.jpg")}
                    alt="Card-k12"
                  />
                  <div className="card-body  d-flex ms-4 flex-column ">
                    <div className="mb-auto">
                      <h4
                        class="card-title h1FontFamily pb-4 mb-3 pt-3"
                        style={{
                          color: "#287a9f",
                          fontWeight: "600",
                          fontSize: "1.5rem",
                        }}
                      >
                        First-hand Customer Stories
                      </h4>
                    </div>
                    <p className="card-text pb-3">
                      <NavLink>
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none text-uppercase ps-0"
                          style={{ color: "red", fontWeight: "700" }}
                        >
                          see stories
                          <i className="ms-3 fa-sharp fa-solid fa-arrow-right "></i>
                        </button>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-3 ">
              <div className="card-k12 ">
                <div className="card shadow-lg">
                  <img
                    className="card-img-top"
                    src={require("../../img/research-slider-2.jpg")}
                    alt="Card-k12"
                  />
                  <div className="card-body  d-flex ms-4 flex-column ">
                    <div className="mb-auto">
                      <h4
                        class="card-title h1FontFamily pb-4 mb-3 pt-3"
                        style={{
                          color: "#287a9f",
                          fontWeight: "600",
                          fontSize: "1.5rem",
                        }}
                      >
                        Insights for Edtech Enthusiasts
                      </h4>
                    </div>
                    <p className="card-text pb-3">
                      <NavLink>
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none text-uppercase ps-0"
                          style={{ color: "red", fontWeight: "700" }}
                        >
                          learn more
                          <i className="ms-3 fa-sharp fa-solid fa-arrow-right "></i>
                        </button>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }}>
          <div className="container p-lg-5 ">
            <h2
              className="text-center UbuntuFONTFAMILY"
              style={{
                color: "#156380",
                fontSize: "2.5rem",
                fontWeight: "800",
              }}
            >
              Featured Resources
            </h2>
            <div className="row d-flex justify-content-center p-lg-5">
              <div className="col-lg-4 col-md-6 p-3">
                <div
                  className="h-100 shadow-lg SolutionDropDownFontFamily"
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                >
                  <img
                    className="w-100"
                    style={{
                      borderRadius: "10px 10px 0px 0px",
                      height: "12rem",
                    }}
                    src={require("../../img/Featured-1.jpg")}
                    alt=""
                  />
                  <div className="p-3 ">
                    <h6
                      className="p-2"
                      style={{
                        lineHeight: "1.5",
                        color: "#4e6f7e",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Blog Articles
                    </h6>
                    <h4
                      className="p-2"
                      style={{
                        lineHeight: "1.1",
                        color: "#143d50",
                        fontSize: "29px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Reducing Bias in the Classroom
                    </h4>
                    <div id="reducing-card">
                      <button
                        type="button"
                        className="btn btn-link text-decoration-none"
                        style={{
                          color: "#e4060f",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        READ MORE
                        <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  p-3 ">
                <div
                  className="h-100 shadow-lg SolutionDropDownFontFamily "
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                >
                  <img
                    className="w-100 "
                    style={{
                      borderRadius: "10px 10px 0px 0px",
                      height: "12rem",
                    }}
                    src={require("../../img/Featured-2.jpg")}
                    alt=""
                  />
                  <div className="p-3">
                    <h6
                      className="p-2"
                      style={{
                        lineHeight: "1.5",
                        color: "#4e6f7e",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Blog Articles
                    </h6>
                    <h4
                      className="p-2"
                      style={{
                        lineHeight: "1.1",
                        color: "#143d50",
                        fontSize: "29px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Bridging the Skills Gap with Competency-Based Education
                    </h4>
                    <div className="card-text-featured">
                      <button
                        type="button"
                        class="btn btn-link text-decoration-none  "
                        style={{
                          color: "#e4060f",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        READ MORE{" "}
                        <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  p-3 ">
                <div
                  className="h-100 shadow-lg SolutionDropDownFontFamily"
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                >
                  <img
                    className="w-100"
                    style={{
                      borderRadius: "10px 10px 0px 0px",
                      height: "12rem",
                    }}
                    src={require("../../img/ks3.png")}
                    alt=""
                  />
                  <div className="p-3">
                    <h6
                      className="p-2"
                      style={{
                        lineHeight: "1.5",
                        color: "#4e6f7e",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Ebooks & Buyer’s Guides
                    </h6>
                    <h4
                      className="p-2"
                      style={{
                        lineHeight: "1.1",
                        color: "#143d50",
                        fontSize: "27px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Your Guide to Choosing a Learning Management System
                    </h4>
                    <div className="card-text-featured">
                      <button
                        type="button"
                        class="btn btn-link text-decoration-none "
                        style={{ color: "#e4060f", fontWeight: "600" }}
                      >
                        {" "}
                        READ MORE{" "}
                        <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  p-3 ">
                <div
                  className="h-100 shadow-lg SolutionDropDownFontFamily"
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                >
                  <img
                    className="w-100"
                    style={{
                      borderRadius: "10px 10px 0px 0px",
                      height: "12rem",
                    }}
                    src={require("../../img/Featured-4.jpg")}
                    alt=""
                  />
                  <div className="p-3">
                    <h6
                      className="p-2"
                      style={{
                        lineHeight: "1.5",
                        color: "#4e6f7e",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Infographic
                    </h6>
                    <h4
                      className="p-2"
                      style={{
                        lineHeight: "1.1",
                        color: "#143d50",
                        fontSize: "29px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      5 Reasons to Invest in High-Quality Assessment Content
                    </h4>
                    <div className="card-text-featured">
                      <button
                        type="button"
                        class="btn btn-link text-decoration-none "
                        style={{ color: "#e4060f", fontWeight: "600" }}
                      >
                        {" "}
                        READ MORE
                        <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  p-3 ">
                <div
                  className="h-100 shadow-lg SolutionDropDownFontFamily"
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                >
                  <img
                    className="w-100"
                    style={{
                      borderRadius: "10px 10px 0px 0px",
                      height: "12rem",
                    }}
                    src={require("../../img/Featured-5.jpg")}
                    alt=""
                  />
                  <div className="p-3">
                    <h6
                      className="p-2"
                      style={{
                        lineHeight: "1.5",
                        color: "#4e6f7e",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Blog Articles
                    </h6>
                    <h4
                      className="p-2"
                      style={{
                        lineHeight: "1.1",
                        color: "#143d50",
                        fontSize: "29px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      12 Effective Tier 1 Instructional Strategies
                    </h4>
                    <div id="reducing-card">
                      <button
                        type="button"
                        class="btn btn-link text-decoration-none "
                        style={{ color: "#e4060f", fontWeight: "600" }}
                      >
                        {" "}
                        READ MORE{" "}
                        <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  p-3 ">
                <div
                  className="h-100 shadow-lg SolutionDropDownFontFamily"
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                >
                  <img
                    className="w-100"
                    style={{
                      borderRadius: "10px 10px 0px 0px",
                      height: "12rem",
                    }}
                    src={require("../../img/BoiseState.jpg")}
                    alt=""
                  />
                  <div className="p-3">
                    <h6
                      className="p-2"
                      style={{
                        lineHeight: "1.5",
                        color: "#4e6f7e",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Product Demos & Videos
                    </h6>
                    <h4
                      className="p-2"
                      style={{
                        lineHeight: "1.1",
                        color: "#143d50",
                        fontSize: "27px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Boise State University and Canvas
                    </h4>
                    <div id="reducing-card">
                      <button
                        type="button"
                        class="btn btn-link text-decoration-none "
                        style={{ color: "#e4060f", fontWeight: "600" }}
                      >
                        {" "}
                        READ MORE{" "}
                        <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  p-3 ">
                <div
                  className="h-100 shadow-lg SolutionDropDownFontFamily"
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                >
                  <img
                    className="w-100"
                    style={{
                      borderRadius: "10px 10px 0px 0px",
                      height: "12rem",
                    }}
                    src={require("../../img/LearnPlatform.jpg")}
                    alt=""
                  />
                  <div className="p-3">
                    <h6
                      className="p-2"
                      style={{
                        lineHeight: "1.5",
                        color: "#4e6f7e",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Product Demos & Videos
                    </h6>
                    <h4
                      className="p-2"
                      style={{
                        lineHeight: "1.1",
                        color: "#143d50",
                        fontSize: "27px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      LearnPlatform by Instructure Explained
                    </h4>
                    <div id="reducing-card">
                      <button
                        type="button"
                        class="btn btn-link text-decoration-none "
                        style={{ color: "#e4060f", fontWeight: "600" }}
                      >
                        {" "}
                        READ MORE{" "}
                        <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  p-3 ">
                <div
                  className="h-100 shadow-lg SolutionDropDownFontFamily"
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                >
                  <img
                    className="w-100"
                    style={{
                      borderRadius: "10px 10px 0px 0px",
                      height: "12rem",
                    }}
                    src={require("../../img/itemeditor.jpg")}
                    alt=""
                  />
                  <div className="p-3">
                    <h6
                      className="p-2"
                      style={{
                        lineHeight: "1.5",
                        color: "#4e6f7e",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Ebooks & Buyer’s Guides
                    </h6>
                    <h4
                      className="p-2"
                      style={{
                        lineHeight: "1.1",
                        color: "#143d50",
                        fontSize: "27px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      From Courses to Career: Readying Non-Traditional Students
                      for the Workforce
                    </h4>
                    <div className="card-text-featured">
                      <button
                        type="button"
                        class="btn btn-link text-decoration-none "
                        style={{ color: "#e4060f", fontWeight: "600" }}
                      >
                        {" "}
                        READ MORE{" "}
                        <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TheStudyHall;
