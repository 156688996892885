import React, { useState } from 'react'

const ElevateDataQuality = () => {

  const [change1, setChange1] = useState(false);
  const [change2, setChange2] = useState(false);
  const [change3, setChange3] = useState(false);
  const [change4, setChange4] = useState(false);
  const [change5, setChange5] = useState(false);

  const clickMe1 = () => {
    setChange1(!change1)
    setChange2(false)
    setChange3(false)
    setChange4(false)
    setChange5(false)
  }
  const clickMe2 = () => {
    setChange1(false)
    setChange2(!change2)
    setChange3(false)
    setChange4(false)
    setChange5(false)
  }
  const clickMe3 = () => {
    setChange1(false)
    setChange2(false)
    setChange3(!change3)
    setChange4(false)
    setChange5(false)
  }

  const clickMe4 = () => {
    setChange1(false)
    setChange2(false)
    setChange3(false)
    setChange4(!change4)
    setChange5(false)
  }

  const clickMe5 = () => {
    setChange1(false)
    setChange2(false)
    setChange3(false)
    setChange4(false)
    setChange5(!change5)
  }

  return (

    <>
      <section>
        <div className="container-fluid" style={{ backgroundColor: "#0097d3" }} >
          <div className="container p-2">
            <div className="row p-2 text-white d-flex justify-content-between">
              <div className='col-lg-2'>
                <h5 className='ps-lg-3' style={{ fontSize: "1.125rem", fontWeight: "600", letterSpacing: "2px" }} >ELEVATE </h5>
              </div>
              <div className="col-lg-3">
                <div className='ps-lg-5 '>
                  <h5 className='ps-lg-5 ' style={{ fontSize: "1.125rem", fontWeight: "600", letterSpacing: "2px" }} > Elevate Data Quality </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container p-5">
          <div className="row p-4 pt-5 d-flex justify-content-around ">
            <div className="col-lg-5">
              <div>
                <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >DATA QUALITY ANALYTICS</p>
                <h2 className='h1FontFamily' style={{ fontSize: "3.6rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Elevate Data Quality. <span className='' style={{ color: "#0097d3", letterSpacing: "1px", fontWeight: "800" }}> Powering Data Quality Improvement. </span></h2>
              </div>
            </div>
            <div className="col-lg-5">
              <p className='mb-4' style={{ fontSize: "1.2rem", color: "#143d50" }}> Elevate Data Quality ensures district data is accurate, complete, and up-to-date by enabling districts to proactively detect and quickly resolve data problems.</p>
              <div className='pt-4 '>
                <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> REQUEST A DEMO <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row p-4  d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Implement a continuous process of data-quality improvement. </h2>
                  <p className='pt-3' style={{ color: "#143d50", fontSize: "1.25rem", }} > Eliminate manual data reporting and processes with an efficient and measurable approach to data quality. </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="row shadow-lg" style={{ backgroundColor: "#fff", borderRadius: "10px 10px 10px 10px" }} >
                  <div className="col-lg-6 p-0">
                    <img className='w-100 ' style={{ borderRadius: "10px 0px 0px 10px", height: "15rem" }} src={require("../../img/edq1.png")} alt='' />
                  </div>
                  <div className="col-lg-6  mt-4"  >
                    <div className='ps-3'>
                      <h2 className='h1FontFamily pt-4' style={{ fontSize: "1.5rem", color: "#143d50", lineHeight: "1.4" }} > Los Angeles Unified School District </h2>
                    </div>
                    <div className='ps-2 pt-lg-3'>
                      <button type="button" class="btn btn-link text-decoration-none pt-2" style={{ color: "red", fontWeight: "600", fontSize: ".875rem" }} > READ THE CASE STUDY <i class=" fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container mt-5 mb-5 p-4">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-5">
                <img className='imgRESOPNSIVE' src={require("../../img/edq2.png")} alt='' />
                <div className='ps-lg-4 ms-lg-2'>
                  <p className='fontFamilyHeader pt-lg-5' style={{ fontSize: "1.50rem", color: '#287a9f', fontWeight: "800", lineHeight: "1.4" }} > " Data drives our business, from student achievement to funding to program support. If our data is inaccurate, we are losing opportunities to support our students and the administration. With Elevate Data Quality, we can now maximize our potential for success. " </p>
                  <h6 className='fontFamilyHeader pt-2' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "800" }} >  — Gary Sabia </h6>
                  <h6 className='fontFamilyHeader' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "400" }}> Program Director, MiSiS Project, Los Angeles Unified School District, CA </h6>
                </div>
              </div>
              <div className="col-lg-6 PLUS-MINUS-CATALOG p-5 shadow-lg" style={{ backgroundColor: "#fff", borderRadius: "10px 10px 10px 10px" }} >
                <h2 className='ps-5 p-3 h1FontFamily ' style={{ color: "#156380", fontSize: "2.5rem", lineHeight: "1.2" }} > Transform Your Data Quality Program.</h2>
                <div className="table p-4 d-flex justify-content-center">
                  <div className='w-100' >
                    <div className=''  >
                      <div className='w-100 p-0'  >
                        <button className='text-start bg-body  w-100  p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe1() }}> <h3 className='fs-5'> <b>{change1 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span>  <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className=' ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Automate Data Validation</span></b></h3></button>
                        <div className=''>
                          {
                            change1 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Elevate Data Quality promotes a daily, proactive process by automatically reading your district’s databases each night and sending email alerts to notify users about specific data issues that need to be reviewed, monitored, or corrected. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe2() }}><h3 className='fs-5'> <b> {change2 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Avoid State Reporting Challenges </span> </b></h3></button>
                        <div >
                          {
                            change2 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > State data submission periods can be incredibly stressful for districts. Our product ensures that data clean-up is a continuous, year-round process so that state reporting deadlines are easily met, with accurate and complete data. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start  w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe3() }}> <h3 className='fs-5'><b> {change3 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i></span> : <span> <i className="fa-solid fa-plus " style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Save Time and Resources </span> </b></h3></button>
                        <div >
                          {
                            change3 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Reduce the time your district staff spends on data clean-up, training, emailing, and supporting schools, so they can be re-focused on more strategic district initiatives. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe4() }}><h3 className='fs-5'> <b> {change4 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.4rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >  Create Data Ownership and Accountability </span> </b></h3></button>
                        <div >
                          {
                            change4 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Elevate Data Quality provides an easy and streamlined process of data error detection and correction that empowers schools to take an active role in keeping data clean and up-to-date—no technical skills required. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe5() }}><h3 className='fs-5'> <b> {change5 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Maximize Funding Opportunities </span> </b></h3></button>
                        <div >
                          {
                            change5 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Having accurate counts for special populations such as English language learners, free and reduced lunch, and others can impact the level of funding and program support that is received. Consistently validating your data can support compliance efforts. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row p-4 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > PROACTIVE DATA VALIDATION </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Make data quality a priority. </h2>
                  <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.2rem", }} > The growing volume of district data can be overwhelming, with limited time to verify accuracy. Poor data quality is not only inconvenient, but it can have consequences that impact student progress, state funding, and a school district’s reputation. Elevate Data Quality enables you to easily detect data errors and discrepancies before they become larger problems. </p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Identify data-quality issues in source systems </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Rely on accurate data for informed decision-  <span className='ps-lg-4 ms-lg-2'>making</span>  </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Proactively identify potential funding or  <span className='ps-lg-4 ms-lg-2'>compliance issues</span>  </span></p>
                </div>
              </div>
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/edq3.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#F2F8FA" }} >
          <div className="container p-lg-5">

            <div className='d-flex justify-content-center text-center'>
              <div className='' style={{ maxWidth: "40%" }} >
                <h4 className='SolutionDropDown pt-2' style={{ color: "#156380", fontSize: "2.3rem", fontWeight: "800", letterSpacing: "2px" }} > More to love about Elevate Data Quality: </h4>
              </div>
            </div>

            <div className="row p-lg-5 d-flex justify-content-center">
              <div className="col-lg-5 col-md-6 ">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Rule Libraries </h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} >Districts can utilize a collection of high-value pre-defined rules and can also define their own district-specific rules to meet business and data needs. </p>
                </div>
              </div>

              <div className="col-lg-5 col-md-6">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Alerts, Right to Your Inbox </h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Users receive daily, personalized email notifications that highlight data issues. Users can then log in to review detailed data errors that are relevant for their specific role. </p>
                </div>
              </div>

              <div className="col-lg-5 col-md-6">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Personalized Scorecards </h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > A daily inventory of data issues in the user’s area of responsibility with error details. Scorecards include a Data Certification Score that allows users to measure their progress. </p>
                </div>
              </div>

              <div className="col-lg-5 col-md-6 ">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Rule Prescriptions</h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > “How-to” documents that can be added to each rule so users can quickly reference the information needed to research an issue or make a data correction. </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-lg-5 ">
            <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
            <div className="row d-flex justify-content-center p-lg-5">
              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/mc7.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Ebooks & Buyer’s Guides</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }} > Address Inequities and Learning Gaps with Integrated Assessment Analytics</h4>
                    <div className='pt-lg-2'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/lms4.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Blog Articles</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }}>Instructure + Ed-Fi: Transforming Education Through Data Interoperability</h4>
                    <div className='pt-lg-2'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/lms4.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Case Studies</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }}>Los Angeles Unified School District: Maximize Success with Elevate Data Quality</h4>
                    <div className='pt-lg-2'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
          <div className="container  p-lg-5 ">
            <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
              <div className='text-lg-center pb-lg-4'>
                <h2 className='pb-2 UbuntuFONTFAMILY' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to improve your data quality <br /> process? </h2>
                <div className='pt-4 '>
                  <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>

  )
}

export default ElevateDataQuality