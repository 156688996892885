import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Header from "./Header";
import Footer from "./Footer";
import Canavas from "./k-12/Canavas";
import LMS from "./k-12/LMS";
import CanvasStudio from "./k-12/CanvasStudio";
import CanvasCatalog from "./k-12/CanvasCatalog";
import CanvasCredentials from "./k-12/CanvasCredentials";
import Mastery from "./k-12/Mastery";
import MasteryConnect from "./k-12/MasteryConnect";
import MasteryItemBank from "./k-12/MasteryItemBank";
import MasteryViewFormativeAssessments from "./k-12/MasteryViewFormativeAssessments";
import MasteryViewPredictiveAssessments from "./k-12/MasteryViewPredictiveAssessments";
import K12Solutoins from "./K12Solutoins";
import ElevateDataQuality from "./k-12/ElevateDataQuality";
import Elevate from "./k-12/Elevate";
import ElevateK12 from "./k-12/ElevateK12";
import LearningManagement from "./k-12/LearningManagement";
import Assessment from "./k-12/Assessment";
import Content from "./k-12/Content";
import OnlinePrograms from "./k-12/OnlinePrograms";
import Analytics from "./k-12/Analytics";
import ProfessionalDevelopment from "./k-12/ProfessionalDevelopment";
import IntegrationsWithPartnerTools from "./k-12/IntegrationsWithPartnerTools";
import ForAdministrators from "./k-12/ForAdministrators";
import ForITTechnologists from "./k-12/ForIT/ForITTechnologists";
import ForEducators from "./k-12/ForIT/ForEducators";
import ForStudentsParents from "./k-12/ForIT/ForStudentsParents";
import HighCanvas from "./HIGHEDUCATION/HighCanvas";
import HighCanvasLms from "./HIGHEDUCATION/HighCanvasLms";
import HighCanvasStudio from "./HIGHEDUCATION/HighCanvasStudio";
import HighCanvasCatalog from "./HIGHEDUCATION/HighCanvasCatalog";
import HighCanvasCredentials from "./HIGHEDUCATION/HighCanvasCredentials";
import HighCanvasStudentPathways from "./HIGHEDUCATION/HighCanvasStudentPathways";
import TheStudyHall from "./Resources/TheStudyHall";
import Error from "./Error";
import Community from "./Resources/Community";
import Articles from "./Resources/Articles";
import CaseStudies from "./Resources/CaseStudies";
import Ebooks from "./Resources/Ebooks";
import Research from "./Resources/Research";
import Videos from "./Resources/Videos";
import Infographic from "./Resources/Infographic";
import Webinars from "./Resources/Webinars";
import AIresources from "./Resources/AIresources";
import UpcommingEvents from "./News-Events/UpcommingEvents";
import Instructurecon2024 from "./News-Events/Instructurecon2024";
import AboutUs from "./About-Us/AboutUs";
import OurStory from "./About-Us/OurStory";

const Routing = () => {
  return (
    <>
      <React.Fragment>
        <header className="sticky-top" style={{ backgroundColor: "#fff" }}>
          <Header />
        </header>

        <main>
          <Routes>
            <Route to path="/" element={<Home />} />

            <Route to path="/k12solution" element={<K12Solutoins />} />

            <Route to path="/canavas" element={<Canavas />} />
            <Route to path="/lms" element={<LMS />} />
            <Route to path="/canvasstudio" element={<CanvasStudio />} />
            <Route to path="/canvascatalog" element={<CanvasCatalog />} />
            <Route
              to
              path="/canvascredentials"
              element={<CanvasCredentials />}
            />

            <Route to path="/mastery" element={<Mastery />} />
            <Route to path="/masteryconnect" element={<MasteryConnect />} />
            <Route to path="/masteryitembank" element={<MasteryItemBank />} />
            <Route
              to
              path="/masteryviewformativeassessments"
              element={<MasteryViewFormativeAssessments />}
            />
            <Route
              to
              path="/masteryviewpredictiveassessments"
              element={<MasteryViewPredictiveAssessments />}
            />

            <Route to path="/elevate" element={<Elevate />} />
            <Route to path="/elevatek_12" element={<ElevateK12 />} />
            <Route
              to
              path="/elevatedataquality"
              element={<ElevateDataQuality />}
            />

            <Route
              to
              path="/learningmanagement"
              element={<LearningManagement />}
            />
            <Route to path="/assessment" element={<Assessment />} />
            <Route to path="/content" element={<Content />} />
            <Route to path="/onlineprograms" element={<OnlinePrograms />} />
            <Route to path="/analytics" element={<Analytics />} />
            <Route
              to
              path="/professionaldevelopment"
              element={<ProfessionalDevelopment />}
            />
            <Route
              to
              path="/integrationswithpartnertools"
              element={<IntegrationsWithPartnerTools />}
            />
            <Route
              to
              path="/foradministrators"
              element={<ForAdministrators />}
            />
            <Route
              to
              path="/forittechnologists"
              element={<ForITTechnologists />}
            />
            <Route to path="/foreducators" element={<ForEducators />} />
            <Route
              to
              path="/forstudentsparents"
              element={<ForStudentsParents />}
            />

            <Route to path="/highcanavas" element={<HighCanvas />} />
            <Route to path="/highcanavaslms" element={<HighCanvasLms />} />
            <Route
              to
              path="/highcanavasstudio"
              element={<HighCanvasStudio />}
            />
            <Route
              to
              path="/highcanavascatalog"
              element={<HighCanvasCatalog />}
            />
            <Route
              to
              path="/highcanvascredentials"
              element={<HighCanvasCredentials />}
            />
            <Route
              to
              path="/highcanvasstudentpathways"
              element={<HighCanvasStudentPathways />}
            />

            <Route to path="/resources" element={<TheStudyHall />} />
            <Route to path="/community" element={<Community />} />
            <Route to path="/articles" element={<Articles />} />
            <Route to path="/casestudies" element={<CaseStudies />} />
            <Route to path="/ebooks" element={<Ebooks />} />
            <Route to path="/research" element={<Research />} />
            <Route to path="/videos" element={<Videos />} />
            <Route to path="/infographic" element={<Infographic />} />
            <Route to path="/webinars" element={<Webinars />} />
            <Route to path="/airesources" element={<AIresources />} />
            <Route to path="/events" element={<UpcommingEvents />} />
            <Route to path="/instructurecon" element={<Instructurecon2024 />} />

            <Route to path="/about" element={<AboutUs />} />
            <Route to path="/our-story" element={<OurStory />} />

            <Route to path="*" element={<Error />} />
          </Routes>
        </main>

        <footer>
          <Footer />
        </footer>
      </React.Fragment>
    </>
  );
};

export default Routing;
