import React, { useState } from 'react'

const HighCanvasCredentials = () => {

    const [change1, setChange1] = useState(false);
    const [change2, setChange2] = useState(false);
    const [change3, setChange3] = useState(false);
    const [change4, setChange4] = useState(false);
    const [change5, setChange5] = useState(false);

    const clickMe1 = () => {
        setChange1(!change1)
        setChange2(false)
        setChange3(false)
        setChange4(false)
        setChange5(false)
    }
    const clickMe2 = () => {
        setChange1(false)
        setChange2(!change2)
        setChange3(false)
        setChange4(false)
        setChange5(false)
    }
    const clickMe3 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(!change3)
        setChange4(false)
        setChange5(false)
    }

    const clickMe4 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(false)
        setChange4(!change4)
        setChange5(false)
    }

    const clickMe5 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(false)
        setChange4(false)
        setChange5(!change5)
    }

    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#e4060f" }} >
                    <div className="container p-1">
                        <div className="row p-1 text-white d-flex justify-content-between">
                            <div className='col-lg-2'>
                                <h5 className='ps-2 p-2' style={{ fontSize: "1.25rem", fontWeight: "600", letterSpacing: "2px" }} >CANVAS</h5>
                            </div>
                            <div className="col-lg-4">
                                <div className='ps-1 p-2'>
                                    <h5 className='' style={{ fontSize: "1.125rem", fontWeight: "600" }} > Higher Education Canvas Credentials </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container p-lg-5 pb-3">
                    <div className="row p-lg-5  d-flex justify-content-around ">
                        <div className="col-lg-5">
                            <div>
                                <p className='pt-2' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > CANVAS CREDENTIALS </p>
                                <h2 className='h1FontFamily' style={{ fontSize: "3.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > The All-In-One <span className='' style={{ color: "#e4060f", letterSpacing: "1px", fontWeight: "800" }}> Digital Badging </span> For Higher Education </h2>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <p className='mb-5 mt-2' style={{ fontSize: "1.2rem", color: "#143d50" }}> Digital badging can be complex. Canvas Credentials makes it easy. Accelerate your goals with a solution that will validate competencies, drive engagement, improve completion, and increase enrollment. </p>
                            <div className='pt-lg-4 '>
                                <button className='SolutionDropDownFontFamily' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> GET A DEMO <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-5 pb-3">
                        <div className="row p-lg-5 pt-2 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.4rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Digital Credentialing for Higher Education </h2>
                                    <p className='pt-3' style={{ color: "#143d50", fontSize: "1.15rem", }} > Increase student enrollment and retention rates while providing a seamless way for learners to record and share their validated skills and achievements with future employers. </p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="row shadow-lg" style={{ backgroundColor: "#fff", borderRadius: "10px 10px 10px 10px" }} >
                                    <div className="col-lg-6 col-md-6  p-lg-0" >
                                        <img className='w-100 ' style={{ borderRadius: "10px 0px 0px 10px", height: "11rem" }} src={require("../../img/HighCanvasCRE1.png")} alt='' />
                                    </div>
                                    <div className="col-lg-6 col-md-6 " style={{ height: "72%" }} >
                                        <div className='ps-lg-3 pt-4'>
                                            <h2 className='h1FontFamily ' style={{ fontSize: "1.6rem", color: "#143d50", lineHeight: "1.4" }} > UNT Students Promote Skills </h2>
                                        </div>
                                        <div className='ps-lg-1 pt-lg-3'>
                                            <button type="button" class="btn btn-link text-decoration-none pt-2" style={{ color: "red", fontWeight: "600", fontSize: ".875rem" }} > READ THE POST <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container p-lg-4 pb-3">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-5">
                                <img className='imgRESOPNSIVE' src={require("../../img/HighCanvasCRE2.png")} alt='' />
                                <div className='ps-lg-5 ms-lg-2 pb-4'>
                                    <p className='fontFamilyHeader pt-5' style={{ fontSize: "1.50rem", color: '#287a9f', fontWeight: "800", lineHeight: "1.4" }} > " With [Canvas Credentials] flexible and stackable pathways and advanced analytics, we’ve been able to seamlessly connect badges across schools and programs while tracking usage. " </p>
                                    <h6 className='fontFamilyHeader pt-2' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "800" }} > — Allison Hall </h6>
                                    <h6 className='fontFamilyHeader' style={{ fontSize: "1.1rem", color: '#287a9f', fontWeight: "400" }}> ASU Director Of Learning Experience Design </h6>
                                </div>
                            </div>
                            <div className="col-lg-6 PLUS-LARGE p-lg-5 shadow-lg" style={{ backgroundColor: "#fff", borderRadius: "10px 10px 10px 10px" }} >
                                <h2 className='ps-lg-5 p-3 h1FontFamily mt-lg-4' style={{ color: "#156380", fontSize: "2.7rem", lineHeight: "1.2" }} > An equitable future with achievement recognition </h2>
                                <div className="table p-lg-4 d-flex justify-content-center">
                                    <div className='w-100 ' >
                                        <div className=' '  >
                                            <div className='w-100 p-0'  >
                                                <button className='text-start bg-body  w-100  p-lg-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe1() }}> <h3 className='fs-5'> <b>{change1 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span>  <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className=' ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Badging made for everyone </span></b></h3></button>
                                                <div className=''>
                                                    {
                                                        change1 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Canvas Credentials—the world’s most open and inclusive digital credentialing network. Join us in creating a more equitable world through verified achievements and skills. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe2() }}><h3 className='fs-5'> <b> {change2 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >  The great equaliser </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change2 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Until recently, when it came to getting a job, there were two camps: those with degrees & those without. Now there’s badging—benefiting students, higher ed institutions, and job recruiters. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start  w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe3() }}> <h3 className='fs-5'><b> {change3 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i></span> : <span> <i className="fa-solid fa-plus " style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Easy and custom </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change3 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Seamlessly issue data-rich, shareable, and verifiable badges with Canvas Credentials, the only open digital credentialing solution with visual and stackable learning pathways. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe4() }}><h3 className='fs-5'> <b> {change4 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Curate, showcase, and achieve </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change4 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Able to integrate credentials from other platforms, learners curate and showcase skills and achievements while employers and educators verify and leverage skills data to meet education and workforce goals. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe5() }}><h3 className='fs-5'> <b> {change5 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Powerful and secure? Yes! </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change5 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Used by thousands of organizations across the globe to issue digital badges and stackable micro-credentials, our secure platform connects learners to new education and employment pathways. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-4 pb-3">
                        <div className="row p-lg-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='pt-2' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > WHAT’S IN IT FOR YOU? </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Increase enrollment and completion rates </h2>
                                    <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.1rem", }} > Attract and serve a larger number of diverse students by offering digital credentials to lifelong learners. Both students and employers benefit when learning can flex around life and when the results from the time invested can be verified and shared. Digital credentialing leads to both. </p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} > Create skills-based credential systems </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} > Recognise and reward learners with badges </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.29rem", fontWeight: "600" }} > Visualise, analyze, and expand the community </span></p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/HighCanvasCRE3.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section >
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-4 pt-2 pb-2">
                        <div className="row  p-lg-5 d-flex justify-content-around ">

                            <div className="col-lg-5">
                                <img className='w-100' src={require("../../img/HighCanvasCRE4.png")} alt='' />
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <p className='pt-2' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > WHY DIGITAL BADGES? </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.6rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > To foster engagement & tell the full story </h2>
                                    <p className='pt-2 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Progress in education doesn’t magically materialise at the end of a degree program. Badging allows you to recognise, validate, track, and share learning achievements from day one. Empower learners to tell their full story by giving them access to it. </p>
                                    <div className=' pb-4'>
                                        <p className='fontFamilyHeader' style={{ fontSize: "1.40rem", color: '#287a9f', fontWeight: "800", lineHeight: "1.4" }} > " By prompting, suggesting structure, and providing opportunities for students to collect and connect their experiences, we have a better chance of supporting their success. " </p>
                                        <h6 className='fontFamilyHeader pt-2' style={{ fontSize: "1.2rem", color: '#287a9f', fontWeight: "800" }} > Mike Simmons </h6>
                                        <h6 className='fontFamilyHeader' style={{ fontSize: "1.1rem", color: '#287a9f', fontWeight: "400" }}> UNT Asst. VP for Academic Affairs </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-4 pt-2 pb-2">
                        <div className="row p-lg-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > TRANSLATING ACHIEVEMENTS </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.6rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Validate skills: Showcase workforce readiness </h2>
                                    <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.2rem", }} > Digital credentials help translate learning outcomes into validated skills that both students and employers can understand. Badges give learners agency over their learning and career path by clearly communicating the employer-valued skills they’ve either already demonstrated or still need to earn. </p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/HighCanvasCRE5.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#F2F8FA" }} >
                    <div className="container SolutionDropDownFontFamily p-lg-5 pt-2 pb-2">

                        <div className='row pb-2 d-flex justify-content-center text-center'>
                            <div className='width40'>
                                <p className='' style={{ color: "#143d50", fontWeight: "800", letterSpacing: "2px" }} >READY STUDENTS FOR THE FUTURE</p>
                                <h4 className=' pt-2' style={{ color: "#156380", fontSize: "2.2rem", fontWeight: "800", letterSpacing: "2px" }} > Digital credentials are advancing skills-based education and hiring </h4>
                            </div>
                        </div>

                        <div className="row g-2 p-lg-5 d-flex justify-content-center ">
                            <div className="col-lg-4 col-md-6 ">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 ' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Skills alignment </h6>
                                    <p className='mt-3 ' style={{ color: "#143d50", fontSize: "1.125rem", }} > Unlock the potential of skills-based education and hiring. </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Share & recognise </h6>
                                    <p className='mt-3 ' style={{ color: "#143d50", fontSize: "1.125rem", }} > Motivate with achievements that are easy to recognise and share. </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 ' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Admin & reporting </h6>
                                    <p className='mt-3 ' style={{ color: "#143d50", fontSize: "1.125rem", }} > Manage, track, analyze, and report in real time. </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 ' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Learner record </h6>
                                    <p className='mt-3 ' style={{ color: "#143d50", fontSize: "1.125rem", }} > Make it easy to envision and share growth. </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 ' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Customisation </h6>
                                    <p className='mt-3 ' style={{ color: "#143d50", fontSize: "1.125rem", }} > Create a fully branded end-to-end experience. </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 ' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Trust and security </h6>
                                    <p className='mt-3 ' style={{ color: "#143d50", fontSize: "1.125rem", }} > Offer confidence through data privacy, security, and ownership. </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-5 pt-2 pb-4">
                        <div className="row g-2 p-lg-5 ">
                            <div className="col-lg-4 col-md-12">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE ALL PRODUCTS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Explore the Entire Canvas Product Family </h2>
                                    <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > Simplify teaching and learning activities, organize coursework and keep teachers, students, and families connected and communicating. Anytime, anywhere.</p>
                                    <div className='pt-4 pb-5'>
                                        <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="row">
                                    <div className="col-lg">
                                        <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Studio</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > It’s the next-generation video learning solution that turns one‑way, passive video into inclusive, engaging, and productive classroom discussions.</p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg pt-5">
                                            <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                                <div className='p-3'>
                                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Credentials</h6>
                                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Canvas Credentials empowers educators with more than just a transcript. With verifiable digital credentials, they have a comprehensive learner record to demonstrate progress towards their goals.</p>
                                                    <div className=''>
                                                        <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                                <div className="row">
                                    <div className="col-lg ">
                                        <div className='p-lg-3 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Catalog</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Catalog is a simple, modern course catalog registration system and branded marketplace for all of your district’s professional development and community resource offerings.</p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg ">
                                            <div className='p-lg-3  mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                                <div className='p-3'>
                                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Impact by Instructure</h6>
                                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Improve technology adoption, support teachers and students in their tech use, and evaluate the impact of educational technology on student engagement.</p>
                                                    <div className=''>
                                                        <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
                    <div className="container  p-lg-5 pt-2 pb-2">
                        <div className="row d-flex justify-content-center pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white ">
                            <div className='text-lg-center pb-lg-4 '  >
                                <h2 className='pb-2 SolutionDropDownFontFamily' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to build your custom badging solution? </h2>
                                <div className='pt-4 '>
                                    <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HighCanvasCredentials