import React, { useState } from 'react'
import "./Common.css"

const Home = () => {

  const [display, setDisplay] = useState("Canvas");

  const [btn1, setBtn1] = useState("newStyle");

  const [btn2, setBtn2] = useState("btnStyle");

  const [btn3, setBtn3] = useState("btn3Style");

  const [btn4, setBtn4] = useState("btn4Style");

  const changleDisplay = () => {
    setDisplay("Canvas")
    setBtn1("newStyle")
    setBtn2("btnStyle")
  }

  const changleDisplay1 = () => {
    setDisplay("Elevate")
    setBtn1("btnStyle")
    setBtn2("newStyle")
  }

  const changleDisplay3 = () => {
    setDisplay("Mastery")
    setBtn3("btn3Style")
    setBtn4("btn4Style")
  }

  const changleDisplay4 = () => {
    setDisplay("Impact")
    setBtn3("btn4Style")
    setBtn4("btn3Style")
  }

  return (

    <>

      <section>
        <div className="container-fluid p-lg-0 HOMEBGIMG" style={{ backgroundImage: `url(${require("../img/EducationHomeBanner.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: ' center,center ' }}>
          <div className="container pt-5 p-lg-5">
            <div className="row p-lg-5 ps-lg-5 ms-lg-4 mt-2"  >
              <div className="col-lg-6  p-lg-5 shadow-lg" style={{ backgroundColor: "#fff", borderRadius: '10px 10px 10px 10px' }}>
                <div className='p-lg-3 p-1'>
                  <h5 className='fs-2' style={{ color: "red", fontWeight: "700" }} >CANAVAS</h5>
                  <h2 className='h1FontFamily1 pt-2 pb-2' style={{ color: "#287a9f", fontSize: '3.5rem', fontWeight: "700", lineHeight: "1.1", letterSpacing: '2px' }} >Yes, You Can <br></br> with <b className='' style={{ color: "red", fontWeight: "700" }} >Canvas</b></h2>
                  <p className='fontFamilyHeader' style={{ color: "#143d50", fontSize: '1.125rem', fontWeight: "400", }} >Deliver dynamic learning experiences to every student, everywhere. Take teaching and learning to the next level with Canvas, the #1 educational software, and the foundation of the Instructure Learning Platform.</p>
                  <div className='pt-3 pb-2'>
                    <button className='' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "red", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem" }}>GET A DEMO</b></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section >
        <div className="container p-lg-5 pt-5 pb-2">
          <div className="row p-lg-4 d-flex justify-content-around">
            <div className="col-lg-5">
              <img className='w-100' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../img/col1.png")} alt='' />
            </div>

            <div className="col-lg-5">
              <div className='pt-2'>
                <h2 className='UbuntuFONTFAMILY' style={{ color: '#143d50', fontSize: "1rem", letterSpacing: "1px", lineHeight: '1.5', fontWeight: '800' }} >JUST FOR EDUCATORS</h2>
                <h2 className='UbuntuFONTFAMILY' style={{ color: '#156380', fontSize: "2.5rem", letterSpacing: "1px", lineHeight: '1.5', fontWeight: '800' }} >Meet with A Panda Pro</h2>
                <p className='SolutionDropDownFontFamily' style={{ color: '#143d50', fontSize: "1.125rem" }} >Connect 1:1 with a Panda Pro for a free 45-minute coaching session to answer your Canvas by Instructure questions.</p>
                <div className='pt-lg-4'>
                  <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "red", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem" }}>Schedule Your 1:1 Session</b></button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section>
        <div className="container p-lg-5 pt-3 pb-2">
          <div className="row p-lg-4 d-flex justify-content-center">
            <div className='text-center'>
              <h6 className='UbuntuFONTFAMILY' style={{ color: '#143d50', letterSpacing: "1px", fontSize: ".875rem", fontWeight: "800" }} >WHO WE HELP</h6>
              <h2 className='UbuntuFONTFAMILY pt-3 pb-4' style={{ color: '#156380', letterSpacing: "1px", fontSize: "2.5rem", fontWeight: "800", lineHeight: "1.2" }} >Instructure Love for Every <br></br> Level of Learning</h2>
            </div>

            <div className="col-lg-4">
              <img className='w-100' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../img/k-12.png")} alt='' />
              <div className='text-center pt-lg-4'>
                <h2 className='h1FontFamily' style={{ color: '#156380', lineHeight: "1.34", fontSize: "2rem", fontWeight: "800" }} > K-12 </h2>
                <p className='SolutionDropDownFontFamily pt-2' style={{ color: '#143d50', lineHeight: "1.5", fontSize: "1.175rem" }} > A lot changes between that K and that 12. Canvas supports every student, at every stage, in any environment. </p>
                <div className=''>
                  <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#E4060F", fontSize: "1rem", textTransform: "uppercase", fontWeight: "700" }} > Learn More </button>
                </div>
              </div>
            </div>


            <div className="col-lg-4">
              <img className='w-100' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../img/Higher-ED.png")} alt='' />
              <div className='text-center pt-lg-4'>
                <h2 className='h1FontFamily' style={{ color: '#156380', lineHeight: "1.34", fontSize: "2rem", fontWeight: "800" }} > Higher Ed </h2>
                <p className='SolutionDropDownFontFamily pt-2' style={{ color: '#143d50', lineHeight: "1.5", fontSize: "1.195rem" }} > Our solutions make educators more effective and students more successful, both in school and beyond. </p>
                <div className=''>
                  <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#E4060F", fontSize: "1rem", textTransform: "uppercase", fontWeight: "700" }} > Learn More </button>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <img className='w-100' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../img/copra.png")} alt='' />
              <div className='text-center pt-lg-4'>
                <h2 className='h1FontFamily' style={{ color: '#156380', lineHeight: "1.34", fontSize: "2rem", fontWeight: "800" }} > Corp Edu </h2>
                <p className='SolutionDropDownFontFamily pt-2' style={{ color: '#143d50', lineHeight: "1.5", fontSize: "1.195rem" }} > To work is to learn. But when work takes extra learning, our tools make it extra intuitive, engaging, and effective. </p>
                <div className=''>
                  <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#E4060F", fontSize: "1rem", textTransform: "uppercase", fontWeight: "700" }} > Learn More </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className=" pt-5 p-lg-4 ">
            <div className="row d-flex justify-content-center align-items-center ">
              <div className='text-center width40 pb-5'>
                <h6 className='UbuntuFONTFAMILY' style={{ color: '#143d50', letterSpacing: "1px", fontSize: ".875rem", fontWeight: "800" }} > MEET </h6>
                <h2 className='UbuntuFONTFAMILY pt-3 pb-2' style={{ color: '#156380', letterSpacing: "1px", fontSize: "2.5rem", fontWeight: "800", lineHeight: "1.2" }} > The Instructure Learning Platform </h2>
                <div className='d-flex justify-content-center'>
                  <p className='' style={{ fontSize: "1.3rem" }} >Every lesson has the power to change lives. Even more so with our teaching and learning solutions in your corner.</p>
                </div>
              </div>
              <div className="row d-flex justify-content-lg-center " style={{ borderBottom: '1px solid #ccdce4' }} >
                <div className="col-lg-2 col-md-6 d-flex justify-content-center fontFamilyHeader1" style={{ fontSize: "1.225rem" }} >
                  <button className={btn1} style={{ color: "red", fontWeight: "800" }} onClick={changleDisplay}>Canvas</button>
                </div>

                <div className="col-lg-2 col-md-6 d-flex justify-content-center fontFamilyHeader1" style={{ fontSize: "1.225rem" }} >
                  <button className={btn3} style={{ fontWeight: "800" }} onClick={changleDisplay3}>Mastery</button>
                </div>

                <div className="col-lg-2 col-md-6 d-flex justify-content-center fontFamilyHeader1" style={{ fontSize: "1.225rem" }} >
                  <button className={btn2} style={{ fontWeight: "800" }} onClick={changleDisplay1}>Elevate</button>
                </div>

                <div className="col-lg-2 col-md-6 d-flex justify-content-center fontFamilyHeader1" style={{ fontSize: "1.225rem" }} >
                  <button className={btn4} style={{ fontWeight: "800" }} onClick={changleDisplay4}>Impact</button>
                </div>
              </div>

              <div >
                {

                  display === "Canvas" ?
                    <div className="container-fluid  mt-3">
                      <div className='row d-flex justify-content-between pt-5 mt-5'>
                        <div className="col-lg-6">
                          <img className='w-100' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../img/ter1.png")} alt='' />
                        </div>

                        <div className="col-lg-5">
                          <div>
                            <h2 className='UbuntuFONTFAMILY' style={{ color: 'red', fontSize: "2.2rem", letterSpacing: "1px", lineHeight: '1.5', fontWeight: '800' }} > CANVAS <b className='' style={{ fontSize: '10px', color: '#143d50' }} >BY INSTRUCTURE</b> </h2>
                            <h2 className='UbuntuFONTFAMILY ' style={{ color: '#156380', fontSize: "2.2rem", letterSpacing: "1px", lineHeight: '1.5', fontWeight: '800' }} >The World's #1 Teaching and Learning Software</h2>
                            <p className='SolutionDropDownFontFamily' style={{ color: '#143d50', fontSize: "1.225rem" }} > The tools you need for teaching and learning in one place. Connection between teachers, students, and families, wherever learning happens with Canvas by Instructure.</p>
                            <div className='pt-4'>
                              <button className='UbuntuFONTFAMILY mb-2' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "red", border: "none", color: "white", padding: "12px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", textTransform: "uppercase" }}> For Higher Education <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                              <button className='UbuntuFONTFAMILY ms-lg-3' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#287A9F", border: "none", color: "white", padding: "12px 15px" }} ><b className='p-2' style={{ fontSize: "1rem" }}>FOR K-12 <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    : display === "Mastery" ?
                      <div className="container-fluid  mt-3">
                        <div className='row d-flex justify-content-between pt-5 mt-5'>
                          <div className="col-lg-6">
                            <img className='w-100' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../img/ter2.png")} alt='' />
                          </div>

                          <div className="col-lg-5">
                            <div>
                              <h2 className='UbuntuFONTFAMILY' style={{ color: '#24A159', fontSize: "2.2rem", letterSpacing: "1px", lineHeight: '1.5', fontWeight: '800' }} > MASTERY <b className='' style={{ fontSize: '10px', color: '#143d50' }} >BY INSTRUCTURE</b> </h2>
                              <h2 className='UbuntuFONTFAMILY ' style={{ color: '#156380', fontSize: "2.2rem", letterSpacing: "1px", lineHeight: '1.5', fontWeight: '800' }} > Assessment. For Learning. </h2>
                              <p className='SolutionDropDownFontFamily' style={{ color: '#143d50', fontSize: "1.225rem" }} > Bringing together the assessment tools and quality content educators need to drive learning outcomes with Mastery by Instructure.</p>
                              <div className='pt-4'>
                                <button className='UbuntuFONTFAMILY ms-lg-3' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#287A9F", border: "none", color: "white", padding: "12px 15px" }} ><b className='p-2' style={{ fontSize: "1rem" }}>FOR K-12 <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      : display === "Elevate" ?
                        <div className="container-fluid  mt-3">
                          <div className='row d-flex justify-content-between pt-5 mt-5'>
                            <div className="col-lg-6">
                              <img className='w-100' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../img/ter3.png")} alt='' />
                            </div>

                            <div className="col-lg-5">
                              <div>
                                <h2 className='UbuntuFONTFAMILY' style={{ color: '#0097D3', fontSize: "2.2rem", letterSpacing: "1px", lineHeight: '1.5', fontWeight: '800' }} > ELEVATE <b className='' style={{ fontSize: '10px', color: '#143d50' }} >BY INSTRUCTURE</b> </h2>
                                <h2 className='UbuntuFONTFAMILY ' style={{ color: '#156380', fontSize: "2.2rem", letterSpacing: "1px", lineHeight: '1.5', fontWeight: '800' }} > Education. Driven by Data. </h2>
                                <p className='SolutionDropDownFontFamily' style={{ color: '#143d50', fontSize: "1.225rem" }} > Provide educators, administrators, and district leaders with actionable data coupled with powerful analytical tools so they can effectively measure, manage, and improve outcomes with Elevate by Instructure.</p>
                                <div className='pt-4'>
                                  <button className='UbuntuFONTFAMILY ms-lg-3' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#287A9F", border: "none", color: "white", padding: "12px 15px" }} ><b className='p-2' style={{ fontSize: "1rem" }}>FOR K-12 <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        : display === "Impact" ?
                          <div className="container-fluid  mt-3">
                            <div className='row d-flex justify-content-between pt-5 mt-5'>
                              <div className="col-lg-6">
                                <img className='w-100' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../img/ter4.png")} alt='' />
                              </div>

                              <div className="col-lg-5">
                                <div>
                                  <h2 className='UbuntuFONTFAMILY' style={{ color: '#F76400', fontSize: "2.2rem", letterSpacing: "1px", lineHeight: '1.5', fontWeight: '800' }} > IMPACT <b className='' style={{ fontSize: '10px', color: '#143d50' }} >BY INSTRUCTURE</b> </h2>
                                  <h2 className='UbuntuFONTFAMILY ' style={{ color: '#156380', fontSize: "2.2rem", letterSpacing: "1px", lineHeight: '1.5', fontWeight: '800' }} > Streamlined edtech adoption. Higher impact. </h2>
                                  <p className='SolutionDropDownFontFamily' style={{ color: '#143d50', fontSize: "1.225rem" }} > Improve technology adoption and evaluate the impact of educational technology, while helping faculty and students seamlessly navigate new platforms with Impact by Instructure.</p>
                                  <div className='pt-4'>
                                    <button className='UbuntuFONTFAMILY mb-2' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "red", border: "none", color: "white", padding: "12px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", textTransform: "uppercase" }}> For Higher Education <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    <button className='UbuntuFONTFAMILY ms-lg-3' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#287A9F", border: "none", color: "white", padding: "12px 15px" }} ><b className='p-2' style={{ fontSize: "1rem" }}>FOR K-12 <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          : <div>
                            somethings went wrong
                          </div>
                }
              </div>



            </div>

          </div>
        </div>
      </section>

      <section>
        <div className="container p-lg-5 p-3 pt-3">
          <div className="row p-3 d-flex justify-content-center align-items-center" style={{ borderRadius: "10px 10px 10px 10px", backgroundImage: "linear-gradient(90deg,#287A9F 0%,#156380 100%)" }} >
            <div className="col-lg-6">
              <div className='text-white'>
                <h6 className='UbuntuFONTFAMILY' style={{ fontSize: ".875rem" }} >OVER 1 MILLION STRONG</h6>
                <h2 className='UbuntuFONTFAMILY' style={{ fontSize: "1.56rem", fontWeight: "600" }} >Join Our Community</h2>
                <p className='UbuntuFONTFAMILY mt-3' style={{ fontSize: "1.225rem", fontWeight: "300" }} >Meet our user community: over a million of the most insightful, talkative, learning-loving folks out there.</p>
                <div className='pt-4'>
                  <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#fff", border: "none", color: "#E4060F", padding: "12px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> CHECK OUT CUMMUNITY <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className=''>
                <img className='w-100' style={{ height: "22rem" }} src={require("../img/1.6m.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pt-3 pb-3">
          <div className="row d-flex justify-content-around">
            <div className="col-lg-5">
              <img className='w-100' style={{ height: "22rem" }} src={require("../img/last1.png")} alt='' />
            </div>

            <div className="col-lg-5">
              <div className='pt-3'>
                <h2 className='UbuntuFONTFAMILY' style={{ color: '#156380', fontSize: "2.5rem", letterSpacing: "1px", lineHeight: '1.5', fontWeight: '800' }} >Crafting Consistency with Canvas LMS</h2>
                <p className='SolutionDropDownFontFamily' style={{ color: '#143d50', fontSize: "1.225rem" }} >Discover how Poway Unified School District identified enduring digital learning practices to prepare their students, teachers, and families for the future with Canvas LMS.</p>
                <div className='pt-lg-4'>
                  <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "red", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem" }}>READ NOW <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
          <div className="container  p-lg-5 mt-lg-5">
            <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
              <div className='text-lg-center pb-lg-4'>
                <h2 className='pb-2 UbuntuFONTFAMILY' style={{ fontSize: '2.6rem', fontWeight: '800' }} >Ready to explore our learning ecosystem?</h2>
                <div className='pt-4 '>
                  <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Home