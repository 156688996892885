import React from "react";
import "./Articles.css";

function Ebooks() {
  return (
    <>
      <section
        style={{
          backgroundImage: `url(${require("../../img/mystudypage.jpg")})`,
          backgroundAttachment: "local",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: " center,center ",
        }}
      >
        <div className="container main-container">
          <div
            className="row p-lg-5 d-flex justify-content-around align-items-center"
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          >
            <div className="col-lg-6 p-4">
              <div>
                <p
                  style={{
                    fontSize: ".875rem",
                    lineHeight: "1.5",
                    textTransform: "uppercase",
                    letterSpacing: ".12em",
                    fontWeight: "700",
                    marginBottom: "5px",
                  }}
                >
                  Resource Center
                </p>
              </div>
              <div className="mb-3">
                <h1 className="UbuntuFONTFAMILY">
                  <p className="p-0 m-0 title-font-text" style={{}}>
                    The Instructure{" "}
                  </p>
                  <span id="font-text-red" className="title-font-text">
                    Study Hall
                  </span>
                </h1>
              </div>
              <div className="study-para-text">
                <p className="fontFamilyHeader fw-normal">
                  Take a deep-dive into ebooks and buyer guides designed to make
                  teaching and learning easier. These resources provide more
                  in-depth information on the solutions and products we offer.
                  Learn how our solutions make educators more effective and get
                  pro-tips on how best to use our products.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="row d-flex align-items-center"
                style={{ padding: "15px" }}
              >
                <div className="col-lg-6 col-5">
                  <div className="">
                    <img
                      className="research-img"
                      src={require("../../img/ebookCover-1.jpg")}
                      alt="resource-contain"
                    ></img>
                  </div>
                </div>
                <div className="col-lg-6 col-6 reserch-content">
                  <div>
                    <h6 className="research-font">EBOOK</h6>
                    <p className="h1FontFamily research-text">
                      Breaking Barriers: How Accessibility Transforms K-12
                      Education
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="row d-flex  align-items-center"
                style={{ padding: "15px" }}
              >
                <div className="col-lg-6 col-5">
                  <div className="">
                    <img
                      className="research-img"
                      src={require("../../img/ebookCover-2.jpg")}
                      alt="resource-contain"
                    ></img>
                  </div>
                </div>
                <div className="col-lg-6 col-6 reserch-content">
                  <div>
                    <h6 className="research-font">EBOOK</h6>
                    <p className="h1FontFamily research-text">
                      Strategies for Building Strong University and Corporate
                      Partnerships
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <nav className="container mt-4">
        <ul
          className="d-flex justify-content-start"
          style={{ listStyle: "none" }}
        >
          <li>
            <a href="/" style={{ textDecoration: "none", color: "#000" }}>
              Study Hall Main Hub &nbsp; &#x3009; &nbsp;
            </a>
          </li>

          <li>
            <a
              href="/"
              style={{
                textDecoration: "none",
                color: "#000",
                borderBottom: "1px solid black",
              }}
            >
              Ebooks Hub Page
            </a>
          </li>
        </ul>
      </nav>
      <section>
        <div className="layout container">
          <div className="layout-content">
            <div>
              <p
                className="text-center title-font-text"
                style={{ fontSize: "2.5rem" }}
              >
                Ebooks & Buyer’s Guides
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="m-3">
        <div className="container d-flex align-items-center justify-content-center p-2">
          <ul className=" blog-list">
            <li className="border-item h5 pe-5 me-3">
              Product
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="border-item h5 pe-5 me-3">
              Org Type
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="border-item h5 pe-5 me-3">
              Topic
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="border-item h5 pe-5 me-3">
              Search...
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="mb-2">
              <button className="btn-apply">
                <b className="p-2" style={{ fontSize: "1rem" }}>
                  Apply
                </b>
              </button>
            </li>
          </ul>
        </div>
      </section>
      <section>
        <div className="container ">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 d-sm-flex flex-sm-column justify-content-sm-center align-items-sm-center cards-resp-screen">
              <div className="row me-4 mt-2 g-4">
                {/* start-cards */}
                <div className="col-lg-8 col-md-6 p-3 ">
                  <div
                    className="h-100 bg-white blog-card shadow-lg SolutionDropDownFontFamily"
                    style={{
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div className="card-img">
                      <img
                        className="w-100"
                        src={require("../../img/ebook1.jpg")}
                        alt="blog-article"
                      />

                      <div className="p-3 article-content">
                        <h6
                          className="ps-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(78, 111, 126)",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Ebooks & Buyer’s Guides
                        </h6>

                        <h3
                          className="p-0 ps-2 shutter-btn"
                          style={{
                            lineHeight: "1.1",
                            color: "rgb(20, 61, 80)",
                            fontSize: "29px",
                            fontWeight: "700",
                          }}
                        >
                          AI Foundational Guide for Higher Education
                        </h3>

                        <div className="view-field-body">
                          <div
                            className=" ps-2"
                            style={{
                              lineHeight: "1.5",
                              color: "rgb(20, 61, 80)",
                              fontSize: "1rem",
                            }}
                          >
                            Let’s explore the foundations of AI in higher
                            education together.
                          </div>
                        </div>

                        <div className="btn-read">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none p-0 m-2"
                            style={{
                              color: "rgb(228, 6, 15)",
                              fontWeight: "600",
                            }}
                          >
                            LEARN MORE
                            <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ebook2.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Ebooks & Buyer’s Guides
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Through the Eyes of Educators: Cultivating…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        ></div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ebook3.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Ebooks & Buyer’s Guides
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        2023 Admin Analytics + Impact Data Sheets
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          2023 Admin Analytics + Impact Data Sheet
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          READ ON{" "}
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ebook4.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Ebooks & Buyer’s Guides
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Breaking Barriers: How Accessibility…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Explore how accessibility is for everyone.
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ebook5.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Ebooks & Buyer’s Guides
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Making Moments: Themes & Takeaways from…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Explore how VBCPS empowered 65K students with
                          future-ready skills & Canvas LMS.
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Row-Change-- */}

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ebook6.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Ebooks & Buyer’s Guides
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        AI Foundational Guide for K-12 Leaders and…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          We're living in the most transformative time in the
                          history of education, and we
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 col-md-6 p-3">
                  <div
                    className="h-100 bg-white blog-card shadow-lg SolutionDropDownFontFamily"
                    style={{
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div className="card-img">
                      <img
                        className="w-100"
                        src={require("../../img/ebook7.jpg")}
                        alt="blog-article"
                      />

                      <div className="p-3 article-content ">
                        <h6
                          className="ps-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(78, 111, 126)",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Ebooks & Buyer’s Guides
                        </h6>

                        <h3
                          className="p-0 ps-2 shutter-btn"
                          style={{
                            lineHeight: "1.1",
                            color: "rgb(20, 61, 80)",
                            fontSize: "29px",
                            fontWeight: "700",
                          }}
                        >
                          Accessibility in Higher Education: Building…
                        </h3>

                        <div className="view-field-body">
                          <div
                            className=" ps-2"
                            style={{
                              lineHeight: "1.5",
                              color: "rgb(20, 61, 80)",
                              fontSize: "1rem",
                            }}
                          ></div>
                        </div>

                        <div className="btn-read">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none p-0 m-2"
                            style={{
                              color: "rgb(228, 6, 15)",
                              fontWeight: "600",
                            }}
                          >
                            LEARN MORE
                            <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  Row-Change-- */}

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ebook8.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Ebooks & Buyer’s Guides
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Rapid-cycle Evaluation: An Efficient, Cost-…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        ></div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ebook9.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Ebooks & Buyer’s Guides
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        How to Simplify Grading for Equity
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Learn how to meet student needs equitably with the
                          right strategies.
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ebook10.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Ebooks & Buyer’s Guides
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Battling Burnout: Strategies to Increase…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Learn strategies and practical tips for supporting and
                          retaining teachers.
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ebook11.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Ebooks & Buyer’s Guides
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Addressing EdTech Challenges with Evidence:…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        ></div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ebook12.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Ebooks & Buyer’s Guides
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Your District’s Guide to Building an…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        ></div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ebookCover-2.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Ebooks & Buyer’s Guides
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Strategies for Building Strong University…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Discover three strategies for aligning educational
                          outcomes with workforce needs
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* --Row-change--- */}

                <div className="col-lg-8 col-md-6 p-3">
                  <div
                    className="h-100 bg-white blog-card shadow-lg SolutionDropDownFontFamily"
                    style={{
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div className="card-img">
                      <img
                        className="w-100"
                        src={require("../../img/ebook3.jpg")}
                        alt="blog-article"
                      />

                      <div className="p-3 article-content">
                        <h6
                          className="ps-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(78, 111, 126)",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Ebooks & Buyer’s Guides
                        </h6>

                        <h3
                          className="p-0 ps-2 shutter-btn"
                          style={{
                            lineHeight: "1.1",
                            color: "rgb(20, 61, 80)",
                            fontSize: "29px",
                            fontWeight: "700",
                          }}
                        >
                          20 Ways to Use Canvas for Face-to-Face…
                        </h3>

                        <div className="view-field-body">
                          <div
                            className="ps-2"
                            style={{
                              lineHeight: "1.5",
                              color: "rgb(20, 61, 80)",
                              fontSize: "1rem",
                            }}
                          >
                            Discover three strategies for aligning educational
                            outcomes with workforce needs
                          </div>
                        </div>

                        <div className="btn-read pt-lg-3">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none p-0 m-2"
                            style={{
                              color: "rgb(228, 6, 15)",
                              fontWeight: "600",
                            }}
                          >
                            LEARN MORE
                            <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ebook15.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Ebooks & Buyer’s Guides
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Canvas Credentials helps spotlight learning…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        ></div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* --Row-change--- */}
              </div>
            </div>
          </div>
        </div>
      </section>
      ;
    </>
  );
}

export default Ebooks;
