import React from "react";

const Canavas = () => {
  return (
    <>
      <section>
        <div className="container-fluid" style={{ backgroundColor: "#287a9f" }}>
          <div className="container p-1">
            <div className="row p-3 text-white d-flex justify-content-between">
              <div className="col-lg-2">
                <h5
                  className=""
                  style={{ fontSize: "1.125rem", fontWeight: "700" }}
                >
                  CANVAS
                </h5>
              </div>
              <div className="col-lg-4">
                <div className="ps-lg-5 ms-lg-5">
                  <h5
                    className=""
                    style={{ fontSize: "1.125rem", fontWeight: "700" }}
                  >
                    {" "}
                    Canvas for K-12 Product Offerings
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container p-5">
          <div className="row pt-5 d-flex justify-content-center ">
            <div className="col-lg-5">
              <div>
                <p
                  className=""
                  style={{
                    fontSize: ".875rem",
                    color: "#143d50",
                    letterSpacing: "1px",
                    fontWeight: "800",
                  }}
                >
                  CANVAS BY INSTRUCTURE
                </p>
                <h2
                  className="h1FontFamily"
                  style={{
                    fontSize: "3.5rem",
                    color: "#287a9f",
                    fontWeight: "800",
                    lineHeight: "1.1",
                  }}
                >
                  Teaching and learning excellence.{" "}
                  <span
                    className=""
                    style={{
                      color: "#e4060f",
                      letterSpacing: "1px",
                      fontWeight: "800",
                    }}
                  >
                    For everyone. Everywhere.
                  </span>
                </h2>
                <p
                  className="pt-3"
                  style={{ color: "#143d50", fontSize: "1.1rem" }}
                >
                  Equitable learning experiences for all students. The tools you
                  need for teaching and learning in one place. Connection
                  between teachers, students, and families, wherever learning
                  happens. Enter Canvas by Instructure, the hub of the digital
                  learning environment.
                </p>
                <div className="pt-4 ">
                  <button
                    className="UbuntuFONTFAMILY"
                    style={{
                      borderRadius: "20px 20px 20px 20px",
                      backgroundColor: "#E4060F",
                      border: "none",
                      color: "white",
                      padding: "10px 15px",
                    }}
                  >
                    <b
                      className="p-2"
                      style={{ fontSize: "1rem", fontWeight: "400" }}
                    >
                      {" "}
                      LET'S GET STARTED{" "}
                      <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i>
                    </b>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <img
                className="w-100 mt-3"
                style={{ borderRadius: "10px 10px 10px 10px" }}
                src={require("../../img/canvas1.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }}>
          <div className="container p-lg-5">
            <div className="d-flex justify-content-center text-center">
              <div className="" style={{ maxWidth: "40%" }}>
                <p
                  className=""
                  style={{
                    color: "#143d50",
                    fontWeight: "800",
                    letterSpacing: "2px",
                  }}
                >
                  OUR PRODUCTS
                </p>
                <h4
                  className="UbuntuFONTFAMILY"
                  style={{
                    color: "#156380",
                    fontSize: "2.5rem",
                    fontWeight: "700",
                    letterSpacing: "2px",
                  }}
                >
                  The Canvas Product Family
                </h4>
                <p
                  className="mt-3 h1FontFamily1"
                  style={{ color: "#444", fontSize: "1.125rem" }}
                >
                  Delivering what you need to support, enrich, and connect every
                  aspect of teaching and learning.
                </p>
              </div>
            </div>

            <div className="row p-lg-5 ">
              <div className="col-lg-4 col-md-6 ">
                <div className="p-lg-3">
                  <img
                    className=" mt-3"
                    style={{ height: "3.2rem" }}
                    src={require("../../img/icon.png")}
                    alt=""
                  />
                  <h6
                    className="mt-3 UbuntuFONTFAMILY"
                    style={{
                      color: "#156380",
                      fontSize: "1.975rem",
                      fontWeight: "800",
                    }}
                  >
                    Canvas LMS
                  </h6>
                  <p
                    className="mt-3 SolutionDropDownFontFamily"
                    style={{ color: "#143d50", fontSize: "1.125rem" }}
                  >
                    Course materials. Communication tools. Participation and
                    grades. Data and insights. All part of Canvas. On both your
                    desktop and mobile device. Ready to support learning
                    anywhere.
                  </p>
                  <div className="">
                    <button
                      type="button"
                      class="btn btn-link text-decoration-none"
                      style={{ color: "red", fontWeight: "700" }}
                    >
                      {" "}
                      LEARN MORE{" "}
                      <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="p-lg-3">
                  <img
                    className=" mt-3"
                    style={{ height: "3.2rem" }}
                    src={require("../../img/icon.png")}
                    alt=""
                  />
                  <h6
                    className="mt-3 UbuntuFONTFAMILY"
                    style={{
                      color: "#156380",
                      fontSize: "1.975rem",
                      fontWeight: "800",
                    }}
                  >
                    Canvas Studio
                  </h6>
                  <p
                    className="mt-3 SolutionDropDownFontFamily"
                    style={{ color: "#143d50", fontSize: "1.125rem" }}
                  >
                    {" "}
                    It’s the next-generation video learning solution that turns
                    one‑way, passive video into inclusive, engaging, and
                    productive classroom discussions.
                  </p>
                  <div className="">
                    <button
                      type="button"
                      class="btn btn-link text-decoration-none"
                      style={{ color: "red", fontWeight: "700" }}
                    >
                      {" "}
                      LEARN MORE{" "}
                      <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="p-lg-3">
                  <img
                    className=" mt-3"
                    style={{ height: "3.2rem" }}
                    src={require("../../img/icon.png")}
                    alt=""
                  />
                  <h6
                    className="mt-3 UbuntuFONTFAMILY"
                    style={{
                      color: "#156380",
                      fontSize: "1.975rem",
                      fontWeight: "800",
                    }}
                  >
                    Canvas Catalog
                  </h6>
                  <p
                    className="mt-3 SolutionDropDownFontFamily"
                    style={{ color: "#143d50", fontSize: "1.125rem" }}
                  >
                    {" "}
                    A simple, modern course catalog registration system and
                    branded marketplace for all of your district’s professional
                    development and community resource offerings.
                  </p>
                  <div className="">
                    <button
                      type="button"
                      class="btn btn-link text-decoration-none"
                      style={{ color: "red", fontWeight: "700" }}
                    >
                      {" "}
                      LEARN MORE{" "}
                      <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row ps-lg-4 d-flex justify-content-center ">
              <div className="col-lg-4 ">
                <div className="p-lg-3">
                  <img
                    className=" mt-3"
                    style={{ height: "3.2rem" }}
                    src={require("../../img/icon.png")}
                    alt=""
                  />
                  <h6
                    className="mt-3 UbuntuFONTFAMILY"
                    style={{
                      color: "#156380",
                      fontSize: "1.975rem",
                      fontWeight: "800",
                    }}
                  >
                    Canvas Credentials
                  </h6>
                  <p
                    className="mt-3 SolutionDropDownFontFamily"
                    style={{ color: "#143d50", fontSize: "1.125rem" }}
                  >
                    {" "}
                    Canvas Credentials empowers educators with more than just a
                    transcript. With verifiable digital credentials, they have a
                    comprehensive learner record to demonstrate progress towards
                    their goals.
                  </p>
                  <div className="">
                    <button
                      type="button"
                      class="btn btn-link text-decoration-none"
                      style={{ color: "red", fontWeight: "700" }}
                    >
                      {" "}
                      LEARN MORE{" "}
                      <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }}>
          <div className="container p-5">
            <div className="row pt-5 d-flex justify-content-center ">
              <div className="col-lg-5">
                <div>
                  <p
                    className=""
                    style={{
                      fontSize: ".875rem",
                      color: "#143d50",
                      letterSpacing: "1px",
                      fontWeight: "800",
                    }}
                  >
                    TEACHING AND LEARNING NEEDS
                  </p>
                  <h2
                    className="h1FontFamily"
                    style={{
                      fontSize: "2.5rem",
                      color: "#287a9f",
                      fontWeight: "800",
                      lineHeight: "1.1",
                    }}
                  >
                    {" "}
                    Canvas has you covered.{" "}
                  </h2>
                  <p
                    className="pt-3 pb-4"
                    style={{ color: "#143d50", fontSize: "1.1rem" }}
                  >
                    {" "}
                    K-12 looks different these days. But one thing remains the
                    same: you need a reliable learning platform that serves as
                    the foundation for teaching and learning. In and out of the
                    classroom. For all students and a variety of learning
                    experiences. Anytime, Anywhere.
                  </p>
                  <p className="">
                    <i
                      class="fa-regular fa-circle-check "
                      style={{ color: "red", fontSize: "22px" }}
                    ></i>
                    <span
                      className="ps-2"
                      style={{
                        color: "#143d50",
                        fontSize: "1.35rem",
                        fontWeight: "600",
                      }}
                    >
                      Simplicity and ease of use
                    </span>
                  </p>
                  <p className="">
                    <i
                      class="fa-regular fa-circle-check "
                      style={{ color: "red", fontSize: "22px" }}
                    ></i>
                    <span
                      className="ps-2"
                      style={{
                        color: "#143d50",
                        fontSize: "1.35rem",
                        fontWeight: "600",
                      }}
                    >
                      Communication and collaboration
                    </span>
                  </p>
                  <p className="">
                    <i
                      class="fa-regular fa-circle-check "
                      style={{ color: "red", fontSize: "22px" }}
                    ></i>
                    <span
                      className="ps-2"
                      style={{
                        color: "#143d50",
                        fontSize: "1.35rem",
                        fontWeight: "600",
                      }}
                    >
                      Mobility and accessibility
                    </span>
                  </p>
                  <p className="">
                    <i
                      class="fa-regular fa-circle-check "
                      style={{ color: "red", fontSize: "22px" }}
                    ></i>
                    <span
                      className="ps-2"
                      style={{
                        color: "#143d50",
                        fontSize: "1.35rem",
                        fontWeight: "600",
                      }}
                    >
                      Unparalleled reliability and security
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <img
                  className="w-100 "
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                  src={require("../../img/canvas2.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="conatiner-fluid">
          <div className="container p-5">
            <div className="row p-lg-5 ">
              <div className="col-lg-4 col-md-12">
                <div>
                  <p
                    className=""
                    style={{
                      fontSize: ".875rem",
                      color: "#143d50",
                      letterSpacing: "1px",
                      fontWeight: "800",
                    }}
                  >
                    EXPLORE OUR PRODUCTS
                  </p>
                  <h2
                    className="h1FontFamily"
                    style={{
                      fontSize: "2.8rem",
                      color: "#287a9f",
                      fontWeight: "800",
                      lineHeight: "1.2",
                    }}
                  >
                    {" "}
                    Discover the Instructure Learning Platform{" "}
                  </h2>
                  <p
                    className="pt-3"
                    style={{
                      color: "#143d50",
                      fontSize: "1.25rem",
                      lineHeight: "1.3",
                    }}
                  >
                    {" "}
                    The Instructure Learning Platform delivers what you need to
                    enrich, support, and connect every aspect of teaching and
                    learning.
                  </p>
                  <div className="pt-4 pb-5">
                    <button
                      className="UbuntuFONTFAMILY shadow-lg"
                      style={{
                        borderRadius: "20px 20px 20px 20px",
                        backgroundImage:
                          "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)",
                        border: "none",
                        color: "#e4060f",
                        padding: "10px 15px",
                      }}
                    >
                      <b
                        className="p-2"
                        style={{ fontSize: "1rem", fontWeight: "400" }}
                      >
                        {" "}
                        LEARN MORE ABOUT US{" "}
                        <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i>
                      </b>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="row">
                  <div className="col-lg">
                    <div
                      className="p-lg-3 shadow-lg"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="p-3">
                        <img
                          className=" mt-3"
                          style={{ height: "3.2rem" }}
                          src={require("../../img/icon.png")}
                          alt=""
                        />
                        <h6
                          className="mt-3 UbuntuFONTFAMILY"
                          style={{
                            color: "#156380",
                            fontSize: "1.975rem",
                            fontWeight: "800",
                          }}
                        >
                          Canvas LMS
                        </h6>
                        <p
                          className="mt-3 SolutionDropDownFontFamily"
                          style={{ color: "#143d50", fontSize: "1.25rem" }}
                        >
                          {" "}
                          Simplify teaching and learning activities, organise
                          coursework, and keep teachers, students, and families
                          connected and communicating. Anytime, anywhere.
                        </p>
                        <div className="">
                          <button
                            type="button"
                            class="btn btn-link text-decoration-none"
                            style={{ color: "red", fontWeight: "700" }}
                          >
                            {" "}
                            LEARN MORE{" "}
                            <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg pt-5">
                      <div
                        className="p-lg-3 shadow-lg"
                        style={{ backgroundColor: "#fff" }}
                      >
                        <div className="p-3">
                          <img
                            className=" mt-3"
                            style={{ height: "3.2rem" }}
                            src={require("../../img/icon.png")}
                            alt=""
                          />
                          <h6
                            className="mt-3 UbuntuFONTFAMILY"
                            style={{
                              color: "#156380",
                              fontSize: "1.975rem",
                              fontWeight: "800",
                            }}
                          >
                            Canvas Catalog
                          </h6>
                          <p
                            className="mt-3 SolutionDropDownFontFamily"
                            style={{ color: "#143d50", fontSize: "1.25rem" }}
                          >
                            {" "}
                            Catalog is a simple, modern course catalog
                            registration system and branded marketplace for all
                            of your district’s professional development and
                            community resource offerings.
                          </p>
                          <div className="">
                            <button
                              type="button"
                              class="btn btn-link text-decoration-none"
                              style={{ color: "red", fontWeight: "700" }}
                            >
                              {" "}
                              LEARN MORE{" "}
                              <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                <div className="row">
                  <div className="col-lg ">
                    <div
                      className="p-lg-3 pt-5 mt-5 shadow-lg"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="p-3">
                        <img
                          className=" mt-3"
                          style={{ height: "3.2rem" }}
                          src={require("../../img/icon.png")}
                          alt=""
                        />
                        <h6
                          className="mt-3 UbuntuFONTFAMILY"
                          style={{
                            color: "#156380",
                            fontSize: "1.975rem",
                            fontWeight: "800",
                          }}
                        >
                          Canvas Studio
                        </h6>
                        <p
                          className="mt-3 SolutionDropDownFontFamily"
                          style={{ color: "#143d50", fontSize: "1.25rem" }}
                        >
                          {" "}
                          It’s the next-generation video learning solution that
                          turns one‑way, passive video into inclusive, engaging,
                          and productive classroom discussions.
                        </p>
                        <div className="">
                          <button
                            type="button"
                            class="btn btn-link text-decoration-none"
                            style={{ color: "red", fontWeight: "700" }}
                          >
                            {" "}
                            LEARN MORE{" "}
                            <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg ">
                      <div
                        className="p-lg-3 pt-5 mt-5 shadow-lg"
                        style={{ backgroundColor: "#fff" }}
                      >
                        <div className="p-3">
                          <img
                            className=" mt-3"
                            style={{ height: "3.2rem" }}
                            src={require("../../img/icon.png")}
                            alt=""
                          />
                          <h6
                            className="mt-3 UbuntuFONTFAMILY"
                            style={{
                              color: "#156380",
                              fontSize: "1.975rem",
                              fontWeight: "800",
                            }}
                          >
                            Impact by Instructure
                          </h6>
                          <p
                            className="mt-3 SolutionDropDownFontFamily"
                            style={{ color: "#143d50", fontSize: "1.25rem" }}
                          >
                            {" "}
                            Improve technology adoption, support teachers and
                            students in their tech use, and evaluate the impact
                            of educational technology on student engagement.
                          </p>
                          <div className="">
                            <button
                              type="button"
                              class="btn btn-link text-decoration-none"
                              style={{ color: "red", fontWeight: "700" }}
                            >
                              {" "}
                              LEARN MORE{" "}
                              <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container-fluid" style={{ backgroundColor: "#176684" }}>
          <div className="container  p-lg-5 ">
            <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
              <div className="text-lg-center pb-lg-4">
                <h2
                  className="pb-2 UbuntuFONTFAMILY"
                  style={{ fontSize: "2.6rem", fontWeight: "800" }}
                >
                  Ready to explore our learning ecosystem?
                </h2>
                <div className="pt-4 ">
                  <button
                    className="UbuntuFONTFAMILY"
                    style={{
                      borderRadius: "20px 20px 20px 20px",
                      backgroundImage:
                        "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)",
                      border: "none",
                      color: "#e4060f",
                      padding: "10px 15px",
                    }}
                  >
                    <b
                      className="p-2"
                      style={{ fontSize: "1rem", fontWeight: "400" }}
                    >
                      {" "}
                      LET'S GET STARTED{" "}
                      <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i>
                    </b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Canavas;
