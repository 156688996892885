import React from "react";
import "./AIresources.css";

function AIresources() {
  return (
    <>
      <section
        style={{
          backgroundImage: `url(${require("../../img/AI-Resources.jpg")})`,
          backgroundAttachment: "local",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: " center,bottom ",
        }}
      >
        <div className="container">
          <div
            className="row p-lg-5 d-flex justify-content-center align-items-center"
            style={{
              borderRadius: "10px",
            }}
          >
            <div className="col-lg-6 paragraph-content ">
              <div className="m-2 title-container">
                <h1 className="UbuntuFONTFAMILY">
                  <p className="p-0 title-font-text" style={{}}>
                    <span className="title-font-text " id="font-text-red">
                      {" "}
                      AI Resources
                    </span>{" "}
                    Hub
                  </p>

                  <div className="study-para-text">
                    <p className="fontFamilyHeader fw-normal">
                      Generative AI is a transformative technology that will
                      elevate student success, empower educators, and enhance
                      the ways we teach and learn. We believe–when implemented
                      with an intentional, secure, and human-driven
                      approach–Generative AI has the power to promote equitable
                      and impactful learning experiences for everyone.
                    </p>
                  </div>
                </h1>
                <div className="Jump-To">
                  <button className="jump-to-arror-btn ">
                    <b className="p-2" style={{ fontSize: "1rem" }}>
                      Jump to Resources
                      <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                    </b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }}>
          <div className="container p-5">
            <div className="row p-5 pt-5 d-flex justify-content-between ">
              <div className="col-lg-6 ">
                <img
                  className="w-100"
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                  src={require("../../img/AI-Resource-sect2.jpg")}
                  alt=""
                />
              </div>

              <div className="col-lg-5">
                <div>
                  <p
                    className=""
                    style={{
                      fontSize: ".875rem",
                      color: "#143d50",
                      letterSpacing: "1px",
                      fontWeight: "800",
                      marginTop: "15px",
                    }}
                  >
                    OUR APPROACH
                  </p>
                  <h2
                    className="h1FontFamily"
                    style={{
                      fontSize: "2.5rem",
                      color: "#287a9f",
                      fontWeight: "800",
                      lineHeight: "1.1",
                    }}
                  >
                    {" "}
                    Intentional, Safe, and Equitable
                  </h2>
                  <p
                    className="pt-3 pb-4"
                    style={{ color: "#143d50", fontSize: "1.1rem" }}
                  >
                    {" "}
                    We are committed to partnering with our customers and the
                    edtech community at large to develop a positive and
                    responsible approach to the use of AI. Highlights of our
                    policy include:
                  </p>
                  <p className="">
                    <i
                      class="fa-regular fa-circle-check "
                      style={{ color: "red", fontSize: "22px" }}
                    ></i>
                    <span
                      className="ps-2"
                      style={{
                        color: "#143d50",
                        fontSize: "1.35rem",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      Transparency and Accountability
                    </span>
                  </p>
                  <p className="">
                    <i
                      class="fa-regular fa-circle-check "
                      style={{ color: "red", fontSize: "22px" }}
                    ></i>
                    <span
                      className="ps-2"
                      style={{
                        color: "#143d50",
                        fontSize: "1.35rem",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      Bias and Fairness
                    </span>
                  </p>
                  <p className="">
                    <i
                      class="fa-regular fa-circle-check "
                      style={{ color: "red", fontSize: "22px" }}
                    ></i>
                    <span
                      className="ps-2"
                      style={{
                        color: "#143d50",
                        fontSize: "1.35rem",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      Human-AI Collaboration
                    </span>
                  </p>

                  <div className="Jump-To">
                    <button className="jump-to-arror-btn ">
                      <b
                        className=""
                        style={{
                          fontSize: "1rem",
                          textTransform: "uppercase",
                          lineHeight: "1.45",
                        }}
                      >
                        Read our full AI Governance Policy
                        <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                      </b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="container ">
          <div className="title-guides p-lg-5 text-center">
            <h2
              className="UbuntuFONTFAMILY pt-3 pb-4 "
              style={{
                color: "rgb(21, 99, 128)",
                letterSpacing: "1px",
                fontSize: "2.5rem",
                fontWeight: "800",
                lineHeight: "1.2",
              }}
            >
              Artificial Intelligence Resources
            </h2>
            <div className="d-flex justify-content-center">
              <p style={{ fontSize: "1.125rem" }}>
                AI in education promises to reshape the way we learn and teach.
                We’ll be publishing how-to tips, guides, and insights as we
                continue to navigate the evolving AI landscape.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="container d-flex align-items-center justify-content-center p-2">
          <ul className=" blog-list ">
            <li className="border-item h5 p-3">
              Resource Type
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>

            <li className="border-item h5 p-3 ">
              Search...
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="ps-3">
              <button className="AI_btn-apply">
                <b className="p-2" style={{ fontSize: "1rem" }}>
                  APPLY
                </b>
              </button>
            </li>
          </ul>
        </div>
      </section>

      <section>
        <div className="container ">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 d-sm-flex flex-sm-column justify-content-sm-center align-items-sm-center cards-resp-screen">
              <div className="row mt-2 g-4">
                <div className="col-lg-8 col-md-6 p-3 ">
                  <div
                    className="h-100 bg-white blog-card shadow-lg SolutionDropDownFontFamily"
                    style={{
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div className="card-img">
                      <img
                        className="w-100"
                        src={require("../../img/ebook1.jpg")}
                        alt="blog-article"
                      />

                      <div className="p-3 article-content">
                        <h6
                          className="ps-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(78, 111, 126)",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Ebooks & Buyer’s Guides
                        </h6>

                        <h3
                          className="p-0 ps-2 shutter-btn"
                          style={{
                            lineHeight: "1.1",
                            color: "rgb(20, 61, 80)",
                            fontSize: "29px",
                            fontWeight: "700",
                          }}
                        >
                          AI Foundational Guide for Higher Education
                        </h3>

                        <div className="view-field-body">
                          <div
                            className=" ps-2"
                            style={{
                              lineHeight: "1.5",
                              color: "rgb(20, 61, 80)",
                              fontSize: "1rem",
                            }}
                          >
                            Let’s explore the foundations of AI in higher
                            education together.
                          </div>
                        </div>

                        <div className="btn-read">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none p-0 m-2"
                            style={{
                              color: "rgb(228, 6, 15)",
                              fontWeight: "600",
                            }}
                          >
                            LEARN MORE
                            <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ebook3.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Case Studies
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Clemson University: Creating Better Student…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Learn how Clemson University provides 24/7 educational
                          support for students.
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/blogImage-14.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Unveiling Insights from the 2023 State of…
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Unveiling Insights from the 2023 State of…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        ></div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ebook3.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Blog Articles
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        Instructure is Aligned with the White House’…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        ></div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/ai-cardImg.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Blog Articles
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Digital Transformation in Higher Ed: Lessons…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          This year at Educause, Instructure hosted a Lunch
                          Briefing in collaboration with
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#176684" }}>
          <div className="container p-lg-5 mt-lg-5">
            <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
              <div className="text-lg-center pb-lg-4">
                <label>
                  <p>
                    <b>#KeepLearning</b>
                  </p>
                </label>
                <h2
                  className="pb-2 UbuntuFONTFAMILY"
                  style={{ fontSize: "2.6rem", fontWeight: "800" }}
                >
                  Join the AI Conversation
                </h2>
                <div className="d-flex justify-content-center align-items-center p-3 ">
                  <p style={{ fontSize: "1.225rem" }}>
                    The response to AI has been a mix of excitement and caution.
                    See what other administrators and educators are discussing
                    in the Instructure Community to discover the possibilities
                    for yourself.
                  </p>
                </div>
                <div className="pt-4 ">
                  <button
                    className="UbuntuFONTFAMILY"
                    style={{
                      borderRadius: "20px 20px 20px 20px",
                      backgroundImage:
                        "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)",
                      border: "none",
                      color: "#e4060f",
                      padding: "10px 15px",
                    }}
                  >
                    <b
                      className="p-2"
                      style={{ fontSize: "1rem", fontWeight: "400" }}
                    >
                      Take me to the Community
                      <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i>
                    </b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <nav className="container mt-4">
        <ul
          className="d-flex justify-content-start"
          style={{ listStyle: "none" }}
        >
          <li>
            <a href="/" style={{ textDecoration: "none", color: "#000" }}>
              Home &#12296; &nbsp;
            </a>
          </li>
          <li>
            <a href="/" style={{ textDecoration: "none", color: "#000" }}>
              Study Hall Main Hub &#12296; &nbsp; &nbsp; &nbsp;
            </a>
          </li>

          <li>
            <a
              href="/"
              style={{
                textDecoration: "none",
                color: "#000",
                borderBottom: "1px solid black",
              }}
            >
              AI Resources Hub
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default AIresources;
