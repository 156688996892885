import React from "react";
import "./Intructurecon24.css";

const Instructurecon2024 = () => {
  return (
    <>
      <section>
        <div
          className="container-fluid d-none d-sm-block"
          style={{
            backgroundImage: `url(${require("../../img/Intructurecon24BG.jpg")})`,
            backgroundAttachment: "local",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: " center,bottom ",
          }}
        >
          <div className="container">
            <div
              className="row p-lg-5 d-flex justify-content-center align-items-center"
              style={{
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-8 paragraph-content ">
                <div className="m-2 title-container">
                  <h1 className="UbuntuFONTFAMILY">
                    <p className="p-0 title-font-text" style={{}}>
                      <span className="title-font-text ">
                        {" "}
                        InstructureCon 2024
                      </span>{" "}
                      <span id="font-text-red">is on!</span>
                    </p>

                    <div>
                      <h4 className="title-date">July 9th - 11th, 2024</h4>
                      <h4 className="title-date">Venetian Resort, Las Vegas</h4>
                    </div>

                    <div className="study-para-text">
                      <p className="fontFamilyHeader fw-normal">
                        Join us for three days full of insightful presenters,
                        informative sessions, excellent entertainment, and more
                        ways to connect and engage than ever before!
                      </p>
                    </div>
                  </h1>
                  <div className="Jump-To">
                    <button
                      className=""
                      style={{
                        borderRadius: "20px 20px 20px 20px",
                        backgroundColor: "red",
                        border: "none",
                        color: "white",
                        padding: "10px 15px",
                      }}
                    >
                      <b className="p-2" style={{ fontSize: "1rem" }}>
                        REGISTER NOW
                        <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                      </b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid d-sm-none d-block"
          style={{
            backgroundImage: `url(${require("../../img/Intructurecon24BG.jpg")})`,
            backgroundAttachment: "local",
            backgroundRepeat: "no-repeat",
            height: "350px",
            backgroundSize: "cover",
            backgroundPosition: " center,bottom ",
          }}
        >
          <div className="container pt-5">
            <div
              className="row  d-flex justify-content-center align-items-center"
              style={{
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-12 paragraph-content ">
                <div className=" title-container">
                  <h1 className="UbuntuFONTFAMILY">
                    <p className="title-font-text" style={{}}>
                      <span className="title-font-text ">
                        {" "}
                        InstructureCon 2024
                      </span>{" "}
                      <span id="font-text-red">is on!</span>
                    </p>

                    <div>
                      <h4 className="title-date">July 9th - 11th, 2024</h4>
                      <h4 className="title-date">Venetian Resort, Las Vegas</h4>
                    </div>

                    <div className="study-para-text">
                      <p className="fontFamilyHeader fw-normal">
                        Join us for three days full of insightful presenters,
                        informative sessions, excellent entertainment, and more
                        ways to connect and engage than ever before!
                      </p>
                    </div>
                  </h1>
                  <div className="Jump-To">
                    <button
                      className=""
                      style={{
                        borderRadius: "20px 20px 20px 20px",
                        backgroundColor: "red",
                        border: "none",
                        color: "white",
                        padding: "10px 15px",
                      }}
                    >
                      <b className="p-2" style={{ fontSize: "1rem" }}>
                        REGISTER NOW
                        <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                      </b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container d-none d-sm-block">
          <div className="layout-content my-5 pb-5">
            <div className="layout-region p-5 ">
              <div className="block-layout-builder position-relative block-card-grid">
                <h2
                  id="why-attend-instrurecon UbuntuFONTFAMILY "
                  style={{
                    textAlign: "center",
                    fontSize: "2.5rem",
                    fontWeight: "700",
                  }}
                >
                  <p>Why Attend InstructureCon?</p>
                </h2>
                <div className="feildname-card">
                  <div className="feild-item">
                    <div className="paragraph paragraph-type-icon-card">
                      <div className="feild-name--feild-icon">
                        <div className="number-design" style={{ color: "red" }}>
                          1
                        </div>
                      </div>
                      <h3 className="feild-name--feild-heading">
                        <p> Learn</p>
                      </h3>
                      <div className="clearfix feild-name--feild-description">
                        <p>
                          Gain valuable insights and best practices directly
                          from fellow educators focused on <br /> redefining
                          teaching and <br />
                          learning.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="feild-item">
                    <div className="paragraph paragraph-type-icon-card">
                      <div className="feild-name--feild-icon">
                        <div
                          className="number-design"
                          style={{ color: "orange" }}
                        >
                          2
                        </div>
                      </div>
                      <h3 className="feild-name--feild-heading">
                        <p>Grow</p>
                      </h3>
                      <div className="clearfix feild-name--feild-description">
                        <p>
                          Explore solutions to the biggest educational
                          challenges you face today.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="feild-item">
                    <div className="paragraph paragraph-type-icon-card">
                      <div className="feild-name--feild-icon">
                        <div className="number-design ">3</div>
                      </div>
                      <h3 className="feild-name--feild-heading">
                        <p>Connect</p>
                      </h3>
                      <div className="clearfix feild-name--feild-description">
                        <p>
                          Engage with your peers, meet up with our partners, and
                          expand your network.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="feild-item">
                    <div className="paragraph paragraph-type-icon-card">
                      <div className="feild-name--feild-icon">
                        <div className="number-design text-warning">4</div>
                      </div>
                      <h3 className="feild-name--feild-heading">
                        <p>Experience</p>
                      </h3>
                      <div className="clearfix feild-name--feild-description">
                        <p>
                          The magic and passion that only comes from three days
                          of inspiration, innovation, and insights.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container d-sm-none d-block"
          style={{ marginTop: "210px" }}
        >
          <div className="layout-content">
            <div className="layout-region ">
              <div className="block-layout-builder position-relative block-card-grid">
                <h2
                  className="pb-5 UbuntuFONTFAMILY "
                  id="why-attend-instrurecon"
                  style={{
                    fontSize: "26px",
                    fontWeight: "700",
                  }}
                >
                  Why Attend InstructureCon?
                </h2>
                <div className="feildname-card">
                  <div className="feild-item">
                    <div className="paragraph paragraph-type-icon-card">
                      <div className="feild-name--feild-icon">
                        <div className="number-design" style={{ color: "red" }}>
                          1
                        </div>
                      </div>
                      <h3 className="feild-name--feild-heading UbuntuFONTFAMILY">
                        <p> Learn</p>
                      </h3>
                      <div className="clearfix feild-name--feild-description">
                        <p>
                          Gain valuable insights and best practices directly
                          from fellow educators focused on redefining teaching
                          and learning.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="feild-item">
                    <div className="paragraph paragraph-type-icon-card">
                      <div className="feild-name--feild-icon ">
                        <div
                          className="number-design"
                          style={{ color: "orange" }}
                        >
                          2
                        </div>
                      </div>
                      <h3 className="feild-name--feild-heading UbuntuFONTFAMILY">
                        <p>Grow</p>
                      </h3>
                      <div className="clearfix feild-name--feild-description">
                        <p>
                          Explore solutions to the biggest educational
                          challenges you face today.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="feild-item">
                    <div className="paragraph paragraph-type-icon-card">
                      <div className="feild-name--feild-icon">
                        <div className="number-design ">3</div>
                      </div>
                      <h3 className="feild-name--feild-heading UbuntuFONTFAMILY">
                        <p>Connect</p>
                      </h3>
                      <div className="clearfix feild-name--feild-description">
                        <p>
                          Engage with your peers, meet up with our partners, and
                          expand your network.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="feild-item">
                    <div className="paragraph paragraph-type-icon-card">
                      <div className="feild-name--feild-icon">
                        <div className="number-design text-warning">4</div>
                      </div>
                      <h3 className="feild-name--feild-heading UbuntuFONTFAMILY">
                        <p>Experience</p>
                      </h3>
                      <div className="clearfix feild-name--feild-description">
                        <p>
                          The magic and passion that only comes from three days
                          of inspiration, innovation, and insights.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="layout-content container mt-5">
          <div className="layout-region row mx-0">
            <div className="block-layout-builder col-lg-6 col-md-6 UbuntuFONTFAMILY">
              <h2 className="feild-name--feild-heading ps-2 ">
                <p>What to Expect in Vegas</p>
              </h2>
              <div className="clearfix feild-name--feild-description">
                <ul>
                  <li>
                    <strong>Educator-led sessions</strong>
                    on the pedagogical and technological topics most important
                    to you.
                  </li>
                  <li>
                    <strong>Product announcements</strong>
                    and what's coming next for the Instructure ecosystem.
                  </li>
                  <li>
                    Discussions on top-of-mind education issues and trends, like
                    generative AI, equity in education, and lifelong learning.
                  </li>
                  <li>
                    Sessions that teach new ways to use your favorite edtech
                    tools—and g
                    <strong>
                      et acquainted with some cool new tools, too.
                    </strong>
                  </li>
                  <li>
                    Attendee favorites (think hack night and game night!) &nbsp;
                  </li>
                  <li>
                    Immersive experiences, including a hands-on learning lab, a
                    genius bar, poster sessions, and even more opportunities to
                    share fresh ideas and network.
                  </li>
                  <li>
                    <strong>Thousands of education professionals</strong>, ready
                    to learn and connect.
                  </li>
                  <li>
                    An experiential Expo Hall full of partners ready to talk
                    integrations, innovations, and more.
                  </li>
                </ul>
              </div>
            </div>

            <div className="block-layout-builder container col-lg-6 col-md-6">
              <div className="clearfix feild-name--feild-description p-3">
                <img
                  src={require("../../img/las-vegas2024.jpg")}
                  className="w-100 p-lg-2"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="layout-content container my-5 p-3">
          <div className="layout-region row d-md-flex">
            <div className="block-layout-builder container col-lg-6 col-md-6">
              <div className="clearfix feild-name--feild-description px-2 mb-5">
                <img
                  src={require("../../img/instructureconDigital.jpg")}
                  className="w-100 p-lg-4"
                />
              </div>
            </div>

            <div className="block-layout-builder container col-lg-6 col-md-6 px-3">
              <h2 className="feild-name--feild-heading UbuntuFONTFAMILY  ">
                <p>Present at InstructureCon</p>
              </h2>
              <div className="clearfix feild-name--feild-description my-3 study-para-text">
                <p className="fontFamilyHeader fw-normal">
                  Interested in sharing your knowledge? Call for Proposals will
                  open January 22nd and close in March. We're looking for
                  first-hand experiences, best practices, and strategies for
                  success that will inspire our attendees and expand their
                  thinking—because that's what education's all about.
                </p>
              </div>
              <div className="Jump-To">
                <button className="present-btn">
                  <b className="p-2" style={{ fontSize: "1rem" }}>
                    Learn More
                    <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                  </b>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="layout-content my-5 pb-5 UbuntuFONTFAMILY">
          <div className="layout-region p-lg-5 ">
            <div className="block-layout-builder position-relative block-card-grid">
              <h2 id="why-attend-instrurecon" className="Schedule-title">
                <p>Schedule at a Glance</p>
              </h2>
              <div className="feildname-card  ">
                <div className="feild-item me-2">
                  <div className="paragraph paragraph-type-icon-card">
                    <div className="feild-name--feild-icon day-icon">
                      <div
                        className="number-design"
                        style={{
                          color: "red",
                          fontSize: "6rem",
                        }}
                      >
                        Tues
                      </div>
                    </div>
                    <h3 className="feild-name--feild-heading scheduleCard-date  mt-4">
                      <p> July 9</p>
                    </h3>
                    <div className="clearfix feild-name--feild-description text-center">
                      <p>
                        <strong>Registration</strong>
                        (opens at 9:00) &nbsp;
                      </p>
                      <p>
                        <strong>Opening Keynote </strong>
                        (opens at 9:00) &nbsp;
                      </p>
                      <p>
                        <strong>Breakout Sessions </strong>
                      </p>
                      <p>
                        <strong>Welcome Reception in the Expo Hall</strong>
                      </p>
                      <p>
                        <strong>Sponsor Excursions</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="feild-item me-2">
                  <div className="paragraph paragraph-type-icon-card">
                    <div className="feild-name--feild-icon day-icon">
                      <div
                        className="number-design"
                        style={{
                          color: "orange",
                          fontSize: "6rem",
                        }}
                      >
                        Weds
                      </div>
                    </div>
                    <h3 className="feild-name--feild-heading mt-4 scheduleCard-date ">
                      <p> July 10</p>
                    </h3>
                    <div className="clearfix feild-name--feild-description text-center">
                      <p>
                        <strong>General Session</strong>
                      </p>
                      <p>
                        <strong>Breakout Sessions </strong>
                      </p>
                      <p>
                        <strong>Dinner and Party</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="feild-item">
                  <div className="paragraph paragraph-type-icon-card">
                    <div className="feild-name--feild-icon day-icon">
                      <div
                        className="number-design"
                        style={{ fontSize: "6rem" }}
                      >
                        Thurs
                      </div>
                    </div>
                    <h3 className="feild-name--feild-heading mt-4 scheduleCard-date ">
                      <p> July 11</p>
                    </h3>
                    <div className="clearfix feild-name--feild-description text-center">
                      <p>
                        <strong>Breakout Sessions</strong>
                      </p>
                      <p>
                        <strong>Birds of a Feather Lunch </strong>
                      </p>
                      <p>
                        <strong>Closing Keynote</strong>
                        (2:00 pm) &nbsp;
                      </p>
                      <p>
                        <strong>Unconference</strong>
                        (3:30) &nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Jump-To text-center mt-lg-5 Sched-Reg-btn">
                <button className="present-btn">
                  <b className="p-2" style={{ fontSize: "1rem" }}>
                    REGISTER NOW
                    <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                  </b>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="layout-content my-5 pb-3 UbuntuFONTFAMILY">
          <div className="layout-region p-lg-5">
            <div className="block-layout-builder block-card-grid container">
              <h2 className="justAFew-title">
                <p>
                  Just A Few Of The Many Topics <br /> We’ll Explore Together
                </p>
              </h2>
              <div className="feildname-card row">
                <div className="feild-item col-lg-6">
                  <div className="paragraph paragraph-type-icon-card">
                    <div className="clearfix feild-name--feild-description text-center">
                      <img
                        src={require("../../img/justAfew.jpg")}
                        alt="side-JustImage"
                        className="w-100"
                      />
                    </div>
                  </div>
                </div>
                <div className="feild-item col-lg-3 col-md-6 col-6 p-lg-2 justAFew-list-together">
                  <div className="paragraph">
                    <div
                      className="justAFew-list "
                      style={{ color: "color: #143d50;" }}
                    >
                      <ul style={{ listStyleType: "none" }}>
                        <li>
                          <strong>Accessibility</strong>
                        </li>
                        <li>
                          <strong>Adoption Best Practices</strong>
                        </li>
                        <li>
                          <strong>AI (Artificial Intelligence)</strong>
                        </li>
                        <li>
                          <strong>Assessment</strong>
                        </li>
                        <li>
                          <strong>Badging and Micro-Credentialing</strong>
                        </li>
                        <li>
                          <strong>Competency-based Education</strong>
                        </li>
                        <li>
                          <strong>Data and Analytics</strong>,
                        </li>
                        <li>
                          <strong>Data Security and Privacy</strong>
                        </li>
                        <li>
                          <strong>Edtech Management</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="feild-item col-lg-3 col-md-6 col-6 p-lg-2 justAFew-list-together">
                  <div className="paragraph">
                    <div
                      className="justAFew-list"
                      style={{ color: "color: #143d50;" }}
                    >
                      <ul style={{ listStyleType: "none" }}>
                        <li>
                          <strong>Diversity, Equity, and Inclusion</strong>
                        </li>
                        <li>
                          <strong>Adoption Best</strong>
                        </li>
                        <li>
                          <strong>Learner Engagement </strong>
                        </li>
                        <li>
                          <strong>Learner Success</strong>
                        </li>
                        <li>
                          <strong>Lifelong Learning</strong>
                        </li>
                        <li>
                          <strong>Partner Integrations</strong>
                        </li>
                        <li>
                          <strong>Personalized Learning</strong>,
                        </li>
                        <li>
                          <strong>Professional Development</strong>
                        </li>
                        <li>
                          <strong>Workforce Readiness</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="layout-content container my-5">
          <div className="layout-region row d-md-flex mx-3">
            <div className="block-layout-builder container col-lg-6 col-md-6 p-lg-4 pb-5">
              <h2 className="feild-name--feild-heading  UbuntuFONTFAMILY  ">
                <p>Become an InstructureCon Sponsor</p>
              </h2>
              <div className="clearfix feild-name--feild-description my-3 study-para-text">
                <p className="fontFamilyHeader fw-normal">
                  Don’t miss the opportunity to connect with thousands of
                  educational leaders at the premier user conference for the #1
                  LMS.
                </p>
              </div>
              <div className="Jump-To">
                <button
                  className=""
                  style={{
                    borderRadius: "20px 20px 20px 20px",
                    backgroundColor: "red",
                    border: "none",
                    color: "white",
                    padding: "10px 15px",
                  }}
                >
                  <b className="p-2" style={{ fontSize: "1rem" }}>
                    View Sponsorship Opportunities
                    <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                  </b>
                </button>
              </div>
            </div>

            <div className="block-layout-builder container col-lg-6 col-md-6">
              <div className="clearfix feild-name--feild-description">
                <img
                  src={require("../../img/Instructurecon_registration_web-13.jpg")}
                  className="w-100 p-lg-4"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="layout-content my-lg-5 pb-lg-5">
          <div className="layout-region  p-lg-5  UbuntuFONTFAMILY">
            <div className="block-layout-builder position-relative block-card-grid p-3">
              <p className="attendee-feedback">ATTENDEE FEEDBACK</p>
              <h2 id="why-attend-instrurecon " className="justAFew-title">
                <p>
                  Don't take our word for it! See what <br />
                  InstructureCon ‘23 attendees had to say:
                </p>
              </h2>

              <div className="feildname-card  ">
                <div className="w-100">
                  <div className="feild-item p-lg-5">
                    <div>
                      <i
                        class="fa-solid fa-quote-left"
                        style={{ fontSize: "80px", color: "red" }}
                      ></i>
                      <p
                        style={{
                          fontSize: "1.4rem",
                          lineHeight: "30px",
                        }}
                      >
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Qui aliquid nemo reprehenderit fuga earum dicta
                        dolore alias ex rerum. Cum, magnam! Quam illo ex
                        similique!
                      </p>
                    </div>
                  </div>
                  <div className="feild-item p-lg-5">
                    <div>
                      <i
                        class="fa-solid fa-quote-left"
                        style={{ fontSize: "80px" }}
                      ></i>
                      <p
                        className=""
                        style={{
                          fontSize: "1.4rem",
                          lineHeight: "30px",
                        }}
                      >
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Qui aliquid nemo reprehenderit fuga earum dicta
                        dolore alias ex rerum. Cum, magnam! Quam illo ex
                        similique!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-100">
                  <div className="feild-item p-lg-5 ">
                    <div>
                      <i
                        class="fa-solid fa-quote-left"
                        style={{ fontSize: "80px", color: "orange" }}
                      ></i>
                      <p
                        className=""
                        style={{
                          fontSize: "1.4rem",
                          lineHeight: "30px",
                        }}
                      >
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Qui aliquid nemo reprehenderit fuga earum dicta
                        dolore alias ex rerum. Cum, magnam! Quam illo ex
                        similique!
                      </p>
                    </div>
                  </div>

                  <div className="feild-item p-lg-5">
                    <div>
                      <i
                        class="fa-solid fa-quote-left"
                        style={{ fontSize: "80px", color: "yellowgreen" }}
                      ></i>
                      <p
                        className=""
                        style={{
                          fontSize: "1.4rem",
                          lineHeight: "30px",
                        }}
                      >
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Qui aliquid nemo reprehenderit fuga earum dicta
                        dolore alias ex rerum. Cum, magnam! Quam illo ex
                        similique!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div
          className="container-fluid UbuntuFONTFAMILY"
          style={{
            backgroundImage: `url(${require("../../img/instructure18bg.jpg")})`,
            backgroundAttachment: "local",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: " center,center ",
            height: "100%",
          }}
        >
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center p-lg-5 p-5 ">
              <div
                className="p-lg-5 col-lg-9 "
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px 10px 10px 10px",
                }}
              >
                <h2 className="ready-title text-lg-center Exp-best-Tsize pb-3">
                  Experience the Best Moments from InstructureCon 2023
                </h2>

                <div>
                  <div className="Acc-the-contButton">
                    <button
                      type="button"
                      className=" "
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "rgb(228, 6, 15)",
                        border: "none",
                        color: "white",
                        padding: "10px 15px",
                      }}
                    >
                      <b
                        className="p-2"
                        style={{
                          fontSize: "1rem",
                          fontWeight: "400",
                          textTransform: "uppercase",
                        }}
                      >
                        Access the content
                        <i className="ms-3 fa-sharp fa-solid fa-arrow-right"></i>
                      </b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Instructurecon2024;
