import React, { useState } from 'react'

const K12Solutoins = () => {

  const [display, setDisplay] = useState("Learning Management");

  const [btn1, setBtn1] = useState("newStyle");

  const [btn2, setBtn2] = useState("btnStyle");

  const [btn3, setBtn3] = useState("btn3Style");

  const [btn4, setBtn4] = useState("btn4Style");

  const changleDisplay = () => {
    setDisplay("Learning Management")
    setBtn1("newStyle")
    setBtn2("btnStyle")
  }

  const changleDisplay1 = () => {
    setDisplay("Assessment")
    setBtn1("btnStyle")
    setBtn2("newStyle")
  }

  const changleDisplay3 = () => {
    setDisplay("Analytics")
    setBtn3("btn3Style")
    setBtn4("btn4Style")
  }

  const changleDisplay4 = () => {
    setDisplay("Professional Development")
    setBtn3("btn4Style")
    setBtn4("btn3Style")
  }

  return (

    <>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-lg-5">
            <div className="row p-lg-5 pt-5 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >THE INSTRUCTURE LEARNING PLATFORM</p>
                  <h2 className='' style={{ fontSize: "61px", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > The Digital Classroom for <span className='' style={{ color: "#24a159", letterSpacing: "1px", fontWeight: "800" }}> K–12 Connection </span></h2>
                  <p className='mb-4' style={{ fontSize: "1.2rem", color: "#143d50" }}> Every lesson has the power to change lives. Even more so with our teaching and learning solutions in your corner. We’ll help you create a digital classroom for all aspects of K–12 learning, whether you’re in person, blended, or online. </p>
                  <div className='pt-2 pb-2'>
                    <button className='SolutionDropDownFontFamily' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "12px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400", letterSpacing: "2px" }}> REQUEST INFO  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../img/k-12-solution.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pb-5">
          <div className=" pt-5 pb-5 p-lg-4 ">
            <div className="row p-lg-5 d-flex justify-content-center align-items-center ">
              <div className='text-center  pb-lg-5'>
                <h6 className='UbuntuFONTFAMILY' style={{ color: '#143d50', letterSpacing: "1px", fontSize: ".875rem", fontWeight: "800" }} > TEACHING & LEARNING NEEDS </h6>
                <h2 className='UbuntuFONTFAMILY pt-3 pb-2' style={{ color: '#156380', letterSpacing: "1px", fontSize: "2.6rem", fontWeight: "800", lineHeight: "1.2" }} > Instructure has you covered. </h2>
                <div className='d-flex justify-content-center'>
                  <p className='' style={{ fontSize: "1.3rem" }} > Our solutions deliver what you need to support, enrich, and connect every aspect of teaching and learning. </p>
                </div>
              </div>
              <div className="row  d-flex justify-content-center " style={{ borderBottom: '1px solid #ccdce4' }} >
                <div className="col-lg d-flex justify-content-center fontFamilyHeader1" style={{ fontSize: "1.325rem" }} >
                  <button className={btn1} style={{ color: "red", fontWeight: "800" }} onClick={changleDisplay}>Learning Management</button>
                </div>

                <div className="col-lg  d-flex justify-content-center fontFamilyHeader1" style={{ fontSize: "1.325rem" }} >
                  <button className={btn2} style={{ fontWeight: "800" }} onClick={changleDisplay1}>Assessment</button>
                </div>

                <div className="col-lg  d-flex justify-content-center fontFamilyHeader1" style={{ fontSize: "1.325rem" }} >
                  <button className={btn3} style={{ fontWeight: "800" }} onClick={changleDisplay3}>Analytics</button>
                </div>

                <div className="col-lg d-flex justify-content-center fontFamilyHeader1" style={{ fontSize: "1.270rem" }} >
                  <button className={btn4} style={{ fontWeight: "800" }} onClick={changleDisplay4}>Professional Development</button>
                </div>
              </div>

              <div >
                {

                  display === "Learning Management" ?
                    <div className="container-fluid  mt-3">
                      <div className='row d-flex justify-content-around pt-lg-5 mt-lg-5'>
                        <div className="col-lg-5">
                          <div>
                            <h2 className='UbuntuFONTFAMILY ' style={{ color: '#156380', fontSize: "1.7rem", letterSpacing: "1px", fontWeight: '800' }} >Create captivating lessons. Develop tech-savvy learners. Anytime.</h2>
                            <p className='SolutionDropDownFontFamily pt-2 pb-2' style={{ color: '#143d50', fontSize: "17px" }} > Whether you’re all together in person or all over the place, Canvas by Instructure keeps you connected, learning, and communicating with consistency.</p>
                            <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-3' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >A foundation for instructional continuity</span></p>
                            <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-3' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Mobility and accessibility</span></p>
                            <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-3' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Everyone's connected</span></p>
                          </div>
                          <div className='pt-4 text-lg-center pb-3'>
                            <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#fff", border: "none", color: "red", padding: "10px 15px", letterSpacing: "2px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LEARN MORE <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                          </div>
                        </div>

                        <div className="col-lg-5">
                          <img className='w-100' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../img/k-12-solution1.png")} alt='' />
                        </div>
                      </div>
                    </div>

                    : display === "Assessment" ?
                      <div className="container-fluid  mt-3">
                        <div className='row d-flex justify-content-around pt-lg-5 mt-lg-5'>
                          <div className="col-lg-5">
                            <div>
                              <h2 className='UbuntuFONTFAMILY ' style={{ color: '#156380', fontSize: "1.7rem", letterSpacing: "1px", fontWeight: '800' }} > Address individual learning needs. Adjust instruction in real time.</h2>
                              <p className='SolutionDropDownFontFamily pt-2 pb-2' style={{ color: '#143d50', fontSize: "17px" }} > And promote student growth every step of the way. Find out exactly what your students know—and develop personalized learning pathways that support their next steps.</p>
                              <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-3' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Flexible assessment tools</span></p>
                              <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-3' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Valid, reliable assessment content</span></p>
                              <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-3' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Insights that drive instruction</span></p>
                            </div>
                            <div className='pt-4 text-lg-center pb-3'>
                              <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#fff", border: "none", color: "red", padding: "10px 15px", letterSpacing: "2px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LEARN MORE <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                            </div>
                          </div>

                          <div className="col-lg-5">
                            <img className='w-100' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../img/k-12-solution2.png")} alt='' />
                          </div>
                        </div>
                      </div>

                      : display === "Analytics" ?
                        <div className="container-fluid  mt-3">
                          <div className='row d-flex justify-content-around pt-lg-5 mt-lg-5'>
                            <div className="col-lg-5">
                              <div>
                                <h2 className='UbuntuFONTFAMILY ' style={{ color: '#156380', fontSize: "1.7rem", letterSpacing: "1px", fontWeight: '800' }} > Put actionable insights into the hands of educators. </h2>
                                <p className='SolutionDropDownFontFamily pt-2 pb-2' style={{ color: '#143d50', fontSize: "17px" }} > Provide your teachers, principals, and district leaders with actionable data coupled with powerful analytical tools, and watch them improve student outcomes. </p>
                                <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-3' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Interactive visualizations of integrated data</span></p>
                                <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-3' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >A complete picture of each student</span></p>
                                <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-3' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Monitor programs and track progress</span></p>
                              </div>
                              <div className='pt-4 text-lg-center pb-3'>
                                <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#fff", border: "none", color: "red", padding: "10px 15px", letterSpacing: "2px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LEARN MORE <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                              </div>
                            </div>

                            <div className="col-lg-5">
                              <img className='w-100' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../img/k-12-solution3.png")} alt='' />
                            </div>
                          </div>
                        </div>

                        : display === "Professional Development" ?
                          <div className="container-fluid  mt-3">
                            <div className='row d-flex justify-content-around pt-lg-5 mt-lg-5'>
                              <div className="col-lg-5">
                                <div>
                                  <h2 className='UbuntuFONTFAMILY ' style={{ color: '#156380', fontSize: "1.7rem", letterSpacing: "1px", fontWeight: '800' }} >Become an even better educator with the best professional development.</h2>
                                  <p className='SolutionDropDownFontFamily pt-2 pb-2' style={{ color: '#143d50', fontSize: "17px" }} > Quality teaching is the #1 in-school factor for K–12 student achievement. Fortunately, we have tech tools to help you scale equitable PD across the district with unique professional learning offerings that can increase teacher efficacy. So you can better support your students every day.</p>
                                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-3' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >PD delivery tools</span></p>
                                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-3' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Center for Leadership and Learning</span></p>
                                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-3' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Exclusive events</span></p>
                                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-3' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Canvas educator certification</span></p>
                                </div>
                                <div className='pt-4 text-lg-center pb-3'>
                                  <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#fff", border: "none", color: "red", padding: "10px 15px", letterSpacing: "2px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LEARN MORE <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                </div>
                              </div>

                              <div className="col-lg-5">
                                <img className='w-100' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../img/k-12-solution4.png")} alt='' />
                              </div>
                            </div>
                          </div>

                          : <div>
                            somethings went wrong
                          </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-lg-5">
            <div className="row p-lg-5 ">
              <div className="col-lg-4 col-md-12">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE OUR PRODUCTS</p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Discover the Instructure Learning Platform </h2>
                  <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > The Instructure Learning Platform delivers what you need to enrich, support, and connect every aspect of teaching and learning.</p>
                  <div className='pt-4 pb-5'>
                    <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="row">
                  <div className="col-lg">
                    <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../img/icon.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas by Instructure</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Simplify teaching and learning activities, organize coursework, and keep teachers, students, and families connected and communicating. Anytime, anywhere.</p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg pt-5">
                      <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                        <div className='p-3'>
                          <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../img/ms2.png")} alt='' />
                          <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Elevate by Instructure</h6>
                          <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Provide educators, administrators, and district leaders with actionable data coupled with powerful analytical tools so they can effectively measure, manage, and improve outcomes.</p>
                          <div className=''>
                            <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg pt-5">
                      <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                        <div className='p-3'>
                          <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../img/ms6.png")} alt='' />
                          <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >LearnPlatform EdTech Effectiveness</h6>
                          <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Edtech management, evaluation, and research solutions to drive equitable, evidence-based decisions that support better teaching and learning outcomes. </p>
                          <div className=''>
                            <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                <div className="row">
                  <div className="col-lg ">
                    <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../img/ms1.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Mastery by Instructure</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Drive learning outcomes and standards mastery with high-quality assessments and actionable insights that inform and improve teaching and learning. </p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg ">
                      <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                        <div className='p-3'>
                          <img className=' mt-2' style={{ height: "3.2rem" }} src={require("../img/ms3.png")} alt='' />
                          <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Impact by Instructure</h6>
                          <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Improve technology adoption, support teachers and students in their tech use, and evaluate the impact of educational technology on student engagement.</p>
                          <div className=''>
                            <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container p-5">
            <div className="row  d-flex justify-content-center">
              <div className='pb-lg-5 d-flex justify-content-center text-center'>
                <div className='pt-3' style={{ maxWidth: "50%" }} >
                  <p className='' style={{ color: "#143d50", fontWeight: "800", letterSpacing: "2px" }} > INTEGRATIONS </p>
                  <h4 className='SolutionDropDown pt-2' style={{ color: "#156380", fontSize: "2.6rem", fontWeight: "800", letterSpacing: "2px" }} > Thousands of digital tools and app integrations. </h4>
                </div>
              </div>
              <div className="col-lg-5 p-3">
                <div className='shadow-lg p-4 h-100' style={{ backgroundColor: "#fff", borderRadius: "10px 10px 10px 10px" }} >
                  <div className='p-2'>
                    <p className='SolutionDropDownFontFamily' style={{ color: "#287a9f", fontSize: "1.875rem", lineHeight: "1.34", fontWeight: "800" }} >Microsoft Teams</p>
                    <p className='SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", lineHeight: "1.5" }} >Meet with your classes virtually—right inside of Canvas LMS—with the Microsoft Teams integration.</p>
                    <img className='' style={{ height: "3rem" }} src={require("../img/k1.png")} alt='' />
                  </div>
                </div>
              </div>

              <div className="col-lg-5 p-3">
                <div className='shadow-lg p-4 h-100' style={{ backgroundColor: "#fff", borderRadius: "10px 10px 10px 10px" }} >
                  <div className='p-2'>
                    <p className='SolutionDropDownFontFamily' style={{ color: "#287a9f", fontSize: "1.875rem", lineHeight: "1.34", fontWeight: "800" }} > Nearpod </p>
                    <p className='SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", lineHeight: "1.5" }} > Easily add Nearpod lessons into Canvas modules, assignments, or pages. </p>
                    <img className='' style={{ height: "3rem" }} src={require("../img/k2.png")} alt='' />
                  </div>
                </div>
              </div>
            </div>

            <div className="row  d-flex justify-content-center">
              <div className="col-lg-5 p-3">
                <div className='shadow-lg p-4 h-100' style={{ backgroundColor: "#fff", borderRadius: "10px 10px 10px 10px" }} >
                  <div className='p-2'>
                    <p className='SolutionDropDownFontFamily' style={{ color: "#287a9f", fontSize: "1.875rem", lineHeight: "1.34", fontWeight: "800" }} > Google Assignments </p>
                    <p className='SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", lineHeight: "1.5" }} > Integrate Google Docs and Drive—and distribute, analyze, and grade students’ work with the collaborative power of G Suite. </p>
                    <img className='' style={{ height: "3rem" }} src={require("../img/k3.png")} alt='' />
                  </div>
                </div>
              </div>

              <div className="col-lg-5 p-3">
                <div className='shadow-lg p-4 h-100' style={{ backgroundColor: "#fff", borderRadius: "10px 10px 10px 10px" }} >
                  <div className='p-2'>
                    <p className='SolutionDropDownFontFamily' style={{ color: "#287a9f", fontSize: "1.875rem", lineHeight: "1.34", fontWeight: "800" }} > Discovery Education </p>
                    <p className='SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", lineHeight: "1.5" }} > Access Discovery Education navigation, modules, assignments, and pages from Canvas LMS.</p>
                    <img className='' style={{ height: "3rem" }} src={require("../img/k4.png")} alt='' />
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-center pt-4 mt-2'>
              <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> READ MORE <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container p-5 p-4 pb-5">
          <div className="row p-3 d-flex justify-content-center align-items-center" style={{ borderRadius: "10px 10px 10px 10px", backgroundImage: "linear-gradient(90deg,#287A9F 0%,#156380 100%)" }} >
            <div className="col-lg-6">
              <div className='text-white SolutionDropDownFontFamily p-lg-5'>
                <h6 className='' style={{ fontSize: ".875rem", fontWeight: "800" }} >OUR COMMUNITY</h6>
                <h2 className='' style={{ fontSize: "2.7rem", fontWeight: "800" }} >Join Our Community</h2>
                <p className=' mt-3' style={{ fontSize: "1.225rem", fontWeight: "300" }} >Meet the Instructure community: over a million of the most insightful, talkative, learning-loving folks out there.</p>
                <div className='pt-4'>
                  <button className='' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#fff", border: "none", color: "#E4060F", padding: "12px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> CHECK OUT THE CUMMUNITY <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className=''>
                <img className='w-100' style={{ height: "22rem" }} src={require("../img/1.5.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5 ">
            <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
            <div className="row d-flex justify-content-center p-5">
              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px" }} src={require("../img/ks1.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Research Reports</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }} >The State of Assessment in K-12 Education: Study & Trends</h4>
                    <div className='pt-lg-2'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px" }} src={require("../img/ks2.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Ebooks & Buyer’s Guides</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }}>Through the Eyes of Educators: Insights into Student Engagement</h4>
                    <div className='pt-lg-3'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px" }} src={require("../img/ks3.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Ebooks & Buyer’s Guides</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }}>Your Guide to Choosing a Learning Management System</h4>
                    <div className='pt-lg-5'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
          <div className="container  p-lg-5 ">
            <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
              <div className='text-lg-center pb-lg-4'>
                <h2 className='pb-2 SolutionDropDownFontFamily' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to explore our learning ecosystem? </h2>
                <div className='pt-4 '>
                  <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>

  )
}

export default K12Solutoins