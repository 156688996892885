import React, { useState } from 'react'

const LMS = () => {

    const [change1, setChange1] = useState(false);
    const [change2, setChange2] = useState(false);
    const [change3, setChange3] = useState(false);
    const [change4, setChange4] = useState(false);
    const [change5, setChange5] = useState(false);
    const [change6, setChange6] = useState(false);

    const clickMe1 = () => {
        setChange1(!change1)
        setChange2(false)
        setChange3(false)
        setChange4(false)
        setChange5(false)
    }
    const clickMe2 = () => {
        setChange1(false)
        setChange2(!change2)
        setChange3(false)
        setChange4(false)
        setChange5(false)
    }
    const clickMe3 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(!change3)
        setChange4(false)
        setChange5(false)
    }

    const clickMe4 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(false)
        setChange4(!change4)
        setChange5(false)
    }

    const clickMe5 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(false)
        setChange4(false)
        setChange5(!change5)
    }

    const clickMe6 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(false)
        setChange4(false)
        setChange5(false)
        setChange6(!change6)
    }

    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#287a9f" }} >
                    <div className="container p-1">
                        <div className="row p-3 text-white d-flex justify-content-between">
                            <div className='col-lg-2'>
                                <h5 className='ps-lg-3' style={{ fontSize: "1.125rem", fontWeight: "700" }} >CANVAS</h5>
                            </div>
                            <div className="col-lg-3">
                                <div className='ps-lg-5 ms-lg-5'>
                                    <h5 className='ps-lg-5 ms-lg-5' style={{ fontSize: "1.125rem", fontWeight: "700" }} > Canvas LMS</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container p-5">
                    <div className="row pt-5 d-flex justify-content-center ">
                        <div className="col-lg-5">
                            <div>
                                <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >LEARNING MANAGEMENT SYSTEM.</p>
                                <h2 className='h1FontFamily' style={{ fontSize: "3.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Your Classroom. <span className='' style={{ color: "#e4060f", letterSpacing: "1px", fontWeight: "800" }}> To the Power of Canvas LMS. </span></h2>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <p className='mb-5' style={{ fontSize: "1.2rem", color: "#143d50" }}>Canvas is the open, extensible K–12 LMS built to amplify teaching and maximize learning.</p>
                            <div className='pt-4 '>
                                <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> REQUEST A DEMO <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                <button className='UbuntuFONTFAMILY shadow-lg ms-4' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#fff", border: "none", color: "red", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> TRY CANVAS FREE <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-5 pt-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} >Canvas LMS lets you focus more on teaching (and less on tasks).</h2>
                                    <p className='pt-3' style={{ color: "#143d50", fontSize: "1.25rem", }} > Provide equitable access and instructional continuity. For every student. Everywhere. Every day. Canvas LMS is the robust digital foundation for all aspects of learning. </p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="row shadow-lg" style={{ backgroundColor: "#fff", borderRadius: "10px 10px 10px 10px" }} >
                                    <div className="col-lg-6 p-0">
                                        <img className='w-100 ' style={{ borderRadius: "10px 0px 0px 10px", height: "18rem" }} src={require("../../img/lms1.png")} alt='' />
                                    </div>
                                    <div className="col-lg-5  mt-4"  >
                                        <div className='ps-3'>
                                            <h2 className='h1FontFamily ' style={{ fontSize: "1.5rem", color: "#143d50", lineHeight: "1.6" }} > Your Guide To Choosing a Learning Management System </h2>
                                        </div>
                                        <div className='ps-1'>
                                            <button type="button" class="btn btn-link text-decoration-none pt-2" style={{ color: "red", fontWeight: "600", fontSize: ".875rem" }} > GET THE GUIDE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container mt-5 mb-5 p-4">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-5">
                                <img className='imgRESOPNSIVE' src={require("../../img/math.png")} alt='' />
                                <div className='ps-lg-5 ms-lg-2'>
                                    <p className='fontFamilyHeader' style={{ fontSize: "1.55rem", color: '#287a9f', fontWeight: "800", lineHeight: "1.4" }} > " Canvas LMS lets us connect with humans more effectively and make resources available 24/7. " </p>
                                    <h6 className='fontFamilyHeader pt-2' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "800" }} > — Ryan Hansen</h6>
                                    <h6 className='fontFamilyHeader' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "400" }}>Director of Learning, Davis Schools, UT</h6>
                                </div>
                            </div>
                            <div className="col-lg-6   p-5 shadow-lg" style={{ backgroundColor: "#fff",borderRadius:"10px 10px 10px 10px" }} >
                                <h2 className='ps-5 p-3 h1FontFamily mt-4' style={{ color: "#156380", fontSize: "2.5rem", lineHeight: "1.2" }} >Centralized (yet <br /> personalized) K-12 <br /> curriculum and <br /> instruction.</h2>
                                <div className="table p-4 d-flex justify-content-center">
                                    <div className='w-100 ' >
                                        <div className=' '  >
                                            <div className='w-100 p-0'  >
                                                <button className='text-start bg-body  w-100  p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe1() }}> <h3 className='fs-5'> <b>{change1 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span>  <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className=' ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Everything in One Place </span></b></h3></button>
                                                <div className=''>
                                                    {
                                                        change1 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Course materials. Participation and grades. Communication tools. Actionable insights. All right there in Canvas LMS. Ready to support in-person, online, and blended learning.</p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe2() }}><h3 className='fs-5'> <b> {change2 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >  Accessibility All the Way </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change2 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > All students deserve learning tools that meet their unique needs. Canvas LMS ensures that students, teachers, and parents have equitable access to all materials and instruction. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start  w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe3() }}> <h3 className='fs-5'><b> {change3 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i></span> : <span> <i className="fa-solid fa-plus " style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Interactive Analytics </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change3 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Data can be daunting. Canvas Admin Analytics makes it easy to view Canvas usage, and allows administrators to see and understand the data that matters most to them. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe4() }}><h3 className='fs-5'> <b> {change4 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >   Instant Content Delivery </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change4 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Easily upload and share course information in a range of useful, engaging ways. Use Blueprints to share curriculum districtwide. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe5() }}><h3 className='fs-5'> <b> {change5 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >   Personalized Learning </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change5 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > MasteryPaths lets you instantly deliver content and instruction appropriate to each student’s level of understanding and performance. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe6() }}><h3 className='fs-5'> <b> {change6 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ss ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >  Boost Productivity </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change6 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > You only have one person's worth of time. Spend it on learning, and let Canvas LMS handle the "management." </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-4">
                        <div className="row p-5 pt-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >FEATURES AND INTEGRATIONS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Digital tools that play nicely with others. </h2>
                                    <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.1rem", }} > Rubrics. Modules. Calendars. Schedules. Quizzes. Syllabi. Analytics. SpeedGrader (cue applause and wild cheering). Canvas LMS is outfitted with smart, intuitive features that will help take learning to the next level. And then there's the integrations! The Canvas API links right up with all your favorite apps and tools: G Suite, Microsoft Teams, Adobe, and hundreds more, in one centralized learning hub.</p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Heaps of helpful features</span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Hundreds of integrations</span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >All inside of Canvas LMS</span></p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/canvas2.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section >
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-5 pt-5 d-flex justify-content-around ">

                            <div className="col-lg-5">
                                <img className='w-100 mt-3' src={require("../../img/lms2.png")} alt='' />
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >MOBILE APPS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Learning is on the move. </h2>
                                    <p className='pt-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Canvas LMS has top-rated mobile apps for teachers, students, and parents to keep everyone informed and connected, on the go, on any device. Teachers can engage with students whenever and wherever through an app that boasts best-in-class data security. (It also comes with SpeedGrader, which can cut grading time by 50%.)</p>
                                </div>
                                <div>
                                    <h2 className='pt-2 SolutionDropDownFontFamily' style={{ fontSize: "1.3rem", color: "#287a9f", fontWeight: "700", lineHeight: "1.5" }} > " Our teachers were all using different platforms, different software, and Canvas allowed them to be unified. Parents were happy, students were happy, and faculty were happy. " </h2>
                                    <h6 className='fontFamilyHeader pt-4' style={{ fontSize: "1.125rem", color: '#287a9f', fontWeight: "600" }} > Nichole Lemmon </h6>
                                    <h6 className='fontFamilyHeader' style={{ fontSize: "1.125rem", color: '#287a9f', fontWeight: "400" }}> Director of Blended Learning, Springfield Public Schools, Springfield, MO </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-4">
                        <div className="row pt-5 p-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >COLLABORATION TOOLS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Talking in class is encouraged. </h2>
                                    <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.1rem" }} > Teachers can communicate with individual students, groups, the entire class, or even parents through messaging, audio notes, video, and more. And students can collaborate among themselves via chat group, video, and other messaging tools.</p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.15rem", fontWeight: "600" }} > Messaging with students, groups, or the whole  <span className='ps-lg-3 ms-lg-3'>class</span>  </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.15rem", fontWeight: "600" }} > Audio and video communication </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.15rem", fontWeight: "600" }} > All sorts of student collaboration tools </span></p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/lms3.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#F2F8FA" }} >
                    <div className="container p-lg-5">

                        <div className='d-flex justify-content-center text-center'>
                            <div className='' style={{ maxWidth: "40%" }} >
                                <p className='' style={{ color: "#143d50", fontWeight: "800", letterSpacing: "2px" }} >MORE FEATURES</p>
                                <h4 className='UbuntuFONTFAMILY pt-2' style={{ color: "#156380", fontSize: "2.2rem", fontWeight: "700", letterSpacing: "2px" }} >All the features you need. None you don’t.</h4>
                            </div>
                        </div>

                        <div className="row p-lg-5 ">
                            <div className="col-lg-4 col-md-6 ">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Undivided Attention</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Enrich lessons with video and audio, and even include face-to-face interactions. All from within Canvas.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >99.9% Uptime</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > A down LMS can really grind your teaching and learning gears. Canvas keeps your educational ecosystem going strong.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Grades at Lightning Speed</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Offer targeted feedback with annotations. Use rubrics for scoring. Share comments via video. Post grades to the Gradebook and quickly create eye-opening reports about student progress.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Unlimited File Size</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > If it will enrich your students’ learning experiences, in it goes. Add as much audio, visuals, and video content as you like.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Insights All Around</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Course analytics serve up data that offer insights on students' interactions with instructional materials—useful info when it comes to adapting instruction.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Cloud-Based <br />(and Born)</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > That means no downtime due to upgrades. Simply log in for instant connection from anywhere—on just about any computer or smart handheld device.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-lg-5 ">
                            <div className="col-lg-4 col-md-12">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE ALL PRODUCTS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Explore the Entire Canvas Product Family </h2>
                                    <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > Simplify teaching and learning activities, organize coursework and keep teachers, students, and families connected and communicating. Anytime, anywhere.</p>
                                    <div className='pt-4 pb-5'>
                                        <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="row">
                                    <div className="col-lg">
                                        <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Studio</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > It’s the next-generation video learning solution that turns one‑way, passive video into inclusive, engaging, and productive classroom discussions.</p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg pt-5">
                                            <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                                <div className='p-3'>
                                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Credentials</h6>
                                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Canvas Credentials empowers educators with more than just a transcript. With verifiable digital credentials, they have a comprehensive learner record to demonstrate progress towards their goals.</p>
                                                    <div className=''>
                                                        <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                                <div className="row">
                                    <div className="col-lg ">
                                        <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Catalog</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Catalog is a simple, modern course catalog registration system and branded marketplace for all of your district’s professional development and community resource offerings.</p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg ">
                                            <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                                <div className='p-3'>
                                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Impact by Instructure</h6>
                                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Improve technology adoption, support teachers and students in their tech use, and evaluate the impact of educational technology on student engagement.</p>
                                                    <div className=''>
                                                        <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5 ">
                        <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
                        <div className="row d-flex justify-content-center p-5">
                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px" }} src={require("../../img/lms4.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Blog Articles</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }} >Canvas for Elementary: Supporting Young Learners as They Return to the Classroom</h4>
                                        <div className=''>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px" }} src={require("../../img/lms4.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Blog Articles</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }}>5 Reasons to Use Canvas LMS in the (Physical) Classroom</h4>
                                        <div className='pt-lg-5 mt-lg-4'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px" }} src={require("../../img/lms4.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Case Studies</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }}>Canyons School District: Creating Districtwide Unity with Canvas</h4>
                                        <div className='pt-4 mt-3'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
                    <div className="container  p-lg-5 ">
                        <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
                            <div className='text-lg-center pb-lg-4'>
                                <h2 className='pb-2 SolutionDropDownFontFamily' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to create your digital classroom? </h2>
                                <div className='pt-4 '>
                                    <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default LMS