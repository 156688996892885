import React from "react";
import "../About-Us/AboutUs.css";

const AboutUs = () => {
  return (
    <>
      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }}>
          <div className="container p-lg-5">
            <div className="row p-lg-5 pt-5 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p
                    className=""
                    style={{
                      fontSize: ".875rem",
                      color: "#143d50",
                      letterSpacing: "1px",
                      fontWeight: "800",
                    }}
                  >
                    ABOUT US
                  </p>
                  <h2
                    className=""
                    style={{
                      fontSize: "61px",
                      color: "#287a9f",
                      fontWeight: "800",
                      lineHeight: "1.1",
                    }}
                  >
                    {" "}
                    Who Is Instructure?
                    <span
                      className=""
                      style={{
                        color: "#e4060f",
                        letterSpacing: "1px",
                        fontWeight: "800",
                      }}
                    >
                      {" "}
                      The Makers of Canvas.
                    </span>
                  </h2>
                  <p
                    className="mb-4"
                    style={{ fontSize: "1.2rem", color: "#143d50" }}
                  >
                    {" "}
                    But we’re not only the makers of a leading learning
                    management system. We're an education technology company
                    with a mission to elevate student success, amplify the power
                    of teaching, and inspire everyone to learn together.
                  </p>
                  <div className="pt-2 pb-2">
                    <button
                      className="SolutionDropDownFontFamily"
                      style={{
                        borderRadius: "20px 20px 20px 20px",
                        backgroundColor: "#E4060F",
                        border: "none",
                        color: "white",
                        padding: "12px 15px",
                      }}
                    >
                      <b
                        className="p-2"
                        style={{
                          fontSize: "1rem",
                          fontWeight: "400",
                          letterSpacing: "2px",
                        }}
                      >
                        {" "}
                        Read Our Story
                        <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i>
                      </b>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  className="w-100 "
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                  src={require("../../img/AboutMainContainerBg.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }}>
          <div className="container p-lg-5">
            <div className="d-flex justify-content-center text-center ">
              <div className="Values-text-box">
                <p
                  className=""
                  style={{
                    color: "#143d50",
                    fontWeight: "800",
                    letterSpacing: "2px",
                  }}
                >
                  HOW WE WORK
                </p>
                <h4
                  className="UbuntuFONTFAMILY"
                  style={{
                    color: "#156380",
                    fontSize: "2.5rem",
                    fontWeight: "700",
                    letterSpacing: "2px",
                  }}
                >
                  Values
                </h4>
                <p
                  className="mt-3 h1FontFamily1"
                  style={{ color: "#444", fontSize: "1.125rem" }}
                >
                  Lots of companies have "values." But ours exist outside the
                  company handbook—they're present in everything we do, from the
                  way we treat our customers and coworkers to the way we build
                  our products.
                </p>
              </div>
            </div>

            <div className="row p-lg-5 ">
              <div className="col-lg-4 col-md-6 ">
                <div className="p-lg-3">
                  <div style={{ fontSize: "3.2rem", color: "#e4060f" }}>
                    {" "}
                    <i class="fa-brands fa-reacteurope"></i>
                  </div>
                  <h6
                    className="mt-3 UbuntuFONTFAMILY"
                    style={{
                      color: "#156380",
                      fontSize: "1.975rem",
                      fontWeight: "800",
                    }}
                  >
                    Openness
                  </h6>
                  <p
                    className="mt-3 SolutionDropDownFontFamily"
                    style={{ color: "#143d50", fontSize: "1.125rem" }}
                  >
                    We’re open to new people, new ideas, and new opportunities.
                    We strive to be collaborative in our projects, transparent
                    about our intentions, and curious about how things work and
                    how to make them better. Open minds open doors.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="p-lg-3">
                  <div style={{ fontSize: "3.2rem", color: "#e4060f" }}>
                    {" "}
                    <i class="fa-brands fa-nfc-directional"></i>
                  </div>
                  <h6
                    className="mt-3 UbuntuFONTFAMILY"
                    style={{
                      color: "#156380",
                      fontSize: "1.975rem",
                      fontWeight: "800",
                    }}
                  >
                    Relationships
                  </h6>
                  <p
                    className="mt-3 SolutionDropDownFontFamily"
                    style={{ color: "#143d50", fontSize: "1.125rem" }}
                  >
                    {" "}
                    We're all about building meaningful relationships based on
                    trust, respect, and mutual success, whether they're
                    peer-peer, teacher-student, manager-employee, or
                    company-customer. People come first.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="p-lg-3">
                  <div style={{ fontSize: "3.2rem", color: "#e4060f" }}>
                    {" "}
                    <i class="fa-regular fa-comment"></i>
                  </div>

                  <h6
                    className="mt-3 UbuntuFONTFAMILY"
                    style={{
                      color: "#156380",
                      fontSize: "1.975rem",
                      fontWeight: "800",
                    }}
                  >
                    Equality
                  </h6>
                  <p
                    className="mt-3 SolutionDropDownFontFamily"
                    style={{ color: "#143d50", fontSize: "1.125rem" }}
                  >
                    {" "}
                    We aspire to offer an inclusive and welcoming culture, and
                    we believe people deserve equal access to opportunities and
                    resources. You can't have equality without equity.
                  </p>
                </div>
              </div>
            </div>

            <div className="row p-lg-4 d-flex justify-content-center ">
              <div className="col-lg-4 col-md-6">
                <div className="p-lg-3">
                  <div style={{ fontSize: "3.2rem", color: "#e4060f" }}>
                    {" "}
                    <i class="fa-brands fa-pagelines"></i>
                  </div>
                  <h6
                    className="mt-3 UbuntuFONTFAMILY"
                    style={{
                      color: "#156380",
                      fontSize: "1.975rem",
                      fontWeight: "800",
                    }}
                  >
                    Ownership
                  </h6>
                  <p
                    className="mt-3 SolutionDropDownFontFamily"
                    style={{ color: "#143d50", fontSize: "1.125rem" }}
                  >
                    {" "}
                    We're all accountable for excellence in our work and our
                    actions, and we stand behind our products and services. Say.
                    Do. Repeat.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="p-lg-3">
                  <div style={{ fontSize: "3.2rem", color: "#e4060f" }}>
                    {" "}
                    <i class="fa-solid fa-shapes"></i>
                  </div>
                  <h6
                    className="mt-3 UbuntuFONTFAMILY"
                    style={{
                      color: "#156380",
                      fontSize: "1.975rem",
                      fontWeight: "800",
                    }}
                  >
                    Simplicity
                  </h6>
                  <p
                    className="mt-3 SolutionDropDownFontFamily"
                    style={{ color: "#143d50", fontSize: "1.125rem" }}
                  >
                    {" "}
                    Our software makes lifelong learning easier. We strive for
                    clarity and ease of use across the board, from product
                    design to communication to customer experience. Simpler is
                    smarter.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }}>
          <div className="container p-lg-5">
            <div className="d-flex justify-content-center text-center ">
              <div className="Values-text-box">
                <p
                  className=""
                  style={{
                    color: "#143d50",
                    fontWeight: "800",
                    letterSpacing: "2px",
                  }}
                >
                  HOW WE WORK
                </p>
                <h4
                  className="UbuntuFONTFAMILY"
                  style={{
                    color: "#156380",
                    fontSize: "2.5rem",
                    fontWeight: "700",
                    letterSpacing: "2px",
                  }}
                >
                  Values
                </h4>
                <p
                  className="mt-3 h1FontFamily1"
                  style={{ color: "#444", fontSize: "1.125rem" }}
                >
                  Lots of companies have "values." But ours exist outside the
                  company handbook—they're present in everything we do, from the
                  way we treat our customers and coworkers to the way we build
                  our products.
                </p>
              </div>
            </div>

            <div className="row p-lg-5 ">
              <div className="col-lg-4 col-md-6 ">
                <div className="p-lg-3">
                  <img
                    src={require("../../img/AboutUs-Thanks.png")}
                    className="w-100"
                    alt="firs-About"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="p-lg-3">
                  <img
                    src={require("../../img/AboutUs-Thanks.png")}
                    className="w-100"
                    alt="firs-About"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="p-lg-3">
                  <img
                    src={require("../../img/AboutUs-Thanks.png")}
                    className="w-100"
                    alt="firs-About"
                  />
                </div>
              </div>
            </div>

            <div className="row p-lg-4 d-flex justify-content-center ">
              <div className="col-lg-4 col-md-6">
                <div className="p-lg-3">
                  <img
                    src={require("../../img/AboutUs-Thanks.png")}
                    className="w-100"
                    alt="firs-About"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="p-lg-3">
                  <img
                    src={require("../../img/AboutUs-Thanks.png")}
                    className="w-100"
                    alt="firs-About"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#176684" }}>
          <div className="container  p-lg-5 ">
            <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
              <div className="text-lg-center pb-lg-4">
                <h2
                  className="pb-2 UbuntuFONTFAMILY"
                  style={{ fontSize: "2.6rem", fontWeight: "800" }}
                >
                  {" "}
                  Curious about Instructure careers?{" "}
                </h2>
                <div className="pt-4 ">
                  <button
                    className="UbuntuFONTFAMILY"
                    style={{
                      borderRadius: "20px 20px 20px 20px",
                      backgroundImage:
                        "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)",
                      border: "none",
                      color: "#e4060f",
                      padding: "10px 15px",
                    }}
                  >
                    <b
                      className="p-2"
                      style={{ fontSize: "1rem", fontWeight: "400" }}
                    >
                      {" "}
                      LEARN MORE{" "}
                      <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i>
                    </b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
