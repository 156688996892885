import React from "react";
import "../About-Us/AboutUs.css";

const OurStory = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url(${require("../../img/Our-StoryHeroBanner.jpg")})`,
          backgroundAttachment: "local",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: " center,bottom ",
        }}
      >
        <div className="container">
          <div
            className="row p-lg-5 d-flex justify-content-center align-items-center"
            style={{
              borderRadius: "10px",
            }}
          >
            <div className="col-lg-6 paragraph-content OurStory-main ">
              <div className="m-2 title-container">
                <h1 className="UbuntuFONTFAMILY">
                  <p className="p-0 title-font-text">
                    <div>
                      <p
                        style={{
                          fontSize: ".875rem",
                          lineHeight: "1.5",
                          textTransform: "uppercase",
                          letterSpacing: ".12em",
                          fontWeight: "700",
                          marginBottom: "5px",
                          color: "#143D50",
                        }}
                      >
                        THE TALE OF INSTRUCTURE
                      </p>
                    </div>
                    Our Company Story
                  </p>

                  <div className="study-para-text">
                    <p className="fontFamilyHeader fw-normal">
                      Sparked in 2008 by two enterprising grad students,
                      Instructure is the home of Canvas LMS and the Instructure
                      Learning Platform that benefits millions of students and
                      teachers worldwide, every single day.
                    </p>
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid p-lg-5 pt-3 my-5">
          <div className="container">
            <div className="row d-flex p-lg-5 justify-content-between flex-wrap">
              <div className="text-lg-center">
                <h6
                  className="UbuntuFONTFAMILY"
                  style={{
                    color: "rgb(20, 61, 80)",
                    letterSpacing: "1px",
                    fontSize: "0.875rem",
                    fontWeight: "800",
                  }}
                >
                  THE INSTRUCTURE TIMELINE
                </h6>
                <h2
                  className="UbuntuFONTFAMILY pt-lg-3 pb-lg-4 O-mMile-text"
                >
                  Our Major Milestones
                </h2>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                <img
                  className="w-100"
                  src={require("../../img/OurStory-row-img1.png")}
                  alt="Search-support"
                  style={{
                    borderRadius: "10px",
                    height: "auto",
                  }}
                />

                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    2008
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    Instructure got its “Inc.” and progressed from a big idea to
                    a real-deal tech startup.
                  </p>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                <img
                  className="w-100"
                  src={require("../../img/OurStory-row-img2.png")}
                  alt="Search-support"
                  style={{ borderRadius: "10px", height: "auto" }}
                />
                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    2009
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    The legendary pre-Canvas product validation tour went metro
                    (Geo Metro).
                  </p>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                <img
                  className="w-100"
                  src={require("../../img/copra.png")}
                  alt="Search-support"
                  style={{ borderRadius: "10px", height: "auto" }}
                />
                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    2010
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    Josh Coates came out of techpreneur retirement to serve as
                    Instructure’s CEO. Canvas became the statewide LMS for all
                    Utah public schools, colleges, and universities.
                  </p>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                <img
                  className="w-100"
                  src={require("../../img/OurStory-row-img4.png")}
                  alt="Search-support"
                  style={{
                    borderRadius: "10px",
                    height: "auto",
                  }}
                />

                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    2011
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    Instructure formally launched the Canvas learning management
                    system, disrupting the LMS status quo for better and
                    forever.
                  </p>
                </div>
              </div>

              {/* row-2 */}

              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                <img
                  className="w-100"
                  src={require("../../img/OurStory-row-img5.png")}
                  alt="Search-support"
                  style={{
                    borderRadius: "10px",
                    height: "auto",
                  }}
                />

                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    2012
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    Canvas Network began offering open, online courses for
                    everyone, everywhere.
                  </p>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                <img
                  className="w-100"
                  src={require("../../img/OurStory-row-img6.png")}
                  alt="Search-support"
                  style={{ borderRadius: "10px", height: "auto" }}
                />
                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    2013
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    Beyonce played the Super Bowl halftime show. (And
                    Instructure moved its HQ to the Old Mill Corporate Center in
                    SLC, UT.)
                  </p>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                <img
                  className="w-100"
                  src={require("../../img/OurStory-row-img7.png")}
                  alt="Search-support"
                  style={{ borderRadius: "10px", height: "auto" }}
                />
                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    2014
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    Instructure went international with offices in London,
                    Sydney, and São Paulo.
                  </p>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                <img
                  className="w-100"
                  src={require("../../img/OurStory-row-img8.png")}
                  alt="Search-support"
                  style={{
                    borderRadius: "10px",
                    height: "auto",
                  }}
                />

                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    2015
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    Instructure went public on the NYSE (INST).
                  </p>
                </div>
              </div>

              {/* row-3 */}

              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                <img
                  className="w-100"
                  src={require("../../img/OurStory-row-img9.png")}
                  alt="Search-support"
                  style={{
                    borderRadius: "10px",
                    height: "auto",
                  }}
                />

                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    2016
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    Instructure launched Canvas Studio, a collaborative next-gen
                    video-learning platform. Instructure also added Cinnamon
                    Toast Crunch to its office cereal offerings.
                  </p>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                <img
                  className="w-100"
                  src={require("../../img/OurStory-row-img10.png")}
                  alt="Search-support"
                  style={{ borderRadius: "10px", height: "auto" }}
                />
                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    2017
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    Instructure hit the $1 billion market cap and acquired
                    Practice, the peer-feedback-driven, video-based professional
                    learning tool.
                  </p>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                <img
                  className="w-100"
                  src={require("../../img/OurStory-row-img11.png")}
                  alt="Search-support"
                  style={{ borderRadius: "10px", height: "auto" }}
                />
                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    2018
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    Instructure turned 10 and opened new markets in Latin
                    America.
                  </p>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                <img
                  className="w-100"
                  src={require("../../img/OurStory-row-img12.png")}
                  alt="Search-support"
                  style={{
                    borderRadius: "10px",
                    height: "auto",
                  }}
                />

                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    2019
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    Canvas reached more than 30 million global users. The online
                    Canvas community surpassed 500,000 users. And Instructure
                    acquired Portfolium and MasteryConnect.
                  </p>
                </div>
              </div>

              {/* row-5 */}
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                  <img
                    className="w-100"
                    src={require("../../img/OurStory-row-img13.png")}
                    alt="Search-support"
                    style={{ borderRadius: "10px", height: "auto" }}
                  />
                  <div className="text-center pt-lg-4">
                    <h2
                      className="h1FontFamily"
                      style={{
                        color: "rgb(21, 99, 128)",
                        lineHeight: "1.34",
                        fontSize: "1.8rem",
                        fontWeight: "800",
                      }}
                    >
                      {" "}
                      2020
                    </h2>
                    <p
                      className="SolutionDropDownFontFamily pt-2"
                      style={{
                        color: "rgb(20, 61, 80)",
                        lineHeight: "1.5",
                        fontSize: "1rem",
                      }}
                    >
                      Instructure was acquired by Thoma Bravo, Steve Daly joined
                      as CEO, and Instructure acquired Certica Solutions.
                    </p>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                  <img
                    className="w-100"
                    src={require("../../img/OurStory-row-img8.png")}
                    alt="Search-support"
                    style={{ borderRadius: "10px", height: "auto" }}
                  />
                  <div className="text-center pt-lg-4">
                    <h2
                      className="h1FontFamily"
                      style={{
                        color: "rgb(21, 99, 128)",
                        lineHeight: "1.34",
                        fontSize: "1.8rem",
                        fontWeight: "800",
                      }}
                    >
                      {" "}
                      2021
                    </h2>
                    <p
                      className="SolutionDropDownFontFamily pt-2"
                      style={{
                        color: "rgb(20, 61, 80)",
                        lineHeight: "1.5",
                        fontSize: "1rem",
                      }}
                    >
                      Instructure went public as INST (Again!). And welcomed
                      EesySoft to the family.
                    </p>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-lg-5 ">
                  <img
                    className="w-100"
                    src={require("../../img/OurStory-row-img15.png")}
                    alt="Search-support"
                    style={{
                      borderRadius: "10px",
                      height: "auto",
                    }}
                  />

                  <div className="text-center pt-lg-4">
                    <h2
                      className="h1FontFamily"
                      style={{
                        color: "rgb(21, 99, 128)",
                        lineHeight: "1.34",
                        fontSize: "1.8rem",
                        fontWeight: "800",
                      }}
                    >
                      {" "}
                      2022
                    </h2>
                    <p
                      className="SolutionDropDownFontFamily pt-2"
                      style={{
                        color: "rgb(20, 61, 80)",
                        lineHeight: "1.5",
                        fontSize: "1rem",
                      }}
                    >
                      Instructure acquires Concentric Sky: the Makers of Badgr,
                      now known as Canvas Credentials and acquires
                      LearnPlatform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#176684" }}>
          <div className="container  p-lg-5 mt-lg-5">
            <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
              <div className="text-lg-center pb-lg-4">
                <h2
                  className="pb-2 UbuntuFONTFAMILY ourst-ready-txt"
                >
                  Ready to explore our learning ecosystem?
                </h2>
                <div className="pt-4 ">
                  <button
                    className="UbuntuFONTFAMILY"
                    style={{
                      borderRadius: "20px 20px 20px 20px",
                      backgroundImage:
                        "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)",
                      border: "none",
                      color: "#e4060f",
                      padding: "10px 15px",
                    }}
                  >
                    <b
                      className="p-2"
                      style={{ fontSize: "1rem", fontWeight: "400" }}
                    >
                      {" "}
                      LET'S GET STARTED{" "}
                      <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i>
                    </b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurStory;
