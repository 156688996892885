import React from "react";
import "./Articles.css";

function CaseStudies() {
  return (
    <>
      <section
        style={{
          backgroundImage: `url(${require("../../img/mystudypage.jpg")})`,
          backgroundAttachment: "local",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: " center,center ",
        }}
      >
        <div className="container main-container">
          <div
            className="row p-lg-5 d-flex justify-content-around align-items-center"
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          >
            <div className="col-lg-6 p-4">
              <div>
                <p
                  style={{
                    fontSize: ".875rem",
                    lineHeight: "1.5",
                    textTransform: "uppercase",
                    letterSpacing: ".12em",
                    fontWeight: "700",
                    marginBottom: "5px",
                  }}
                >
                  Resource Center
                </p>
              </div>
              <div className="mb-3">
                <h1 className="UbuntuFONTFAMILY">
                  <p className="p-0 m-0 title-font-text" style={{}}>
                    The Instructure{" "}
                  </p>
                  <span id="font-text-red" className="title-font-text">
                    Study Hall
                  </span>
                </h1>
              </div>
              <div className="study-para-text">
                <p className="fontFamilyHeader fw-normal">
                  Explore our case studies to hear the challenges that schools
                  and insituitions overcome and results received since
                  implementing our solutions. Discover how educators use Canvas,
                  Mastery, Elevate and Impact by Instructure to take their
                  classrooms to the next level.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="row d-flex align-items-center"
                style={{ padding: "15px" }}
              >
                <div className="col-lg-6 col-5">
                  <div className="">
                    <img
                      className="research-img"
                      src={require("../../img/caseStudy-1.jpg")}
                      alt="resource-contain"
                    ></img>
                  </div>
                </div>
                <div className="col-lg-6 col-6 reserch-content">
                  <div>
                    <h6 className="research-font">Case Study</h6>
                    <p className="h1FontFamily research-text">
                      Howard County Public Schools
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="row d-flex  align-items-center"
                style={{ padding: "15px" }}
              >
                <div className="col-lg-6 col-5">
                  <div className="">
                    <img
                      className="research-img"
                      src={require("../../img/caseStudy-2.jpg")}
                      alt="resource-contain"
                    ></img>
                  </div>
                </div>
                <div className="col-lg-6 col-6 reserch-content">
                  <div>
                    <h6 className="research-font">Case Study</h6>
                    <p className="h1FontFamily research-text">
                      Professional Education at the Wharton School of the
                      University of Pennsylvania
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <nav className="container mt-4">
        <ul
          className="d-flex justify-content-start"
          style={{ listStyle: "none" }}
        >
          <li>
            <a href="/" style={{ textDecoration: "none", color: "#000" }}>
              Study Hall Main Hub &nbsp; &#x3009; &nbsp;
            </a>
          </li>

          <li>
            <a href="/" style={{ textDecoration: "none", color: "#000" }}>
              Case Studies Hub Page
            </a>
          </li>
        </ul>
      </nav>

      <section>
        <div className="layout container">
          <div className="layout-content">
            <div>
              <p
                className="text-center title-font-text"
                style={{ fontSize: "2.5rem" }}
              >
                Case Studies
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="m-3">
        <div className="container d-flex align-items-center justify-content-center p-2">
          <ul className=" blog-list">
            <li className="border-item h5 pe-5 me-3">
              Product
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="border-item h5 pe-5 me-3">
              Org Type
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="border-item h5 pe-5 me-3">
              Topic
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="border-item h5 pe-5 me-3">
              Search...
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="mb-2">
              <button className="btn-apply">
                <b className="p-2" style={{ fontSize: "1rem" }}>
                  Apply
                </b>
              </button>
            </li>
          </ul>
        </div>
      </section>

      <section>
        <div className="container ">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 d-sm-flex flex-sm-column justify-content-sm-center align-items-sm-center cards-resp-screen">
              <div className="row me-4 mt-2 g-4">
                <div className="col-lg-8 col-md-6 p-3 ">
                  <div
                    className="h-100 bg-white blog-card shadow-lg SolutionDropDownFontFamily"
                    style={{
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div className="card-img">
                      <img
                        className="w-100"
                        src={require("../../img/Csrow-1.jpg")}
                        alt="blog-article"
                      />

                      <div className="p-3 article-content">
                        <h6
                          className="ps-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(78, 111, 126)",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Case Studies
                        </h6>

                        <h3
                          className="p-0 ps-2 shutter-btn"
                          style={{
                            lineHeight: "1.1",
                            color: "rgb(20, 61, 80)",
                            fontSize: "29px",
                            fontWeight: "700",
                          }}
                        >
                          Building a Meaningful Assessment Culture…
                        </h3>

                        <div className="view-field-body">
                          <div
                            className=" ps-2"
                            style={{
                              lineHeight: "1.5",
                              color: "rgb(20, 61, 80)",
                              fontSize: "1rem",
                            }}
                          >
                            Rolling out a new assessment management system can
                            be an overwhelming process.…
                          </div>
                        </div>

                        <div className="btn-read">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none p-0 m-2"
                            style={{
                              color: "rgb(228, 6, 15)",
                              fontWeight: "600",
                            }}
                          >
                            LEARN MORE
                            <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Csrow-2.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Case Studies
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Fostering an Engaged Community at Davis…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Discover how Davis Schools has cultivated a thriving
                          and engaged community and
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Csrow-3.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Case Studies
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Howard County Public Schools
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Explore Aurora Public Schools' edtech evaluation
                          journey with…
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Csrow-4.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Case Studies
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        How Spartanburg 2 Meets Students Where They…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Discover how Spartanburg 2 meets students where they
                          are with Assessment for
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Csrow-5.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Case Studies
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Future-Ready with Canvas LMS at Virginia…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Explore how VBCPS empowered 65K students with
                          future-ready skills & Canvas LMS.
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Csrow-6.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Case Studies
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Clemson University: Creating Better Student…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Learn how Clemson University provides 24/7 educational
                          support for students.
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 col-md-6 p-3">
                  <div
                    className="h-100 bg-white blog-card shadow-lg SolutionDropDownFontFamily"
                    style={{
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div className="card-img">
                      <img
                        className="w-100"
                        src={require("../../img/Csrow-7.jpg")}
                        alt="blog-article"
                      />

                      <div className="p-3 article-content ">
                        <h6
                          className="ps-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(78, 111, 126)",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          Case Studies
                        </h6>

                        <h3
                          className="p-0 ps-2 shutter-btn"
                          style={{
                            lineHeight: "1.1",
                            color: "rgb(20, 61, 80)",
                            fontSize: "29px",
                            fontWeight: "700",
                          }}
                        >
                          Providence College: Navigating Canvas LMS…
                        </h3>

                        <div className="view-field-body">
                          <div
                            className=" ps-2"
                            style={{
                              lineHeight: "1.5",
                              color: "rgb(20, 61, 80)",
                              fontSize: "1rem",
                            }}
                          >
                            Learn how Providence College works with Tier 1
                            Services to adopt Canvas LMS and
                          </div>
                        </div>

                        <div className="btn-read">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none p-0 m-2"
                            style={{
                              color: "rgb(228, 6, 15)",
                              fontWeight: "600",
                            }}
                          >
                            LEARN MORE
                            <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Csrow-8.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Case Studies
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Canvas LMS at Northern Arizona University{" "}
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        ></div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Csrow-9.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Case Studies
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Durham Technical Community College: Creating…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Learn why Durham Tech migrated to Canvas LMS.
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          READ ON
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Csrow-10.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Case Studies
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        SSCF Archives 40K Courses with Canvas…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        ></div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Csrow-11.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Case Studies
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Ohio University: A Canvas Customer Spotlight
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Learn why Ohio University chose Canvas LMS and Impact
                          by Instructure to
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Csrow-12.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Case Studies
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Florida Gulf Coast University: Implementing…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Learn how FGCU's university-wide badging initiative
                          brings employers/industry
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Csrow-13.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Case Studies
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Oak Park and River Forest High School |…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        ></div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 col-md-6 p-3">
                  <div
                    className="h-100 bg-white blog-card shadow-lg SolutionDropDownFontFamily"
                    style={{
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div className="card-img">
                      <img
                        className="w-100"
                        src={require("../../img/Csrow-14.jpg")}
                        alt="blog-article"
                      />

                      <div className="p-3 article-content">
                        <h6
                          className="ps-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(78, 111, 126)",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Case Studies
                        </h6>

                        <h3
                          className="p-0 ps-2 shutter-btn"
                          style={{
                            lineHeight: "1.1",
                            color: "rgb(20, 61, 80)",
                            fontSize: "29px",
                            fontWeight: "700",
                          }}
                        >
                          Getting a Handle on EdTech Tools
                        </h3>

                        <div className="view-field-body">
                          <div
                            className="ps-2"
                            style={{
                              lineHeight: "1.5",
                              color: "rgb(20, 61, 80)",
                              fontSize: "1rem",
                            }}
                          >
                            How One District Overhauled Its EdTech Request
                            Process to Drive Efficiency and
                          </div>
                        </div>

                        <div className="btn-read pt-lg-3">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none p-0 m-2"
                            style={{
                              color: "rgb(228, 6, 15)",
                              fontWeight: "600",
                            }}
                          >
                            LEARN MORE{" "}
                            <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Csrow-15.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Case Studies
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Duke University
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Duke University chooses Canvas LMS to help achieve its
                          goals over the coming
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CaseStudies;
