import React from "react";
import "./Community.css";

function Community() {
  return (
    <>
      <section>
        <div className="container p-5">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-5">
              <div>
                <p
                  className=""
                  style={{
                    fontSize: ".875rem",
                    color: "#143d50",
                    letterSpacing: "1px",
                    fontWeight: "800",
                  }}
                >
                  THE ULTIMATE EDUCATOR RESOURCE
                </p>
                <h2
                  className="h1FontFamily"
                  style={{
                    fontSize: "3.5rem",
                    color: "#287a9f",
                    fontWeight: "800",
                    lineHeight: "1.1",
                  }}
                >
                  Join Our Community. <br />
                  <span
                    className=""
                    style={{
                      color: "#e4060f",
                      letterSpacing: "1px",
                      fontWeight: "800",
                    }}
                  >
                    Join the Fun.
                  </span>
                </h2>
                <p
                  className="pt-3"
                  style={{ color: "#143d50", fontSize: "1.1rem" }}
                >
                  Welcome, Instructure newbies, enthusiasts, collaborators, and
                  innovators! You’re invited to experience one of the
                  fastest-growing user communities, passionately committed to
                  sharing "how-to"s and "a-ha!"s. Community members actively
                  discuss everything Canvas, Mastery, Elevate, Impact, and
                  LearnPlatform - from basic use to advanced actions, they're a
                  regular source of smart solutions to common problems, and
                  their input directly influences the Instructure product
                  roadmap.
                </p>
                <div className="pt-4 ">
                  <button
                    className="UbuntuFONTFAMILY"
                    style={{
                      borderRadius: "20px 20px 20px 20px",
                      backgroundColor: "#E4060F",
                      border: "none",
                      color: "white",
                      padding: "10px 15px",
                    }}
                  >
                    <b
                      className="p-2"
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                        textTransform: "uppercase",
                      }}
                    >
                      {" "}
                      Explore the Community{" "}
                      <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i>
                    </b>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <img
                className="w-100 mt-3"
                style={{ borderRadius: "10px 10px 10px 10px" }}
                src={require("../../img/Community-hero-2023.jpg")}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container p-5">
          <div className="row g-5 layout-guides d-flex justify-content-center align-items-center">
            <div className="title-guides text-center">
              <h2
                className="UbuntuFONTFAMILY pt-3 pb-4"
                style={{
                  color: "rgb(21, 99, 128)",
                  letterSpacing: "1px",
                  fontSize: "2.5rem",
                  fontWeight: "800",
                  lineHeight: "1.2",
                }}
              >
                Guides, Resources, and Forums
              </h2>
              <div className="d-flex justify-content-center">
                <p style={{ fontSize: "1.125rem" }}>
                  No matter which product family you utilize, there’s a space in
                  the Community just for you! Read official guides, join
                  discussions, read or write blogs, and collaborate with
                  like-minded individuals.
                </p>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                className="canvas-img"
                src={require("../../img/Canvas-btn.png")}
              ></img>
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                className="canvas-img"
                src={require("../../img/Mastery-btn.png")}
              ></img>
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                className="canvas-img"
                src={require("../../img/elevate-btn.png")}
              ></img>
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                className="canvas-img"
                src={require("../../img/impact-btn.png")}
              ></img>
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                className="canvas-img"
                src={require("../../img/lp-btn.png")}
              ></img>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid p-lg-5 pt-3">
          <div className="container">
            <div className="row d-flex p-lg-5 justify-content-between flex-wrap">
              <div className="text-center">
                <h6
                  className="UbuntuFONTFAMILY"
                  style={{
                    color: "rgb(20, 61, 80)",
                    letterSpacing: "1px",
                    fontSize: "0.875rem",
                    fontWeight: "800",
                  }}
                >
                  WAYS TO DISCOVER
                </h6>
                <h2
                  className="UbuntuFONTFAMILY pt-3 pb-4"
                  style={{
                    color: "rgb(21, 99, 128)",
                    letterSpacing: "1px",
                    fontSize: "2.5rem",
                    fontWeight: "800",
                    lineHeight: "1.2",
                  }}
                >
                  Support. Connections. Insights.
                </h2>
              </div>

              <div className="col-lg-4 col-6 p-lg-5 ">
                <img
                  className="w-100"
                  src={require("../../img/FE2.png")}
                  alt="Search-support"
                  style={{
                    borderRadius: "10px",
                    height: "auto",
                  }}
                />

                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    Search Support Resources
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    Use the Instructure Community’s global search to find
                    official Instructure-created guides and blogs as well as
                    people, places, and member-generated content related to your
                    search terms.
                  </p>
                  <div className="">
                    <button
                      type="button"
                      className="btn btn-link text-decoration-none"
                      style={{
                        color: "rgb(228, 6, 15)",
                        fontSize: "1rem",
                        textTransform: "uppercase",
                        fontWeight: "700",
                      }}
                    >
                      Search The Community
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-6 p-lg-5">
                <img
                  className="w-100"
                  src={require("../../img/k-12.png")}
                  alt="Search-support"
                  style={{ borderRadius: "10px", height: "auto" }}
                />
                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    Connect with Your Peers
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    Start tailoring your Community experience to your specific
                    needs while learning from others. The Community is home to
                    many user-group hubs based on the roles, interests, and
                    language preferences of their members.
                  </p>
                  <div className="">
                    <button
                      type="button"
                      className="btn btn-link text-decoration-none"
                      style={{
                        color: "rgb(228, 6, 15)",
                        fontSize: "1rem",
                        textTransform: "uppercase",
                        fontWeight: "700",
                      }}
                    >
                      Explore Groups
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-6 p-lg-5">
                <img
                  className="w-100"
                  src={require("../../img/copra.png")}
                  alt="Search-support"
                  style={{ borderRadius: "10px", height: "auto" }}
                />
                <div className="text-center pt-lg-4">
                  <h2
                    className="h1FontFamily"
                    style={{
                      color: "rgb(21, 99, 128)",
                      lineHeight: "1.34",
                      fontSize: "1.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    Get Product Insights
                  </h2>
                  <p
                    className="SolutionDropDownFontFamily pt-2"
                    style={{
                      color: "rgb(20, 61, 80)",
                      lineHeight: "1.5",
                      fontSize: "1rem",
                    }}
                  >
                    Find out which new features are slated for release in coming
                    months, and learn how a feature goes from roadmap to product
                    development to platform.
                  </p>
                  <div className="">
                    <button
                      type="button"
                      className="btn btn-link text-decoration-none"
                      style={{
                        color: "rgb(228, 6, 15)",
                        fontSize: "1rem",
                        textTransform: "uppercase",
                        fontWeight: "700",
                      }}
                    >
                      Explore the Roadmap
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div
          className="container-fluid "
          style={{
            backgroundImage: `url(${require("../../img/header-ready.png")})`,
            backgroundAttachment: "local",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: " center,center ",
          }}
        >
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center p-5">
              <div
                className="p-5 text-center col-lg-9"
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px 10px 10px 10px",
                }}
              >
                <p
                  style={{
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    textTransform: "uppercase",
                    letterSpacing: "0.12em",
                    fontWeight: "700",
                    marginBottom: "5px",
                  }}
                >
                  Learn. Contribute. Discover.
                </p>
                <h2
                  className="text-center UbuntuFONTFAMILY ready-title"
                  style={{}}
                >
                  Ready to experience the Community?
                </h2>
                <div>
                  <p className="HEADERK12" style={{ textDecoration: "none" }}>
                    You’ll quickly feel right at home in the Instructure
                    Community. It’s a collaborative and positive place focused
                    on empowering people with information and opportunities for
                    connection to use Instructure software successfully.
                  </p>
                  <div className="pt-4">
                    <button
                      type="button"
                      className="UbuntuFONTFAMILY"
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "rgb(228, 6, 15)",
                        border: "none",
                        color: "white",
                        padding: "10px 15px",
                      }}
                    >
                      <b
                        className="p-2"
                        style={{
                          fontSize: "1rem",
                          fontWeight: "400",
                          textTransform: "uppercase",
                        }}
                      >
                        Join the Community
                        <i className="ms-3 fa-sharp fa-solid fa-arrow-right"></i>
                      </b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Community;
