import React from 'react'

const OnlinePrograms = () => {
    return (
        <>
            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-4">
                        <div className="row p-5 pt-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >TEACH & LEARN; VIRTUALLY</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "3.9rem", color: "#287a9f", fontWeight: "800", lineHeight: "1" }} > The tools you need to deliver <span className='' style={{ color: "#e4060f", letterSpacing: "1px", fontWeight: "800" }}> meaningful online learning at scale. </span></h2>
                                    <p className='mb-4' style={{ fontSize: "1.2rem", color: "#143d50" }}> Virtual schools want to provide their students with the same quality instruction and equitable education as students learning in person. That’s where we come in. For K-12 students who are learning fully online, Canvas LMS makes it easy to provide them with the tools they need to learn at their own pace. And educators have the tools they need to check-in individually, provide feedback, and connect—wherever and whenever learning happens. </p>
                                    <div className='pt-2 '>
                                        <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400", letterSpacing: "2px" }}> REQUEST DEMO <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/op1.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-5">
                        <div className="row p-lg-4  d-flex justify-content-around ">

                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/op2.png")} alt='' />
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > LEARN FROM ANYWHERE </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > A digital classroom that goes where you go. </h2>
                                    <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > With the right tools, the classroom is a concept, not a place. For virtual K-12 schools, Canvas by Instructure provides a digital foundation for teaching and learning with an industry-leading LMS, a modern course catalog system for easy registration, and an interactive video education platform. Together, these online learning tools ensure all students, teachers, and parents have equitable access to interactive and engaging content for fully online teaching and learning—whether they’re on desktops, laptops, or smart mobile devices. And, with industry-leading mobile applications and full-featured responsive design, you can rest assured that you can reach every learner. </p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Learn from anywhere with Canvas LMS </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Create engaging learning experiences with <span className='ps-4 ms-2'>Canvas Studio</span> </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Register for self-paced courses with Canvas <span className='ps-4 ms-2'>Catalog</span> </span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-4 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > ASSESS FOR GROWTH </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Actionable data for personalized feedback. </h2>
                                    <p className='pt-2 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Mastery by Instructure provides the same flexible assessment model for fully online teaching and learning. Once teachers have a clear understanding of what their students know, they can evaluate their instruction, make timely decisions that positively impact learning, and identify resources for remediation efforts to ensure students can study, practice, and achieve mastery at their own pace with online resources. </p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Know what students know, standard by <span className='ps-4 ms-2'> standard </span> </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Deliver formative and benchmark assessments <span className='ps-4 ms-2'> online </span> </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Use student assessment data to collaborate <span className='ps-4 ms-2'> and improve teaching practices </span>  </span></p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/op3.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-lg-5 ">
                            <div className="col-lg-4 col-md-12">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE ALL PRODUCTS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Discover the Instructure Learning Platform </h2>
                                    <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > The Instructure Learning Platform delivers what you need to enrich, support, and connect every aspect of teaching and learning. </p>
                                    <div className='pt-4 pb-5'>
                                        <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="row">
                                    <div className="col-lg">
                                        <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Canvas LMS </h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Course materials. Communication tools. Participation and grades. Data and insights. All part of Canvas LMS. Ready to support learning anywhere. </p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg pt-5">
                                            <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                                <div className='p-3'>
                                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms2.png")} alt='' />
                                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Elevate Analytics</h6>
                                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > The analytics solution that combines data from multiple sources and makes it easy for districts to put the right information into the hands of educators and administrators.</p>
                                                    <div className=''>
                                                        <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#0097d3", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                                <div className="row">
                                    <div className="col-lg ">
                                        <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Mastery Connect</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Drive student outcomes with actionable, standards-based insights that improve teaching and learning, leading to even more helpful insights and even better teaching and learning.</p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg ">
                                            <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                                <div className='p-3'>
                                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms3.png")} alt='' />
                                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Impact by Instructure</h6>
                                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Improve technology adoption, support teachers and students in their tech use, and evaluate the impact of educational technology on student engagement.</p>
                                                    <div className=''>
                                                        <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#f76400", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5 ">
                        <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
                        <div className="row d-flex justify-content-center p-5">
                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px" }} src={require("../../img/ass5.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Blog Articles</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }} >Epic Charter School: Strengthening Virtual Instruction With MasteryConnect</h4>
                                        <div className='pt-lg-4'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px" }} src={require("../../img/ass6.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Blog Articles</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }}>The State of Assessment in K-12 Education: Study & Trends</h4>
                                        <div className='pt-lg-4'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px" }} src={require("../../img/ass7.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Case Studies</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }}>A District Leader's Guide to Using Assessment Data</h4>
                                        <div className='pt-lg-5 mt-lg-3'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
                    <div className="container  p-lg-5 ">
                        <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
                            <div className='text-lg-center pb-lg-4'>
                                <h2 className='pb-2 SolutionDropDownFontFamily' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to explore our learning ecosystem? </h2>
                                <div className='pt-4 '>
                                    <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default OnlinePrograms