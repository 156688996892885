import React from "react";
import "./Articles.css";

function Articles() {
  return (
    <>
      <section
        style={{
          backgroundImage: `url(${require("../../img/mystudypage.jpg")})`,
          backgroundAttachment: "local",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: " center,center ",
        }}
      >
        <div className="container main-container">
          <div
            className="row p-lg-5 d-flex justify-content-around align-items-center"
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          >
            <div className="col-lg-6 p-4">
              <div>
                <p
                  style={{
                    fontSize: ".875rem",
                    lineHeight: "1.5",
                    textTransform: "uppercase",
                    letterSpacing: ".12em",
                    fontWeight: "700",
                    marginBottom: "5px",
                  }}
                >
                  Resource Center
                </p>
              </div>
              <div className="mb-3">
                <h1 className="UbuntuFONTFAMILY">
                  <p className="p-0 m-0 title-font-text" style={{}}>
                    The Instructure{" "}
                  </p>
                  <span id="font-text-red" className="title-font-text">
                    Study Hall
                  </span>
                </h1>
              </div>
              <div className="study-para-text">
                <p className="fontFamilyHeader fw-normal">
                  Read articles on the pulse of teaching and learning written by
                  and for today's educators. From K12 to Higher Education to
                  professional development, find topics on all things education.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="row d-flex align-items-center"
                style={{ padding: "15px" }}
              >
                <div className="col-lg-6 col-5">
                  <div className="">
                    <img
                      className="research-img"
                      src={require("../../img/Article-img1.jpg")}
                      alt="resource-contain"
                    ></img>
                  </div>
                </div>
                <div className="col-lg-6 col-6 reserch-content">
                  <div>
                    <h6 className="research-font">Article</h6>
                    <p className="h1FontFamily research-text">
                      Navigating Generative AI: Key Insights for This School
                      Year
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="row d-flex  align-items-center"
                style={{ padding: "15px" }}
              >
                <div className="col-lg-6 col-5">
                  <div className="">
                    <img
                      className="research-img"
                      src={require("../../img/article-img2.jpg")}
                      alt="resource-contain"
                    ></img>
                  </div>
                </div>
                <div className="col-lg-6 col-6 reserch-content">
                  <div>
                    <h6 className="research-font">Article</h6>
                    <p className="h1FontFamily research-text">
                      13 LMS Features That Benefit Student Learning
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <nav className="container mt-4">
        <ul
          className="d-flex justify-content-start"
          style={{ listStyle: "none" }}
        >
          <li>
            <a href="/" style={{ textDecoration: "none", color: "#000" }}>
              Study Hall Main Hub &nbsp; &#x3009; &nbsp;
            </a>
          </li>

          <li>
            <a href="/" style={{ textDecoration: "none", color: "#000" }}>
              Articles Hub Page
            </a>
          </li>
        </ul>
      </nav>

      <section>
        <div className="layout container">
          <div className="layout-content">
            <div>
              <p
                className="text-center title-font-text"
                style={{ fontSize: "2.5rem" }}
              >
                Blog Articles
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="m-3">
        <div className="container d-flex align-items-center justify-content-center p-2">
          <ul className=" blog-list">
            <li className="border-item h5 pe-5 me-3">
              Product
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="border-item h5 pe-5 me-3">
              Org Type
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="border-item h5 pe-5 me-3">
              Topic
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="border-item h5 pe-5 me-3">
              Search...
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="mb-2">
              <button className="btn-apply">
                <b className="p-2" style={{ fontSize: "1rem" }}>
                  Apply
                </b>
              </button>
            </li>
          </ul>
        </div>
      </section>

      <section>
        <div className="container ">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 d-sm-flex flex-sm-column justify-content-sm-center align-items-sm-center cards-resp-screen">
              <div className="row mt-2 g-4">
                <div className="col-lg-8 col-md-6 p-lg-3 ">
                  <div
                    className="h-100 bg-white blog-card shadow-lg SolutionDropDownFontFamily"
                    style={{
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div className="card-img">
                      <img
                        className="w-100"
                        src={require("../../img/blueprints.jpg")}
                        alt="blog-article"
                      />

                      <div className="p-3 article-content">
                        <h6
                          className="ps-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(78, 111, 126)",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Blog Articles
                        </h6>

                        <h3
                          className="p-0 ps-2 shutter-btn"
                          style={{
                            lineHeight: "1.1",
                            color: "rgb(20, 61, 80)",
                            fontSize: "29px",
                            fontWeight: "700",
                          }}
                        >
                          Blueprints for Better Blending: Virtual…
                        </h3>

                        <div className="view-field-body">
                          <div
                            className=" ps-2"
                            style={{
                              lineHeight: "1.5",
                              color: "rgb(20, 61, 80)",
                              fontSize: "1rem",
                            }}
                          >
                            Learn everything you need to know for VVA & VSTE's
                            Blended Learning…
                          </div>
                        </div>

                        <div className="btn-read">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none p-0 m-2"
                            style={{
                              color: "rgb(228, 6, 15)",
                              fontWeight: "600",
                            }}
                          >
                            READ ON
                            <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-lg-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/blog-item2.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Blog Articles
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Blueprints for Better Blending: Virtual…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Learn everything you need to know for VVA & VSTE's
                          Blended Learning…
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          READ ON
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-lg-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/blogImage-3.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Blog Articles
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Crafting the Right Edtech Evaluation System…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Explore Aurora Public Schools' edtech evaluation
                          journey with…
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          READ ON
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-lg-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/blogImage-4.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Blog Articles
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Leveraging Canvas to Support Live Learning
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          UAGC has implemented a centralized Live Learning model
                          that offers benefits not…
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          READ ON
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-lg-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/blogImage-5.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Blog Articles
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Understanding Student Engagement: 6…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Student engagement is the level of attention and
                          motivation students have in…
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          READ ON
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-lg-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/blogImage-6.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Blog Articles
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Why You Need to Archive Your Canvas LMS Data
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          This is a guest post by Instructure partner K-16
                          Solutions …
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          READ ON
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 col-md-6 p-lg-3">
                  <div
                    className="h-100 bg-white blog-card shadow-lg SolutionDropDownFontFamily"
                    style={{
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div className="card-img">
                      <img
                        className="w-100"
                        src={require("../../img/blogImage-7.jpg")}
                        alt="blog-article"
                      />

                      <div className="p-3 article-content ">
                        <h6
                          className="ps-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(78, 111, 126)",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Blog Articles
                        </h6>

                        <h3
                          className="p-0 ps-2 shutter-btn"
                          style={{
                            lineHeight: "1.1",
                            color: "rgb(20, 61, 80)",
                            fontSize: "29px",
                            fontWeight: "700",
                          }}
                        >
                          3 Ways to Prioritize Accessibility in 2024
                        </h3>

                        <div className="view-field-body">
                          <div
                            className=" ps-2"
                            style={{
                              lineHeight: "1.5",
                              color: "rgb(20, 61, 80)",
                              fontSize: "1rem",
                            }}
                          >
                            In the pursuit of equity and access for all
                            learners, digital accessibility…
                          </div>
                        </div>

                        <div className="btn-read">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none p-0 m-2"
                            style={{
                              color: "rgb(228, 6, 15)",
                              fontWeight: "600",
                            }}
                          >
                            READ ON
                            <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-lg-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/blogImage-8.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Blog Articles
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Navigating Mental Health in Higher Education
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Explore the crucial role of educational institutions
                          in supporting mental…
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          READ ON
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-lg-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/blogImage-9.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Blog Articles
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        What Is Human-Centered Design?
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Human-centered design focuses on students' needs,
                          behaviors, and…
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          READ ON
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-lg-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/blogImage-10.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Blog Articles
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Embracing Change and Canvas Mastery with the…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          In a recent episode of the InstructureCast podcast, we
                          had the pleasure of…
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          READ ON
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-lg-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/blogImage-11.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Blog Articles
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Visual Learning: Effective Strategies and…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          The visual learning method involves the use of images,
                          videos, and other visual…
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          READ ON
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-lg-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/blogImage-12.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Blog Articles
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Increasing Student Engagement with Canvas…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Use Canvas Studio to boost student engagement and
                          participation.
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          READ ON
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-lg-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/blogImage-13.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Blog Articles
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Global Higher Education Study Identifies 6…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Take a look at six key trends identified as most
                          important to learners and…
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          READ ON
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 col-md-6 p-lg-3">
                  <div
                    className="h-100 bg-white blog-card shadow-lg SolutionDropDownFontFamily"
                    style={{
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div className="card-img">
                      <img
                        className="w-100"
                        src={require("../../img/blogImage-14.jpg")}
                        alt="blog-article"
                      />

                      <div className="p-3 article-content">
                        <h6
                          className="ps-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(78, 111, 126)",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Blog Articles
                        </h6>

                        <h3
                          className="p-0 ps-2 shutter-btn"
                          style={{
                            lineHeight: "1.1",
                            color: "rgb(20, 61, 80)",
                            fontSize: "29px",
                            fontWeight: "700",
                          }}
                        >
                          Building a Meaningful Assessment Culture…
                        </h3>

                        <div className="view-field-body">
                          <div
                            className="ps-2"
                            style={{
                              lineHeight: "1.5",
                              color: "rgb(20, 61, 80)",
                              fontSize: "1rem",
                            }}
                          >
                            Rolling out a new assessment management system can
                            be an overwhelming process.…
                          </div>
                        </div>

                        <div className="btn-read pt-lg-3">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none p-0 m-2"
                            style={{
                              color: "rgb(228, 6, 15)",
                              fontWeight: "600",
                            }}
                          >
                            READ ON
                            <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-lg-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/blogImage-15.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Blog Articles
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Equity in Education
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Equity in education aims to provide tailored support
                          for each student's…
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          READ ON
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Articles;
