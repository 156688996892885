import React, { useState } from 'react'

const MasteryViewPredictiveAssessments = () => {

    const [change1, setChange1] = useState(false);
    const [change2, setChange2] = useState(false);
    const [change3, setChange3] = useState(false);
    const [change4, setChange4] = useState(false);
    const [change5, setChange5] = useState(false);

    const clickMe1 = () => {
        setChange1(!change1)
        setChange2(false)
        setChange3(false)
        setChange4(false)
        setChange5(false)
    }
    const clickMe2 = () => {
        setChange1(false)
        setChange2(!change2)
        setChange3(false)
        setChange4(false)
        setChange5(false)
    }
    const clickMe3 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(!change3)
        setChange4(false)
        setChange5(false)
    }

    const clickMe4 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(false)
        setChange4(!change4)
        setChange5(false)
    }

    const clickMe5 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(false)
        setChange4(false)
        setChange5(!change5)
    }

    return (

        <>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#24a159" }} >
                    <div className="container p-1">
                        <div className="row p-2 text-white d-flex justify-content-between">
                            <div className='col-lg-2'>
                                <h5 className='ps-lg-3' style={{ fontSize: "1.125rem", fontWeight: "600", letterSpacing: "2px" }} > MASTERY </h5>
                            </div>
                            <div className="col-lg-4">
                                <div className='ps-lg-3'>
                                    <h5 className='' style={{ fontSize: "1.125rem", fontWeight: "600", letterSpacing: "2px" }} > Mastery View Predictive Assessments </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container p-5">
                    <div className="row p-4 pt-5 d-flex justify-content-around ">
                        <div className="col-lg-5">
                            <div>
                                <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > PREDICTIVE BENCHMARK ASSESSMENTS </p>
                                <h2 className='h1FontFamily' style={{ fontSize: "3.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Assessment. To the Power of <span className='' style={{ color: "#24a159", letterSpacing: "1px", fontWeight: "800" }}> Predicting Performance. </span></h2>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <p className='mb-3' style={{ fontSize: "1.2rem", color: "#143d50" }}> The Mastery View Assessment offerings include predictive benchmarks to gauge students’ progress at key points during the school year, a tightly-aligned item bank that reflects state assessment rigor, and an ACT practice test to ensure students are prepared for the next step in their academic career. They provide everything you need to gauge standards mastery and predict summative outcomes. Available in select states for the 2023-24 school year. </p>
                            <div className='pt-2 '>
                                <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S CONNECT <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container mt-5 mb-5 p-4">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-5 p-0">
                                <img className='imgRESOPNSIVE' src={require("../../img/mvpa1.png")} alt='' />
                                <div className='ps-lg-5 pt-5 ms-lg-2'>
                                    <p className='fontFamilyHeader' style={{ fontSize: "1.55rem", color: '#287a9f', fontWeight: "800", lineHeight: "1.3" }} > " Using the Mastery View Predictive Assessments, we have seen a minimum of a 10% increase in the number of students scoring proficient and advanced on the state assessment in just one year." </p>
                                    <h6 className='fontFamilyHeader pt-2' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "800" }} > — Christopher Williams, Ph.D. </h6>
                                    <h6 className='fontFamilyHeader' style={{ fontSize: "1.13rem", color: '#287a9f', fontWeight: "400" }}> Assistant Superintendent, Curriculum and Instruction Ocean Spring School District, Ocean Springs, MS </h6>
                                </div>
                            </div>
                            <div className="col-lg-6  shadow-lg PLUS-LARGE"  >
                                <h2 className='ps-5 ms-4 pt-4 h1FontFamily mt-5' style={{ color: "#156380", fontSize: "2.5rem", lineHeight: "1.2" }} > Measure and Accelerate Student Learning with Effective Assessment </h2>
                                <div className="table p-4 d-flex justify-content-center">
                                    <div className='w-100 ' >
                                        <div className='pt-1'  >
                                            <div className='w-100 p-0'  >
                                                <button className='text-start bg-body  w-100  p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe1() }}> <h3 className='fs-5'> <b>{change1 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span>  <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className=' ps-3' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >  Actionable Insights </span></b></h3></button>
                                                <div className=''>
                                                    {
                                                        change1 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} >Identify what students know—and what they can do—with meaningful data needed to inform instruction, target interventions, and personalize the learning experience. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe2() }}><h3 className='fs-5'> <b> {change2 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-3' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Mirror State Assessments </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change2 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Modeled after each state’s summative assessment blueprint, Mastery View Predictive Assessments are developed with the rigor, standard alignment, and reliability of state tests. Available in Alabama, California, Indiana, Kentucky, Louisiana, Missouri, Mississippi, North Carolina, South Carolina, and Tennessee. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start  w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe3() }}> <h3 className='fs-5'><b> {change3 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i></span> : <span> <i className="fa-solid fa-plus " style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className='ps-3' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Tailored to Your Pacing </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change3 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Assessments can match your district's specific scope and sequence and reflect the standards students have learned so strengths and learning gaps can be easily identified. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe4() }}><h3 className='fs-5'> <b> {change4 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-3' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Proven Impact </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change4 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Backed by research and district response, our unique approach to test construction results in proven correlations between assessment scores and students’ achievement on state tests. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe5() }}><h3 className='fs-5'> <b> {change5 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-3' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Powered by Mastery Connect </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change5 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Mastery Connect makes it easy to administer assessments, analyze results, compare benchmark data to formative classroom data by standard, and adjust data-driven instructional strategies to drive learning forward. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row pt-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > DATA THAT INFORMS INSTRUCTION </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Reports that help you understand and actually use assessment data. </h2>
                                    <p className='pt-3 pb-3' style={{ color: "#143d50", fontSize: "1.2rem", }} > Data is only useful if you put it into action. Mastery View Predictive Assessment reports give teachers the context to make impactful instructional decisions in response to reliable assessment data. </p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Scaled scores and reports within 72 hours of administration</span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Student, grade, school, or district level data</span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Actionable and easy-to-use</span></p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/mvpa2.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section >
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-4">
                        <div className="row p-4  d-flex justify-content-around ">

                            <div className="col-lg-5">
                                <img className='w-100' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/mvpa3.png")} alt='' />
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >SUMMATIVE-QUALITY ASSESSMENT ITEMS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Practice for benchmarks with rigorous classroom assessments. </h2>
                                    <p className='pt-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Further prepare for state tests with the Mastery Item Bank Supplemental, an item bank that includes standards-based items that reflect the rigor and complexity of high-stakes tests. </p>
                                </div>
                                <div>
                                    <h2 className='pt-2 SolutionDropDownFontFamily' style={{ fontSize: "1.3rem", color: "#287a9f", fontWeight: "700", lineHeight: "1.5" }} > " The Mastery View Predictive Assessments have helped to align the continuous improvement work for our district in a way that allows us to maintain forward movement during the most difficult times." </h2>
                                    <h6 className='fontFamilyHeader pt-4' style={{ fontSize: "1.125rem", color: '#287a9f', fontWeight: "600" }} > — Kelly Williams, Ed.D. </h6>
                                    <h6 className='fontFamilyHeader' style={{ fontSize: "1.125rem", color: '#287a9f', fontWeight: "400" }}> Executive Director of Teaching and Learning Oak Ridge Schools, Oak Ridge, TN </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row pt-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > ACT PREP </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Prepare students for success on the ACT. </h2>
                                    <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.1rem", }} > Whether students take the ACT® as a summative test or as a college entrance exam, a proven practice test can provide insights into strengths and learning gaps. Mastery View College Prep delivers. </p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Predictive of performance, just like the Mastery <p className='ps-lg-4 ms-lg-2'>View Predictive Assessments</p> </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Mirrors the content, time, and length of the ACT </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} >More data points and faster turnaround than <p className='ps-lg-4 ms-lg-2'> other practice tests </p> </span></p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/mvpa4.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#F2F8FA" }} >
                    <div className="container p-lg-5">

                        <div className='d-flex justify-content-center text-center'>
                            <div className='' style={{ maxWidth: "40%" }} >
                                <p className='' style={{ color: "#143d50", fontWeight: "800", letterSpacing: "2px" }} >MORE FEATURES</p>
                                <h4 className='SolutionDropDownFontFamily pt-2' style={{ color: "#156380", fontSize: "2.4rem", fontWeight: "800", letterSpacing: "2px" }} > More to love about Mastery View Predictive Assessments: </h4>
                            </div>
                        </div>

                        <div className="row p-lg-5 d-flex justify-content-center">
                            <div className="col-lg-4 col-md-6 ">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "2.1rem", fontWeight: "800" }} > Flexible Administration </h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > There is no one right way to assess students. Assessments can be delivered in the classroom or at home—either online, paper-and-pencil, or both—to accommodate your district’s unique needs. </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Expertly Developed and Designed </h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Developed by educators and content experts who continuously analyze state-released test blueprints, Mastery View Predictive Assessments are designed to guarantee rigor and comprehensive standards coverage. </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > The Right Level of Rigor </h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Assessments provide rigor through text complexity, vocabulary, balance of DOK, and plausible answer choices—and require students to apply concepts and skills, make judgments, and justify thinking. </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > A Tool for Reteaching </h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > New assessments are designed with new items each year, so teachers can maintain access to completed assessments after receiving their students’ results. </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Less Testing </h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Spend less time testing and more time teaching. Reliable assessments provide meaningful data, resulting in an efficient and effective assessment program. </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Inform PLCs </h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Rely on valuable data to fuel discussions about student progress. Make timely decisions about instructional practices and teaching strategies based on assessment results. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-lg-5 ">
                            <div className="col-lg-4 col-md-12">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE ALL PRODUCTS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Explore the Entire Mastery Product Family </h2>
                                    <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > Drive learning outcomes and standards mastery with high quality assessments and actionable insights that inform and improve teaching and learning. </p>
                                    <div className='pt-4 pb-5'>
                                        <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="row">
                                    <div className="col-lg">
                                        <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2rem", fontWeight: "800" }} > Mastery <br /> Connect </h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.45rem", }} > Drive student outcomes with actionable, standards-based insights that improve teaching and learning, leading to even more helpful insights and even better teaching and learning. </p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg pt-5">
                                            <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                                <div className='p-3'>
                                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Mastery View Predictive Assessments</h6>
                                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Benchmarks that mirror state assessment blueprints, reflect the standards that students have learned, and provide educators with meaningful insights into student progress.</p>
                                                    <div className=''>
                                                        <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                                <div className="row">
                                    <div className="col-lg ">
                                        <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Mastery View Formative Assessments</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > A collection of expert-developed, tightly-aligned formative assessments designed with high-quality items that evaluate student mastery.</p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5 ">
                        <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
                        <div className="row d-flex justify-content-center p-5">

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/mvpa5.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Ebooks & Buyer’s Guides</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }} > Choosing Effective Benchmark Assessments </h4>
                                        <div className='pt-lg-5'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/mvpa6.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Blog Articles</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }}>Mastery View Predictive Assessments Earn Tier 1 Rating by LDOE</h4>
                                        <div className='pt-lg-3'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/mvpa7.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Blog Articles</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }} > An Intro to MasteryConnect, Instructure's AMS </h4>
                                        <div className='pt-lg-5 '>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
                    <div className="container  p-lg-5 ">
                        <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
                            <div className='text-lg-center pb-lg-4'>
                                <h2 className='pb-2 UbuntuFONTFAMILY' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to measure mastery and predict <br /> performance? </h2>
                                <div className='pt-4 '>
                                    <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>

    )
}

export default MasteryViewPredictiveAssessments