import React, { useState } from 'react'

const CanvasCredentials = () => {

  const [change1, setChange1] = useState(false);
  const [change2, setChange2] = useState(false);
  const [change3, setChange3] = useState(false);
  const [change4, setChange4] = useState(false);
  const [change5, setChange5] = useState(false);

  const clickMe1 = () => {
    setChange1(!change1)
    setChange2(false)
    setChange3(false)
    setChange4(false)
    setChange5(false)
  }
  const clickMe2 = () => {
    setChange1(false)
    setChange2(!change2)
    setChange3(false)
    setChange4(false)
    setChange5(false)
  }
  const clickMe3 = () => {
    setChange1(false)
    setChange2(false)
    setChange3(!change3)
    setChange4(false)
    setChange5(false)
  }

  const clickMe4 = () => {
    setChange1(false)
    setChange2(false)
    setChange3(false)
    setChange4(!change4)
    setChange5(false)
  }

  const clickMe5 = () => {
    setChange1(false)
    setChange2(false)
    setChange3(false)
    setChange4(false)
    setChange5(!change5)
  }

  return (

    <>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#287a9f" }} >
          <div className="container p-2">
            <div className="row p-2 text-white d-flex justify-content-between">
              <div className='col-lg-2'>
                <h5 className='ps-lg-3' style={{ fontSize: "1.125rem", fontWeight: "600", letterSpacing: "2px" }} >CANVAS</h5>
              </div>
              <div className="col-lg-4">
                <div className='ps-lg-5 ms-lg-5'>
                  <h5 className='ps-lg-5 ms-lg-5' style={{ fontSize: "1.125rem", fontWeight: "600", letterSpacing: "2px" }} > Canvas Credentials</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container p-5">
          <div className="row pt-5 d-flex justify-content-center ">
            <div className="col-lg-5">
              <div>
                <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >CANVAS CREDENTIALS</p>
                <h2 className='h1FontFamily' style={{ fontSize: "3.6rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Digital Badging <span className='' style={{ color: "#e4060f", letterSpacing: "1px", fontWeight: "800" }}> for K-12 Professional Development </span></h2>
              </div>
            </div>
            <div className="col-lg-5">
              <p className='mb-4' style={{ fontSize: "1.2rem", color: "#143d50" }}> Ensure your educators have the skills and credentials needed to produce the best outcomes for students in your school or district. Start tracking skills and certifications, supporting professional development, and evaluating progress today. </p>
              <div className='pt-4 '>
                <button className='SolutionDropDownFontFamily' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET’S CONNECT  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container mt-5 mb-5 p-4">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-5 p-0">
                <img className='imgRESOPNSIVE' src={require("../../img/cre.png")} alt='' />

              </div>
              <div className="col-lg-6  shadow-lg PLUS"  >
                <h2 className='ps-5 ms-4  h1FontFamily mt-5' style={{ color: "#156380", fontSize: "2.5rem", lineHeight: "1.2" }} > Recognize and reward professional learning. </h2>
                <div className="table p-4 d-flex justify-content-center">
                  <div className='w-100 ' >
                    <div className='pt-4 '  >
                      <div className='w-100 p-0'  >
                        <button className='text-start bg-body  w-100  p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe1() }}> <h3 className='fs-5'> <b>{change1 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span>  <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className=' ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >  Open the Lines of Communication </span></b></h3></button>
                        <div className=''>
                          {
                            change1 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Digital credentials assist in translating learning outcomes into validated skills that both educators and their employers can easily comprehend. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe2() }}><h3 className='fs-5'> <b> {change2 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Recognize and Verify Achievement </span> </b></h3></button>
                        <div >
                          {
                            change2 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Empower teachers and staff to provide evidence of the transferable skills that are needed in an ever-evolving professional education landscape. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start  w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe3() }}> <h3 className='fs-5'><b> {change3 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i></span> : <span> <i className="fa-solid fa-plus " style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Upskill, Reskill, and Hire the Best Fit </span> </b></h3></button>
                        <div >
                          {
                            change3 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Digital badging opens up new ways to articulate competencies and accomplishments, verify skills, and identify opportunities within a school district for both existing and new educators and staff.  </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe4() }}><h3 className='fs-5'> <b> {change4 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Drive Your District’s Mission </span> </b></h3></button>
                        <div >
                          {
                            change4 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Discover, understand, and report on badging metrics and employee professional development achievements. Then architect pathways that illuminate the skills and accomplishments educators need to be lifelong learners. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe5() }}><h3 className='fs-5'> <b> {change5 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Deliver Transparency </span> </b></h3></button>
                        <div >
                          {
                            change5 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Badges provide teachers and staff agency over their training and career path by clearly communicating the institution-valued skills they previously demonstrated or have yet to earn. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-4">
            <div className="row p-5 pt-5 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > THE TOOLS TO TAKE OWNERSHIP </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Educator-owned professional development </h2>
                  <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.1rem", }} > Teachers and staff can share their Comprehensive Learner Record (LER)—a digital space where all learning and skills are brought together into one easily accessible, secure, and verifiable source of truth—no matter where or when they earned a skill or competency.</p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} > Onboard, train, and level up </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} > Build learning journeys with pathways </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} > Gamify accomplishments </span></p>
                </div>
              </div>
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/cre1.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section >
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row p-5 pt-5 d-flex justify-content-between ">

              <div className="col-lg-6">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/cre2.png")} alt='' />
              </div>

              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > CATALOG, SIGNAL, AND VERIFY </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Showcase achievements and skills in real-time. </h2>
                  <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.1rem", }} > Easily discover the micro-credentials your teachers and staff earn—identifying institutional skills recognized and the number of employees learning them. Determine which professional development opportunities are maximized with digital badging and understand how these Open Badges are shared on social mediums such as LinkedIn and Facebook. </p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} > Communicate institution-valued skills </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} > Track badges earned in Canvas LMS and elsewhere </span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-4">
            <div className="row p-5 pt-5 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > ALIGN TO YOUR MISSION </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Map the way to engagement and success. </h2>
                  <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.1rem", }} > Digital badging can be complex. Canvas Credentials makes it easy. Automate milestone badges, track third-party badges, and apply group and individual progress tracking while assigning pathways to groups and individuals. With self-paced professional learning journeys, your educators can take the lead, track their progress, and look ahead to plan next steps. </p>
                </div>
              </div>
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/cre3.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#F2F8FA" }} >
          <div className="container p-lg-5">

            <div className='d-flex justify-content-center text-center'>
              <div className='' style={{ maxWidth: "40%" }} >
                <p className='' style={{ color: "#143d50", fontWeight: "800", letterSpacing: "2px" }} >MORE FEATURES</p>
                <h4 className='SolutionDropDownFontFamily pt-2' style={{ color: "#156380", fontSize: "2.4rem", fontWeight: "800", letterSpacing: "2px" }} > Canvas Credentials — A digital credentialing solution for K-12 education </h4>
              </div>
            </div>

            <div className="row p-lg-5 d-flex justify-content-center">
              <div className="col-lg-4 col-md-6 ">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Skills Alignment </h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Unlock the potential of skills-based education and hiring. </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Engagement & Recognition </h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Motivate with achievements that are easy to recognize and share. </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Reporting & Analytics </h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Manage, track, analyze, and report in real time. </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 ">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Learner Record </h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Make it easy for learners to visualize, understand, and share progress. </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Customization </h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Create a fully branded end-to-end experience. </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Trust & Security </h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Offer confidence through data privacy, security, and ownership. </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row p-lg-5 ">
              <div className="col-lg-4 col-md-12">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE ALL PRODUCTS</p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Explore the Entire Canvas Product Family </h2>
                  <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > Simplify teaching and learning activities, organize coursework and keep teachers, students, and families connected and communicating. Anytime, anywhere.</p>
                  <div className='pt-4 pb-5'>
                    <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="row">
                  <div className="col-lg">
                    <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas LMS</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > It’s the next-generation video learning solution that turns one‑way, passive video into inclusive, engaging, and productive classroom discussions.</p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg pt-5">
                      <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                        <div className='p-3'>
                          <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                          <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Credentials</h6>
                          <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Canvas Credentials empowers educators with more than just a transcript. With verifiable digital credentials, they have a comprehensive learner record to demonstrate progress towards their goals.</p>
                          <div className=''>
                            <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg pt-5">
                      <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                        <div className='p-3'>
                          <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                          <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Impact by Instructure</h6>
                          <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Improve technology adoption, support teachers and students in their tech use, and evaluate the impact of educational technology on student engagement.</p>
                          <div className=''>
                            <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                <div className="row">
                  <div className="col-lg ">
                    <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Catalog</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Catalog is a simple, modern course catalog registration system and branded marketplace for all of your district’s professional development and community resource offerings.</p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg ">
                      <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                        <div className='p-3'>
                          <img className=' mt-2' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                          <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Student Pathway</h6>
                          <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Improve technology adoption, support teachers and students in their tech use, and evaluate the impact of educational technology on student engagement.</p>
                          <div className=''>
                            <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
          <div className="container  p-lg-5 ">
            <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
              <div className='text-lg-center  pb-lg-4'>
                <h2 className='pb-2 SolutionDropDownFontFamily' style={{ fontSize: '2.6rem', fontWeight: '800' }}  > Ready to take Professional Development <br /> to the next level? </h2>
                <div className='pt-4 '>
                  <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>

  )
}

export default CanvasCredentials