import React, { useState } from 'react'

const CanvasStudio = () => {

    const [change1, setChange1] = useState(false);
    const [change2, setChange2] = useState(false);
    const [change3, setChange3] = useState(false);
    const [change4, setChange4] = useState(false);
    const [change5, setChange5] = useState(false);


    const clickMe1 = () => {
        setChange1(!change1)
        setChange2(false)
        setChange3(false)
        setChange4(false)
        setChange5(false)
    }
    const clickMe2 = () => {
        setChange1(false)
        setChange2(!change2)
        setChange3(false)
        setChange4(false)
        setChange5(false)
    }
    const clickMe3 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(!change3)
        setChange4(false)
        setChange5(false)
    }

    const clickMe4 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(false)
        setChange4(!change4)
        setChange5(false)
    }

    const clickMe5 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(false)
        setChange4(false)
        setChange5(!change5)
    }



    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#287a9f" }} >
                    <div className="container p-2">
                        <div className="row p-2 text-white d-flex justify-content-between">
                            <div className='col-lg-2'>
                                <h5 className='ps-lg-3' style={{ fontSize: "1.125rem", fontWeight: "600" }} >CANVAS</h5>
                            </div>
                            <div className="col-lg-4">
                                <div className='ps-lg-5 ms-lg-5'>
                                    <h5 className='ps-lg-5 ms-lg-5' style={{ fontSize: "1.125rem", fontWeight: "600" }} > Canvas Studio</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container p-5">
                    <div className="row pt-5 d-flex justify-content-center ">
                        <div className="col-lg-5">
                            <div>
                                <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >CANVAS STUDIO FOR K–12.</p>
                                <h2 className='h1FontFamily' style={{ fontSize: "3.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Engagement. <span className='' style={{ color: "#e4060f", letterSpacing: "1px", fontWeight: "800" }}> To the Power of Video. </span></h2>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <p className='mb-4' style={{ fontSize: "1.2rem", color: "#143d50" }}> Canvas Studio is the next-generation video education platform for K–12 teaching and learning.</p>
                            <div className='pt-4 '>
                                <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET’S CONNECT  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-5 pt-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} >Canvas LMS lets you focus more on teaching (and less on tasks).</h2>
                                    <p className='pt-3' style={{ color: "#143d50", fontSize: "1.25rem", }} > Provide equitable access and instructional continuity. For every student. Everywhere. Every day. Canvas LMS is the robust digital foundation for all aspects of learning. </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='p-2'>
                                    <video autoPlay loop muted className='w-100 d-none d-sm-block ' style={{ height: '300px', objectFit: "fill", borderRadius: "10px 10px 10px 10px" }}  >
                                        <source src={require("../../img/demo1.mp4")} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container mt-5 mb-5 p-4">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-5 p-0">
                                <img className='imgRESOPNSIVE' src={require("../../img/studio1.png")} alt='' />
                                <div className='ps-lg-5 pt-5 ms-lg-2'>
                                    <p className='fontFamilyHeader' style={{ fontSize: "1.65rem", color: '#287a9f', fontWeight: "800", lineHeight: "1.3" }} > " We use Studio in all of our disciplines from the arts to modern languages to English, history, and religion—across the board. You give me a subject and I’ll find a way for you to use it. " </p>
                                    <h6 className='fontFamilyHeader pt-2' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "800" }} > Beth Crook </h6>
                                    <h6 className='fontFamilyHeader' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "400" }}> Technology Project Manager, St. Andrews School, DE </h6>
                                </div>
                            </div>
                            <div className="col-lg-6 shadow-lg PLUS"  >
                                <h2 className='ps-5  h1FontFamily mt-5' style={{ color: "#156380", fontSize: "2.5rem", lineHeight: "1.2" }} >Video just updated its <br /> resume. Canvas Studio <br /> gives you:</h2>
                                <div className="table p-4 d-flex justify-content-center">
                                    <div className='w-100 ' >
                                        <div className=' '  >
                                            <div className='w-100 p-0'  >
                                                <button className='text-start bg-body  w-100  p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe1() }}> <h3 className='fs-5'> <b>{change1 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span>  <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className=' ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Your Own Media Library </span></b></h3></button>
                                                <div className=''>
                                                    {
                                                        change1 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Create, deliver, host, and organize video content in all major formats. In your own media library.</p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe2() }}><h3 className='fs-5'> <b> {change2 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >  Easy Uploads & Imports </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change2 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Students and teachers can add video from phones, laptops, YouTube, etc. right to courses using Canvas Studio’s intuitive tools. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start  w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe3() }}> <h3 className='fs-5'><b> {change3 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i></span> : <span> <i className="fa-solid fa-plus " style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Engagement Stats, Stat </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change3 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > See who’s watched a video, for how long, and what parts. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe4() }}><h3 className='fs-5'> <b> {change4 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >  Live Video Integrations </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change4 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Easily connect with platforms like Zoom, Microsoft Teams, and Google Hangouts. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe5() }}><h3 className='fs-5'> <b> {change5 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Better Professional Development </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change5 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Record or import video. Store it, deliver it, and share it with your fellow educators. Even interact via video. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-4">
                        <div className="row p-5 pt-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > ACTIVE LEARNING </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Go from passive observation to active conversation. </h2>
                                    <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.1rem", }} > Robust, easy-to-use tools let you create and deliver interactive learning experiences that hold kids’ attention and stand up to distractions. Students can produce and submit their own video creations in Canvas Studio and can easily collaborate and comment on each other’s work. Everybody stays engaged.</p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} > Engagement from anywhere </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} > Easy video collaboration </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} > Stream across platforms and devices </span></p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/studio2.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section >
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-5 pt-5 d-flex justify-content-around ">

                            <div className="col-lg-5">
                                <img className='w-100 mt-3' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/studio3.png")} alt='' />
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >LEARNING INSIGHTS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Activate eye-opening analytics and insights. </h2>
                                    <p className='pt-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Find out who’s watching which videos and for how long. See overall class data at once or dig into each student’s viewing details. Then use this valuable data to decide how to engage students further.</p>
                                </div>
                                <div>
                                    <h2 className='pt-2 SolutionDropDownFontFamily' style={{ fontSize: "1.3rem", color: "#287a9f", fontWeight: "700", lineHeight: "1.5" }} > " The engagement analytics in Canvas Studio provide super helpful feedback, because if a student is saying they don’t understand a subject or standard, we have valuable insight into what pieces of content they are watching and which ones are just being clicked on. " </h2>
                                    <h6 className='fontFamilyHeader pt-4' style={{ fontSize: "1.125rem", color: '#287a9f', fontWeight: "600" }} > — Kirsten Wilson </h6>
                                    <h6 className='fontFamilyHeader' style={{ fontSize: "1.125rem", color: '#287a9f', fontWeight: "400" }}> Director of Curriculum and Instruction at Virtual Arkansas </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#F2F8FA" }} >
                    <div className="container p-lg-5">

                        <div className='d-flex justify-content-center text-center'>
                            <div className='' style={{ maxWidth: "40%" }} >
                                <p className='' style={{ color: "#143d50", fontWeight: "800", letterSpacing: "2px" }} >MORE FEATURES</p>
                                <h4 className='UbuntuFONTFAMILY pt-2' style={{ color: "#156380", fontSize: "2.2rem", fontWeight: "700", letterSpacing: "2px" }} >Extra credit for Canvas Studio:</h4>
                            </div>
                        </div>

                        <div className="row p-lg-5 ">
                            <div className="col-lg-4 col-md-6 ">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Lessons Get Better</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Bring new life, personalization, and knowledge to your courses with your own video content.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Everybody Benefits</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Deliver, host, and organize video in a cost-effective manner across your district.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Absolute Accessibility</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Upload and edit caption files and create auto-captions in your choice of 19 languages.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Directed By: You</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Record videos with a webcam or upload content by simply dragging and dropping a file.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Embed Video Anywhere</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Use built-in sharing options and place Studio videos anywhere. No other platforms needed.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >All Inside the Canvas LMS</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Everyone can easily access, create, upload, and interact with video assets, right inside the Canvas LMS.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-lg-5 ">
                            <div className="col-lg-4 col-md-12">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE ALL PRODUCTS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Explore the Entire Canvas Product Family </h2>
                                    <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > Simplify teaching and learning activities, organize coursework and keep teachers, students, and families connected and communicating. Anytime, anywhere.</p>
                                    <div className='pt-4 pb-5'>
                                        <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="row">
                                    <div className="col-lg">
                                        <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas LMS</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > It’s the next-generation video learning solution that turns one‑way, passive video into inclusive, engaging, and productive classroom discussions.</p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg pt-5">
                                            <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                                <div className='p-3'>
                                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Credentials</h6>
                                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Canvas Credentials empowers educators with more than just a transcript. With verifiable digital credentials, they have a comprehensive learner record to demonstrate progress towards their goals.</p>
                                                    <div className=''>
                                                        <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                                <div className="row">
                                    <div className="col-lg ">
                                        <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Catalog</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Catalog is a simple, modern course catalog registration system and branded marketplace for all of your district’s professional development and community resource offerings.</p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg ">
                                            <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                                <div className='p-3'>
                                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Impact by Instructure</h6>
                                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Improve technology adoption, support teachers and students in their tech use, and evaluate the impact of educational technology on student engagement.</p>
                                                    <div className=''>
                                                        <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5 ">
                        <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
                        <div className="row d-flex justify-content-center p-5">
                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px",height:"12rem" }} src={require("../../img/studio4.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Product Demos & Videos</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }} >Canvas Studio | 2 Minute Overview</h4>
                                        <div className=' mt-lg-4 pt-lg-3'>
                                            <button type="button" class="btn btn-link text-decoration-none mt-lg-4 pt-lg-3" style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px",height:"12rem" }} src={require("../../img/lms4.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Case Studies</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1", color: "#143d50", fontSize: "28px", fontWeight: "600" }}>Virtual Arkansas: Scaling High-Quality Content & Courses With Canvas</h4>
                                        <div className=' mt-lg-4'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
                    <div className="container  p-lg-5 ">
                        <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
                            <div className='text-lg-center pb-lg-4'>
                                <h2 className='pb-2 SolutionDropDownFontFamily' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to set the scene for active video learning? </h2>
                                <div className='pt-4 '>
                                    <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CanvasStudio