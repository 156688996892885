import React from "react";
import "./NewsEvents.css";

function UpcommingEvents() {
  return (
    <>
      <section>
        <div className="">
          <div className=" event-bgImg-container">
            <div className="h1FontFamily1  upcomming-data-text ">
              <h3 className="h1FontFamily" id="upcomming-Event-date">
                JULY 9TH - 11TH, 2024
              </h3>
              <h2 className="UbuntuFONTFAMILY" id="Upcomming-heading-title">
                InstructureCon 2024
              </h2>
              <p>
                Join us for three days full of insightful presenters,
                informative sessions, excellent entertainment, and more ways to
                connect and engage than ever before!
              </p>
              <div className="Jump-To p-3">
                <button
                  className=""
                  style={{
                    borderRadius: "20px 20px 20px 20px",
                    backgroundColor: "red",
                    border: "none",
                    color: "white",
                    padding: "10px 15px",
                  }}
                >
                  <b className="p-2" style={{ fontSize: "1rem" }}>
                    Join Us
                    <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                  </b>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container-fluid p-0 d-sm-none d-block ">
          <div
            className="w-100 event-bgImg-container"
            style={{
              objectFit: "cover",
              height: "650px",
              backgroundImage: `url(${require("../../img/UpcommingEventBg.jpg")})`,
              backgroundAttachment: "local",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: " center,center ",
            }}
          ></div>
          <div className="h1FontFamily1 start-50 translate-middle upcomming-data-text">
            <h3 className="h1FontFamily" id="upcomming-Event-date">
              JULY 8TH - 11TH, 2024
            </h3>
            <h2 className="UbuntuFONTFAMILY" id="Upcomming-heading-title">
              InstructureCon 2024
            </h2>
            <p>
              Join us for three days full of insightful presenters, informative
              sessions, excellent entertainment, and more ways to connect and
              engage than ever before!
            </p>
            <div className="Jump-To p-3">
              <button
                className=""
                style={{
                  borderRadius: "20px 20px 20px 20px",
                  backgroundColor: "red",
                  border: "none",
                  color: "white",
                  padding: "10px 15px",
                }}
              >
                <b className="p-2" style={{ fontSize: "1rem" }}>
                  Join Us
                  <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                </b>
              </button>
            </div>
          </div>
        </div> */}
      </section>

      <section>
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center p-1">
            <div className="row g-4 ">
              <div className="col-xl-4 col-md-6 col-12">
                <div className="h-100 card-img shadow-lg SolutionDropDownFontFamily noHover">
                  <div className="img-blend">
                    <img
                      className="w-100"
                      src={require("../../img/demoEvent.png")}
                      alt="blog-article"
                    />
                    <div className="c-data-container">
                      <div className="case-two-container">
                        <div className="eventDate">
                          <div className="d-flex justify-content-center align-items-center pt-2">
                            Feb 20, 2024
                          </div>

                          <span className="date-span-visible">
                            8:30AM - 8:30AM America/Denver
                          </span>
                        </div>
                        <div className="sub-c-data">
                          <h3
                            className=""
                            style={{
                              lineHeight: "1.1",
                              color: "rgb(20, 61, 80)",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            CALET Pre-Conference Workshop
                          </h3>

                          <div className="view-field-show">
                            <div className="view-field-body">
                              The annual CASE Winter Leadership Conference,
                              Excellence By Association, is the only conference
                              that brings together public education leaders from
                              every job position in the state during the school
                              year. Featuring timely and valuable sessions, a
                              robust legislative and policy program, and several
                              engaging networking opportunities, the CASE Winter
                              Leadership Conference provides a dose.
                            </div>
                          </div>

                          <div className="lern-more-btn">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none"
                              style={{
                                color: "rgb(228, 6, 15)",
                                fontWeight: "600",
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              Learn More
                              <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-12">
                <div className="h-100 card-img shadow-lg SolutionDropDownFontFamily noHover">
                  <div className="img-blend">
                    <img
                      className="w-100"
                      src={require("../../img/CASEthumb.jpg")}
                      alt="blog-article"
                    />

                    <div className="c-data-container">
                      <div className="case-two-container">
                        <div className="eventDate">
                          <div className="d-flex justify-content-center align-items-center pt-2">
                            Feb 21, Feb 22 2024
                          </div>

                          <span className="date-span-visible">
                            8:30AM - 8:30AM America/Denver
                          </span>
                        </div>
                        <div className="sub-c-data">
                          <h3
                            className=""
                            style={{
                              lineHeight: "1.1",
                              color: "rgb(20, 61, 80)",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            MECA - Mississippi Educational Computing Association
                          </h3>

                          <div className="view-field-show">
                            <div className="view-field-body">
                              This annual event geared solely toward Colorado
                              superintendents focuses on the most timely and
                              critical issues facing public education. A mix of
                              presentations, speakers, and ample time for
                              discussion and networking creates a valuable and
                              engaging conference experience. Information for
                              the 2024 event will be released at the end of
                              summer 2024.
                            </div>
                          </div>

                          <div className="lern-more-btn">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none"
                              style={{
                                color: "rgb(228, 6, 15)",
                                fontWeight: "600",
                                padding: "0px",
                              }}
                            >
                              Learn More
                              <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-12">
                <div className="h-100 card-img shadow-lg SolutionDropDownFontFamily noHover">
                  <div className="img-blend">
                    <img
                      className="w-100"
                      src={require("../../img/MECAthumb.jpg")}
                      alt="blog-article"
                    />

                    <div className="c-data-container">
                      <div className="case-two-container">
                        <div className="eventDate">
                          <div className="d-flex justify-content-center align-items-center pt-2">
                            Feb 21 - Feb 23 , 2024
                          </div>

                          <span className="date-span-visible">
                            8:30AM - 8:30AM America/Denver
                          </span>
                        </div>
                        <div className="sub-c-data">
                          <h3
                            className=""
                            style={{
                              lineHeight: "1.1",
                              color: "rgb(20, 61, 80)",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            MECA - Mississippi Educational Computing Association
                          </h3>

                          <div className="view-field-show">
                            <div className="view-field-body">
                              Welcome to MECA, the Mississippi Educational
                              Computing Association, where innovation meets
                              education! Dedicated to fostering the exchange of
                              ideas, techniques, materials, and procedures, MECA
                              brings together individuals passionate about
                              technology in education. Our focus spans
                              instructional applications, administrative
                              applications, and computer science education.
                            </div>
                          </div>

                          <div className="lern-more-btn">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none"
                              style={{
                                color: "rgb(228, 6, 15)",
                                fontWeight: "600",
                                padding: "0px",
                              }}
                            >
                              Learn More
                              <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-12">
                <div className="h-100 card-img shadow-lg SolutionDropDownFontFamily noHover">
                  <div className="img-blend">
                    <img
                      className="w-100"
                      src={require("../../img/ul-system.jpg")}
                      alt="blog-article"
                    />

                    <div className="c-data-container">
                      <div className="case-two-container">
                        <div className="eventDate">
                          <div className="d-flex justify-content-center align-items-center pt-2">
                            Feb 25 - Feb 27, 2024
                          </div>

                          <span className="date-span-visible">
                            8:30AM - 8:30AM America/Denver
                          </span>
                        </div>
                        <div className="sub-c-data">
                          <h3
                            className=""
                            style={{
                              lineHeight: "1.1",
                              color: "rgb(20, 61, 80)",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            For Our Future Conference - University of Louisiana
                            System
                          </h3>

                          <div className="view-field-show">
                            <div className="view-field-body">
                              The event focuses on enhancing various aspects of
                              student life, including Student Health and
                              Wellness, Power-Based Violence, Free Expression,
                              International Students, and Supporting Underserved
                              Populations. Additionally, it aims to develop
                              areas such as Scholarship and Research, Academic
                              Freedom, Faculty Development, and Online and
                              Hybrid Delivery.
                            </div>
                          </div>

                          <div className="lern-more-btn">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none p-0"
                              style={{
                                color: "rgb(228, 6, 15)",
                                fontWeight: "600",
                                margin: "0px",
                              }}
                            >
                              Learn More
                              <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-12 ">
                <div className="h-100 card-img shadow-lg SolutionDropDownFontFamily noHover">
                  <div className="img-blend">
                    <img
                      className="w-100"
                      src={require("../../img/EdTech-effective.jpg")}
                      alt="blog-article"
                    />

                    <div className="c-data-container">
                      <div className="case-two-container">
                        <div className="eventDate">
                          <div className="d-flex justify-content-center align-items-center pt-2">
                            Feb 28 , 2024
                          </div>

                          <span className="date-span-visible">
                            11:00AM - 11:00AM America/Denver
                          </span>
                        </div>
                        <div className="sub-c-data">
                          <h3
                            className=""
                            style={{
                              lineHeight: "1.1",
                              color: "rgb(20, 61, 80)",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            Do More with Less - Refine your EdTech Ecosystem to
                            Maximize Impact
                          </h3>

                          <div className="view-field-show">
                            <div className="view-field-body">
                              ESSER funds expire in September 2024, which means
                              K-12 administrators must take control of sprawling
                              edtech ecosystems and consider evidence-based
                              edtech management approaches.&nbsp;
                            </div>
                          </div>

                          <div className="lern-more-btn">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none p-0"
                              style={{
                                color: "rgb(228, 6, 15)",
                                fontWeight: "600",
                                margin: "0px",
                              }}
                            >
                              Learn More
                              <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-12">
                <div className="h-100 card-img shadow-lg SolutionDropDownFontFamily noHover">
                  <div className="img-blend">
                    <img
                      className="w-100"
                      src={require("../../img/Default-BG-New.jpg")}
                      alt="blog-article"
                    />

                    <div className="c-data-container">
                      <div className="case-two-container">
                        <div className="eventDate">
                          <div className="d-flex justify-content-center align-items-center pt-2">
                            Feb 29 , 2024
                          </div>

                          <span className="date-span-visible">
                            5:30PM - 7:30PM America/Denver
                          </span>
                        </div>
                        <div className="sub-c-data">
                          <h3
                            className=""
                            style={{
                              lineHeight: "1.1",
                              color: "rgb(20, 61, 80)",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            US Embassy Event
                          </h3>

                          <div className="view-field-show">
                            <div className="view-field-body">
                              Shaping The Workforce Of The Future:
                              Micro-Credentials and Lifelong Learning
                            </div>
                          </div>

                          <div className="lern-more-btn">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none p-0"
                              style={{
                                color: "rgb(228, 6, 15)",
                                fontWeight: "600",
                                margin: "0px",
                              }}
                            >
                              Learn More
                              <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-12">
                <div className="h-100 card-img shadow-lg SolutionDropDownFontFamily noHover">
                  <div className="img-blend">
                    <img
                      className="w-100"
                      src={require("../../img/austrialianvetconference.jpg")}
                      alt="blog-article"
                    />

                    <div className="c-data-container">
                      <div className="case-two-container">
                        <div className="eventDate">
                          <div className="d-flex justify-content-center align-items-center pt-2">
                            Mar 6 - Mar 8, 2024
                          </div>
                        </div>
                        <div className="sub-c-data">
                          <h3
                            className=""
                            style={{
                              lineHeight: "1.1",
                              color: "rgb(20, 61, 80)",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            Australian Tertiary and VET Conference 2024
                          </h3>

                          <div className="view-field-show">
                            <div className="view-field-body">
                              Australia needs a proactive, efficient, and
                              cohesive Tertiary Education sector that can
                              respond to current and future workforce and social
                              challenges. The Australian Tertiary and VET
                              Conference 2024 will explore critical resources,
                              capabilities, and strategies to support HE and VET
                              sectors dealing with an increasingly competitive,
                              disruptive, and highly digitised future.
                            </div>
                          </div>

                          <div className="lern-more-btn">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none p-0"
                              style={{
                                color: "rgb(228, 6, 15)",
                                fontWeight: "600",
                                margin: "0px",
                              }}
                            >
                              Learn More
                              <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-12">
                <div className="h-100 card-img shadow-lg SolutionDropDownFontFamily noHover">
                  <div className="img-blend">
                    <img
                      className="w-100"
                      src={require("../../img/nctiesthumb.jpg")}
                      alt="blog-article"
                    />

                    <div className="c-data-container">
                      <div className="case-two-container">
                        <div className="eventDate">
                          <div className="d-flex justify-content-center align-items-center pt-2">
                            Mar 6 - Mar 8, 2024
                          </div>
                        </div>
                        <div className="sub-c-data">
                          <h3
                            className=""
                            style={{
                              lineHeight: "1.1",
                              color: "rgb(20, 61, 80)",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            NCTIES - North Carolina Technology in Education
                            Society
                          </h3>

                          <div className="view-field-show">
                            <div className="view-field-body">
                              NCTIES is a membership association for educators
                              and educator leaders engaged in advancing
                              excellence in learning and teaching through the
                              effective use of technology. We are the North
                              Carolina ISTE and CoSN affiliate.
                            </div>
                          </div>

                          <div className="lern-more-btn">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none p-0"
                              style={{
                                color: "rgb(228, 6, 15)",
                                fontWeight: "600",
                                margin: "0px",
                              }}
                            >
                              Learn More
                              <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-12">
                <div className="h-100 card-img shadow-lg SolutionDropDownFontFamily noHover">
                  <div className="img-blend">
                    <img
                      className="w-100"
                      src={require("../../img/AeriesThumb.jpg")}
                      alt="blog-article"
                    />

                    <div className="c-data-container">
                      <div className="case-two-container">
                        <div className="eventDate">
                          <div className="d-flex justify-content-center align-items-center pt-2">
                            Mar 6 - Mar 8, 2024
                          </div>
                        </div>
                        <div className="sub-c-data">
                          <h3
                            className=""
                            style={{
                              lineHeight: "1.1",
                              color: "rgb(20, 61, 80)",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            AeriesCon Spring 2024
                          </h3>

                          <div className="view-field-show">
                            <div className="view-field-body">
                              We are excited to invite you to participate as a
                              Partner at our Spring 2024 AeriesCon Event. We
                              welcome the chance for our customers to connect
                              with your organization!
                            </div>
                          </div>

                          <div className="lern-more-btn">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none p-0"
                              style={{
                                color: "rgb(228, 6, 15)",
                                fontWeight: "600",
                                margin: "0px",
                              }}
                            >
                              Learn More
                              <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-12">
                <div className="h-100 card-img shadow-lg SolutionDropDownFontFamily noHover">
                  <div className="img-blend">
                    <img
                      className="w-100"
                      src={require("../../img/mbo-digital.jpg")}
                      alt="blog-article"
                    />

                    <div className="c-data-container">
                      <div className="case-two-container">
                        <div className="eventDate">
                          <div className="d-flex justify-content-center align-items-center pt-2">
                            Mar 7 - Mar 8, 2024
                          </div>
                        </div>
                        <div className="sub-c-data">
                          <h3
                            className=""
                            style={{
                              lineHeight: "1.1",
                              color: "rgb(20, 61, 80)",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            MBO Digitaal Conferentie
                          </h3>

                          <div className="view-field-show">
                            <div className="view-field-body">
                              Twee keer per jaar organiseert MBO Digitaal samen
                              met een van de aangesloten instellingen, fungerend
                              als gastcollege, de conferentie MBO Digitaal. Op
                              deze conferenties laten onze leden in een
                              gevarieerd programma van keynotes en workshops aan
                              elkaar zien wat er binnen de instellingen speelt.
                            </div>
                          </div>

                          <div className="lern-more-btn">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none p-0"
                              style={{
                                color: "rgb(228, 6, 15)",
                                fontWeight: "600",
                                margin: "0px",
                              }}
                            >
                              Learn More
                              <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-12">
                <div className="h-100 card-img shadow-lg SolutionDropDownFontFamily noHover">
                  <div className="img-blend">
                    <img
                      className="w-100"
                      src={require("../../img/novidades-da.jpg")}
                      alt="blog-article"
                    />

                    <div className="c-data-container">
                      <div className="case-two-container">
                        <div className="eventDate">
                          <div className="d-flex justify-content-center align-items-center pt-2">
                            Mar 7, 2024
                          </div>
                          <span className="date-span-visible">
                            11:00AM - 12:00AM America/Denver
                          </span>
                        </div>
                        <div className="sub-c-data">
                          <h3
                            className=""
                            style={{
                              lineHeight: "1.1",
                              color: "rgb(20, 61, 80)",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            Novidades da plataforma Canvas LMS - Volte às aulas
                            com a Instructure!
                          </h3>

                          <div className="view-field-show">
                            <div className="view-field-body">
                              Nestas volta às aulas otimize ao máximo os
                              processos de ensino tirando o melhor proveito
                              possível do ecossistema de aprendizagem da
                              Instructure. Conheça as novas funcionalidades do
                              Canvas LMS, outros produtos da companhia e as
                              novidades que estão por vir. Inscreva-se hoje!
                            </div>
                          </div>

                          <div className="lern-more-btn">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none p-0"
                              style={{
                                color: "rgb(228, 6, 15)",
                                fontWeight: "600",
                                margin: "0px",
                              }}
                            >
                              Learn More
                              <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-12">
                <div className="h-100 card-img shadow-lg SolutionDropDownFontFamily noHover">
                  <div className="img-blend">
                    <img
                      className="w-100"
                      src={require("../../img/AeriesThumb.jpg")}
                      alt="blog-article"
                    />

                    <div className="c-data-container">
                      <div className="case-two-container">
                        <div className="eventDate">
                          <div className="d-flex justify-content-center align-items-center pt-2">
                            Mar 6 - Mar 8, 2024
                          </div>
                        </div>
                        <div className="sub-c-data">
                          <h3
                            className=""
                            style={{
                              lineHeight: "1.1",
                              color: "rgb(20, 61, 80)",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            AeriesCon Spring 2024
                          </h3>

                          <div className="view-field-show">
                            <div className="view-field-body">
                              We are excited to invite you to participate as a
                              Partner at our Spring 2024 AeriesCon Event. We
                              welcome the chance for our customers to connect
                              with your organization!
                            </div>
                          </div>

                          <div className="lern-more-btn">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none p-0"
                              style={{
                                color: "rgb(228, 6, 15)",
                                fontWeight: "600",
                                margin: "0px",
                              }}
                            >
                              Learn More
                              <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UpcommingEvents;
