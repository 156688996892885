import React from "react";
import "./Articles.css";

function Videos() {
  return (
    <>
      <section
        style={{
          backgroundImage: `url(${require("../../img/mystudypage.jpg")})`,
          backgroundAttachment: "local",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: " center,center ",
        }}
      >
        <div className="container main-container">
          <div
            className="row p-lg-5 d-flex justify-content-around align-items-center"
            style={{
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          >
            <div className="col-lg-6 p-4">
              <div>
                <p
                  style={{
                    fontSize: ".875rem",
                    lineHeight: "1.5",
                    textTransform: "uppercase",
                    letterSpacing: ".12em",
                    fontWeight: "700",
                    marginBottom: "5px",
                  }}
                >
                  Resource Center
                </p>
              </div>
              <div className="mb-3">
                <h1 className="UbuntuFONTFAMILY">
                  <p className="p-0 m-0 title-font-text" style={{}}>
                    The Instructure{" "}
                  </p>
                  <span id="font-text-red" className="title-font-text">
                    Study Hall
                  </span>
                </h1>
              </div>
              <div className="study-para-text">
                <p className="fontFamilyHeader fw-normal">
                  Dive into the videos below to hear more customer stories, see
                  our products in action, and learn more about how we're
                  amplifying teaching, elevating learning, and intensifying
                  impact across K-12 and Higher Education and keeping a pulse on
                  the everchanging education landscape.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="row d-flex align-items-center"
                style={{ padding: "15px" }}
              >
                <div className="col-lg-6 col-5">
                  <div className="">
                    <img
                      className="research-img"
                      src={require("../../img/video-img1.jpg")}
                      alt="resource-contain"
                    ></img>
                  </div>
                </div>
                <div className="col-lg-6 col-6 reserch-content">
                  <div>
                    <h6 className="research-font">VIDEO</h6>
                    <p className="h1FontFamily research-text">
                      Hamilton County Schools and Mastery Connect
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="row d-flex  align-items-center"
                style={{ padding: "15px" }}
              >
                <div className="col-lg-6 col-5">
                  <div className="">
                    <img
                      className="research-img"
                      src={require("../../img/BoiseState.jpg")}
                      alt="resource-contain"
                    ></img>
                  </div>
                </div>
                <div className="col-lg-6 col-6 reserch-content">
                  <div>
                    <h6 className="research-font">VIDEO</h6>
                    <p className="h1FontFamily research-text">
                      Boise State University and Canvas
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <nav className="container mt-4">
        <ul
          className="d-flex justify-content-start"
          style={{ listStyle: "none" }}
        >
          <li>
            <a href="/" style={{ textDecoration: "none", color: "#000" }}>
              Study Hall Main Hub &nbsp; &#x3009; &nbsp;
            </a>
          </li>

          <li>
            <a
              href="/"
              style={{
                textDecoration: "none",
                color: "#000",
                borderBottom: "1px solid black",
              }}
            >
              Videos Hub Page
            </a>
          </li>
        </ul>
      </nav>
      <section>
        <div className="layout container">
          <div className="layout-content">
            <div>
              <p
                className="text-center title-font-text"
                style={{ fontSize: "2.5rem" }}
              >
                Videos
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="m-3">
        <div className="container d-flex align-items-center justify-content-center p-2">
          <ul className=" blog-list">
            <li className="border-item h5 pe-5 me-3">
              Product
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="border-item h5 pe-5 me-3">
              Org Type
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="border-item h5 pe-5 me-3">
              Topic
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="border-item h5 pe-5 me-3">
              Search...
              <span className="ps-5">
                {" "}
                <i class="fa-solid fa-caret-down"></i>
              </span>
            </li>
            <li className="mb-2">
              <button className="btn-apply">
                <b className="p-2" style={{ fontSize: "1rem" }}>
                  Apply
                </b>
              </button>
            </li>
          </ul>
        </div>
      </section>
      <section>
        <div className="container ">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 d-sm-flex flex-sm-column justify-content-sm-center align-items-sm-center cards-resp-screen">
              <div className="row me-4 mt-2 g-4">
                <div className="col-lg-8 col-md-6 p-3 ">
                  <div
                    className="h-100 bg-white blog-card shadow-lg SolutionDropDownFontFamily"
                    style={{
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div className="card-img">
                      <img
                        className="w-100"
                        src={require("../../img/Vid-img1.jpg")}
                        alt="blog-article"
                      />

                      <div className="p-3 article-content">
                        <h6
                          className="ps-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(78, 111, 126)",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Product Demos & Videos
                        </h6>

                        <h3
                          className="p-0 ps-2 shutter-btn"
                          style={{
                            lineHeight: "1.1",
                            color: "rgb(20, 61, 80)",
                            fontSize: "29px",
                            fontWeight: "700",
                          }}
                        >
                          Exploring the Potential of Generative AI in…
                        </h3>

                        <div className="view-field-body">
                          <div
                            className=" ps-2"
                            style={{
                              lineHeight: "1.5",
                              color: "rgb(20, 61, 80)",
                              fontSize: "1rem",
                            }}
                          >
                            Watch as education leaders come together to explore
                            the impact of Generative
                          </div>
                        </div>

                        <div className="btn-read">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none p-0 m-2"
                            style={{
                              color: "rgb(228, 6, 15)",
                              fontWeight: "600",
                            }}
                          >
                            WATCH VIDEO
                            <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Video-img2.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Product Demos & Videos
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Actionable Insights for New Quizzes Success…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Get ready to kickoff your own New Quizzes success
                          story!
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          WATCH VIDEO
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Video-img3.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Product Demos & Videos
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        The Latest and Greatest in Mastery Connect:…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Watch the latest Mastery Connect updates in action.
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          WATCH VIDEO
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/vIDEO-IMG4.JPG")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Product Demos & Videos
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Penn State: Leveraging Robust Analytics to…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Learn how Penn State leverages analytics from Amazon
                          Web Services (AWS) and
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          WATCH VIDEO
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Video-img5.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Product Demos & Videos
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Inform Decision-Making with Canvas Admin…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Take a closer look at how Canvas Admin Analytics
                          brings data to the surface
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          WATCH VIDEO
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/Video-img6.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Product Demos & Videos
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Instructure's Technology Foundation and…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Chief Architect Zach Pendleton and Chief Engineering
                          Officer Steve Townsend
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          WATCH VIDEO
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 col-md-6 p-3">
                  <div
                    className="h-100 bg-white blog-card shadow-lg SolutionDropDownFontFamily"
                    style={{
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div className="card-img">
                      <img
                        className="w-100"
                        src={require("../../img/Video-img7.jpg")}
                        alt="blog-article"
                      />

                      <div className="p-3 article-content ">
                        <h6
                          className="ps-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(78, 111, 126)",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Product Demos & Videos
                        </h6>

                        <h3
                          className="p-0 ps-2 shutter-btn"
                          style={{
                            lineHeight: "1.1",
                            color: "rgb(20, 61, 80)",
                            fontSize: "29px",
                            fontWeight: "700",
                          }}
                        >
                          Effective and Efficient Edtech Management:…
                        </h3>

                        <div className="view-field-body">
                          <div
                            className=" ps-2"
                            style={{
                              lineHeight: "1.5",
                              color: "rgb(20, 61, 80)",
                              fontSize: "1rem",
                            }}
                          >
                            Kristie Lindell, VP of LearnPlatform Product
                            Management joins the stage to share
                          </div>
                        </div>

                        <div className="btn-read">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none p-0 m-2"
                            style={{
                              color: "rgb(228, 6, 15)",
                              fontWeight: "600",
                            }}
                          >
                            WATCH VIDEO
                            <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/VideoImg8.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Product Demos & Videos
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        A Modern Product Approach for the Whole…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Chief Product Officer Shiren Vijiasingam shares
                          Instructure's strategic product
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          WATCH VIDEO
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/videoimg9.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Product Demos & Videos
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        A Focus on Lifelong Learning: Shifts in…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Tara Goldman, VP of Canvas LMS Product Management
                          describes how Canvas LMS can
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          WATCH VIDEO
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/VideoImg-10.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Product Demos & Videos
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        User-centered Innovation: Inside New Quizzes…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Canvas LMS product leader Jody Sailor shares about
                          exciting updates to New
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          WATCH VIDEO
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/VideoImg11.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Product Demos & Videos
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Intentional, Safe, and Equitable: A Look at…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Chief Architect Zach Pendleton demos powerful new AI
                          futures coming soon in beta
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          WATCH VIDEO
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/videoImg12.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Product Demos & Videos
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Mastery Connect's Back to School…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Mastery Connect product leader Dana Okerlund shares
                          exciting new updates to
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          WATCH VIDEO
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/VideoImg11.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Product Demos & Videos
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Instructure and Khan Academy Announce…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Shiren Vijiasingam and Jody Sailor make an exciting
                          announcement about a new
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          WATCH VIDEO
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 col-md-6 p-3">
                  <div
                    className="h-100 bg-white blog-card shadow-lg SolutionDropDownFontFamily"
                    style={{
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div className="card-img">
                      <img
                        className="w-100"
                        src={require("../../img/VideoImg14.jpg")}
                        alt="blog-article"
                      />

                      <div className="p-3 article-content">
                        <h6
                          className="ps-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(78, 111, 126)",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Product Demos & Videos
                        </h6>

                        <h3
                          className="p-0 ps-2 shutter-btn"
                          style={{
                            lineHeight: "1.1",
                            color: "rgb(20, 61, 80)",
                            fontSize: "29px",
                            fontWeight: "700",
                          }}
                        >
                          Education for All: Canvas + ASU, 100 Million…
                        </h3>

                        <div className="view-field-body">
                          <div
                            className="ps-2"
                            style={{
                              lineHeight: "1.5",
                              color: "rgb(20, 61, 80)",
                              fontSize: "1rem",
                            }}
                          >
                            Instructure's Melissa Loble and Jess Awtry share
                            about the importance of
                          </div>
                        </div>

                        <div className="btn-read pt-lg-3">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none p-0 m-2"
                            style={{
                              color: "rgb(228, 6, 15)",
                              fontWeight: "600",
                            }}
                          >
                            WATCH VIDEO
                            <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 p-3">
                  <div
                    className="h-100 shadow-lg SolutionDropDownFontFamily bg-white"
                    style={{ borderRadius: "10px 10px 10px 10px" }}
                  >
                    <img
                      className="w-100"
                      style={{
                        borderRadius: "10px 10px 0px 0px",
                        height: "12rem",
                      }}
                      src={require("../../img/videoImg15.jpg")}
                      alt=""
                    />
                    <div className="p-3 ">
                      <h6
                        className="p-2"
                        style={{
                          lineHeight: "1.5",
                          color: "#4e6f7e",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Product Demos & Videos
                      </h6>
                      <h4
                        className="p-2"
                        style={{
                          lineHeight: "1.1",
                          color: "#143d50",
                          fontSize: "29px",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        All Things Analytics: Canvas Data 2, Admin…
                      </h4>
                      <div>
                        <div
                          className=" p-2"
                          style={{
                            lineHeight: "1.5",
                            color: "rgb(20, 61, 80)",
                            fontSize: "1rem",
                          }}
                        >
                          Analytics leader Carly Curran dives into exciting
                          analytics updates, including
                        </div>
                      </div>
                      <div className="pt-lg-5 mt-lg-5  ">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none "
                          style={{
                            color: "#e4060f",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          LEARN MORE
                          <i className="ms-2 fa-sharp fa-solid fa-arrow-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      ;
    </>
  );
}

export default Videos;
