import React from 'react'

const ForITTechnologists = () => {
  return (
    <>
      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-4">
            <div className="row p-5 pt-5 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >FOR K-12 IT</p>
                  <h2 className='h1FontFamily' style={{ fontSize: "3.6rem", color: "#287a9f", fontWeight: "800", lineHeight: "1" }} > Where equitable learning <span className='' style={{ color: "#e4060f", letterSpacing: "1px", fontWeight: "800" }}> meets scalable technology. </span></h2>
                  <p className='mb-4 pt-2' style={{ fontSize: "1.2rem", color: "#143d50" }}> As an IT leader, you want technology that teachers and students will use. Cloud-based solutions that anticipate their needs and connect with your existing tools. Technology that makes everyone’s lives easier, supports data interactions at every level, and can scale with the evolving needs of your district. </p>
                  <div className='pt-2 '>
                    <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400", letterSpacing: "2px" }}> LET'S CONNECT <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../../img/IT.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-lg-5">
            <div className="row p-lg-4  d-flex justify-content-around ">

              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../../img/IT1.png")} alt='' />
              </div>

              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > FOR IT/TECHNOLOGISTS </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Information is power—the power to impact student achievement. </h2>
                  <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > The Instructure Learning Platform has a long track record of helping schools and districts equitably scale student achievement—in person, online, and in blended environments. Easy-to-implement and easy to use, with industry-best open architecture to help you craft the perfect learning ecosystem for your school and provide stakeholders with timely, relevant information. And we have separate apps for students, parents, and teachers, so they can stay in the know, on the go. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container p-5">
            <div className="row p-4 d-flex justify-content-center text-center SolutionDropDownFontFamily">
              <div className='SolutionDropDownFontFamily pb-lg-4'>
                <h6 className='' style={{ color: "#143d50", fontSize: ".875rem", lineHeight: "1.5", letterSpacing: "1px", fontWeight: "700" }} >GIVE STUDENTS WHAT THEY NEED</h6>
                <h6 className='pb-2' style={{ color: "#156380", fontSize: "3rem", lineHeight: "1.2", letterSpacing: "1px", fontWeight: "700" }} >We have you covered.</h6>
                <p className='' style={{ color: "#444", fontSize: "1.125rem" }} >Supporting schools and districts in making learning personal. For <br /> every teacher and student. Every single day.</p>
              </div>
              <div className="col-lg-3">
                <img className='w-100' src={require("../../../img/FA2.png")} alt='' />
                <h6 className='' style={{ color: "#156380", fontSize: "1.85rem", fontWeight: "800" }} > Learning Management </h6>
                <div className=''>
                  <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                </div>
              </div>

              <div className="col-lg-3">
                <img className='w-100' src={require("../../../img/FA3.png")} alt='' />
                <h6 className='' style={{ color: "#156380", fontSize: "1.85rem", fontWeight: "800" }} > Assessment </h6>
                <div className=''>
                  <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                </div>
              </div>

              <div className="col-lg-3">
                <img className='w-100' src={require("../../../img/FA4.png")} alt='' />
                <h6 className='' style={{ color: "#156380", fontSize: "1.85rem", fontWeight: "800" }} > Analytics </h6>
                <div className=''>
                  <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                </div>
              </div>

              <div className="col-lg-3">
                <img className='w-100' src={require("../../../img/IT3.png")} alt='' />
                <h6 className='' style={{ color: "#156380", fontSize: "1.85rem", fontWeight: "800" }} > Integrations </h6>
                <div className=''>
                  <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row p-4 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > ONE PLATFORM, ENDLESS OPPORTUNITY </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Your open, LTI-compliant partner for innovation. </h2>
                  <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > These days, one learning solution doesn’t fit all. You need a partner that will help you build the learning ecosystem that meets the unique needs of your school or district. We work with you to create a custom implementation plan. We provide world-class support for quick bug fixes and simple data migrations. Our open API and LTI integrations connect with existing learning tools. And we manage the systems and streamline teacher adoption so you can focus on supporting the teaching and learning process. </p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Freedom to customize and expand your <span className='ps-4 ms-2' >  learning ecosystem </span>  </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > A reliable solution with 99.9% uptime  </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Seamless integrations enhance teaching and <span className='ps-4 ms-2' > learning </span> </span></p>
                </div>
              </div>
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../../img/IT4.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-lg-5">
            <div className="row p-lg-4  d-flex justify-content-around ">
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../../img/IT5.png")} alt='' />
              </div>

              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > ADOPTION </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > The platform that teachers and students use. </h2>
                  <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Whether your faculty and students are digital natives or technology newbies, our platform quickly becomes second nature. It keeps everyone learning, interacting, and engaging from anywhere. It also provides data that supports both teachers’ and schools’ success. </p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Easy to use </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Keeps everyone on the same page </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Gives you data to support success </span></p>
                </div>
              </div>
              <div className='p-lg-5 ps-lg-5 ms-lg-5'>
                <p className=' SolutionDropDownFontFamily pb-2' style={{ fontSize: "1.425rem", color: "#287a9f", fontWeight: "800" }} > "Our Canvas solutions engineer was very instrumental in our decision-making process. He showed us that Canvas would allow us to see beyond where we were at that time and that we were adopting a system that was going to grow with us." </p>
                <h6 className=' SolutionDropDownFontFamily' style={{ fontSize: "1.125rem", color: "#287a9f", fontWeight: "600" }} > — Gretta Rogne </h6>
                <h6 className=' SolutionDropDownFontFamily' style={{ fontSize: "1.125rem", color: "#287a9f", fontWeight: "400" }} > Director of Digital Learning at New Caney ISD </h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row p-lg-5 ">
              <div className="col-lg-4 col-md-12">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE ALL PRODUCTS</p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Discover the Instructure Learning Platform </h2>
                  <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > The Instructure Learning Platform delivers what you need to enrich, support, and connect every aspect of teaching and learning. </p>
                  <div className='pt-4 pb-5'>
                    <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="row">
                  <div className="col-lg ">
                    <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../../img/icon.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas LMS</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Course materials. Communication tools. Participation and grades. Data and insights. All part of Canvas LMS. Ready to support learning anywhere. </p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg ">
                    <div className='p-lg-3 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../../img/icon.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Studio</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > It’s the next-generation video learning solution that turns one‑way, passive video into inclusive, engaging, and productive classroom discussions. </p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg pt-5">
                    <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../../img/ms2.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Elevate Analytics </h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > The analytics solution that combines data from multiple sources and makes it easy for districts to put the right information into the hands of educators and administrators. </p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#0097d3", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                <div className="row">
                  <div className="col-lg ">
                    <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../../img/ms1.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.4rem", fontWeight: "800" }} >Mastery connect</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Drive student outcomes with actionable, standards-based insights that improve teaching and learning, leading to even more helpful insights and even better teaching and learning.</p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg ">
                    <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../../img/ms3.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Mastery by Instructure</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Drive learning outcomes and standards mastery with high-quality assessments and actionable insights that inform and improve teaching and learning. </p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#f76400", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-lg-5 ">
            <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
            <div className="row d-flex justify-content-center p-lg-5">
              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../../img/mc7.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Ebooks & Buyer’s Guides</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }} > The K12 Guide to Choosing a Learning Management Platform </h4>
                    <div className='pt-lg-5 mt-lg-3'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../../img/lms4.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Blog Articles</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }}>Canvas + Google = Better Together</h4>
                    <div className='pt-lg-5 mt-lg-5'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../../img/lms4.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Case Studies</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }}>Microsoft Teams Meetings + Canvas: In The Classroom</h4>
                    <div className='pt-lg-5 mt-lg-3'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
          <div className="container  p-lg-5 ">
            <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
              <div className='text-lg-center pb-lg-4'>
                <h2 className='pb-2 SolutionDropDownFontFamily' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to explore our learning ecosystem? </h2>
                <div className='pt-4 '>
                  <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default ForITTechnologists