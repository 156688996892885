import React from 'react'

const IntegrationsWithPartnerTools = () => {

    return (

        <>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-4">
                        <div className="row p-5 pt-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >K-12 INTEGRATIONS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "3.6rem", color: "#287a9f", fontWeight: "800", lineHeight: "1" }} > All the best online tools and apps  <span className='' style={{ color: "#e4060f", letterSpacing: "1px", fontWeight: "800" }}> come together in our platform. </span></h2>
                                    <p className='mb-4 pt-2' style={{ fontSize: "1.2rem", color: "#143d50" }}> The Instructure Learning Platform easily integrates the tools K–12 educators and students need with the apps they love in one seamless learning experience. </p>
                                    <div className='pt-2 '>
                                        <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400", letterSpacing: "2px" }}> REQUEST A DEMO <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/int.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-4 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > ONE PLATFORM. ALL YOUR FAVORITES. </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > A consistent, familiar digital learning environment. </h2>
                                    <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Openness is at the heart of the Instructure Learning Platform. No matter what apps and tools you add, it’s the hub of your digital learning system—one single-sign-on-ready, integration-loving platform. Both teachers and students benefit from a unified experience in a familiar digital classroom. </p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > One hub for all digital learning</span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Easy movement between tools and apps </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Open APIs and LTI integrations </span></p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/int1.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-5">
                        <div className="row p-lg-4  d-flex justify-content-around ">

                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/int2.png")} alt='' />
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > OPEN FOR LEARNING </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Open architecture. Open for progress. </h2>
                                    <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > You know best what works best for your district, school, and classroom, so don’t be forced into a rigid arrangement of tools. With open architecture built on industry-leading technology, our reliable, secure platform allows you to build the right ecosystem for you. Plus, our open culture is all about collaborative partnerships. And our open platform is made to connect with your favorite tools, creating a consistent, familiar workflow for data, content, and collaboration from anywhere. </p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Options for every learning style  </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > More flexibility for teachers </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > More ways for students to engage </span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-4 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > INTEGRATE WITH EVERYTHING </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Not just API—LTI. </h2>
                                    <p className='pt-3 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > The open API isn't the only way you can extend the learning ecosystem and amplify its teaching and learning potential. Our platform supports Learning Tools Interoperability (LTI). Specifically, the latest version of LTI: LTI Advantage. </p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > The gold standard for integrating your tools </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > An open LTI app collection </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Integration tutorials and resources </span></p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/int4.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-lg-5 ">
                            <div className="col-lg-4 col-md-12">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE ALL PRODUCTS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Discover the Instructure Learning Platform </h2>
                                    <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > The Instructure Learning Platform delivers what you need to enrich, support, and connect every aspect of teaching and learning. </p>
                                    <div className='pt-4 pb-5'>
                                        <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="row">
                                    <div className="col-lg ">
                                        <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas LMS</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Course materials. Communication tools. Participation and grades. Data and insights. All part of Canvas LMS. Ready to support learning anywhere. </p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#e4060f", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg pt-5">
                                        <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms2.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} > Elevate Analytics </h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > The analytics solution that combines data from multiple sources and makes it easy for districts to put the right information into the hands of educators and administrators. </p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#0097d3", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                                <div className="row">
                                    <div className="col-lg ">
                                        <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms1.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Mastery by Instrture</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Drive student outcomes with actionable, standards-based insights that improve teaching and learning, leading to even more helpful insights and even better teaching and learning.</p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#24a159", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg ">
                                        <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms3.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Impact by Instructure</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Improve technology adoption, support teachers and students in their tech use, and evaluate the impact of educational technology on student engagement.</p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "#f76400", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5 ">
                        <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
                        <div className="row d-flex justify-content-center p-5">
                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px" }} src={require("../../img/ass5.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Blog Articles</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }} >Epic Charter School: Strengthening Virtual Instruction With MasteryConnect</h4>
                                        <div className='pt-lg-4'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px" }} src={require("../../img/ass6.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Blog Articles</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }}>The State of Assessment in K-12 Education: Study & Trends</h4>
                                        <div className='pt-lg-4'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px" }} src={require("../../img/ass7.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Case Studies</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "28px", fontWeight: "700" }}>A District Leader's Guide to Using Assessment Data</h4>
                                        <div className='pt-lg-5 mt-lg-3'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
                    <div className="container  p-lg-5 ">
                        <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
                            <div className='text-lg-center pb-lg-4'>
                                <h2 className='pb-2 SolutionDropDownFontFamily' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to explore our learning ecosystem? </h2>
                                <div className='pt-4 '>
                                    <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default IntegrationsWithPartnerTools