import React from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";

const Header = () => {
  document.addEventListener("DOMContentLoaded", function () {
    var dropdownBtn = document.querySelector(".dropdown-btn");
    var dropdownContent = document.querySelector(".dropdown-content");

    dropdownBtn.addEventListener("click", function () {
      if (dropdownContent.style.display === "block") {
        dropdownContent.style.display = "none";
      } else {
        dropdownContent.style.display = "block";
      }
    });
  });

  return (
    <>
      <section>
        <div
          className="container-fluid d-none d-sm-block"
          style={{ borderBottom: "1px solid rgb(204, 220, 228)" }}
        >
          <div className="container ">
            <div className="row p-1 d-flex ">
              <nav
                className="navbar navbar-expand-lg  p-0 d-flex justify-content-lg-around justify-content-lg-around align-items-center "
                style={{ fontWeight: "400" }}
              >
                <div className=" ">
                  <div className=" col-lg  ">
                    <div
                      className="text-start ms-lg-5 pt-2 col"
                      id="google_translate_element"
                    >
                      {" "}
                    </div>
                    <div className="col d-lg-block d-none"> </div>
                  </div>
                </div>

                <div
                  class="collapse navbar-collapse  ps-lg-4 ms-lg-4"
                  id="navbarNavDropdown"
                >
                  <ul class="navbar-nav  w-100 ps-lg-5 ms-lg-5 d-flex justify-content-lg-end align-items-lg-center text-lg-start">
                    <li className="pb-1">
                      <span className="p-3">
                        <i
                          class="fa-solid fa-magnifying-glass"
                          style={{ color: "red" }}
                        ></i>
                      </span>
                    </li>
                    <li className="pb-1">
                      <span
                        className="p-3"
                        style={{
                          color: "#4e6f7e",
                          fontSize: ".8125rem",
                          fontWeight: "800",
                        }}
                      >
                        Try Canvas Free
                      </span>
                    </li>
                    <li className="pb-1">
                      <span
                        className="p-3"
                        style={{
                          color: "#4e6f7e",
                          fontSize: ".8125rem",
                          fontWeight: "800",
                        }}
                      >
                        Corp Edu
                      </span>
                    </li>
                    <li className="pb-1">
                      <span
                        className="p-3"
                        style={{
                          color: "#4e6f7e",
                          fontSize: ".8125rem",
                          fontWeight: "800",
                        }}
                      >
                        Support
                      </span>
                    </li>
                    <li className="pb-1">
                      <span
                        className="p-3"
                        style={{
                          color: "#4e6f7e",
                          fontSize: ".8125rem",
                          fontWeight: "800",
                        }}
                      >
                        Contact Us
                      </span>
                    </li>
                    <li className="pb-1">
                      <button
                        className="p-2"
                        style={{
                          borderRadius: "20px 20px 20px 20px",
                          backgroundColor: "#1A6989",
                          border: "none",
                          color: "white",
                        }}
                      >
                        <b className="p-2" style={{ fontSize: "1rem" }}>
                          Login In
                        </b>
                      </button>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container ">
          <div className="row p-1 d-flex ">
            <nav
              id="navbar_top"
              className="navbar navbar-expand-lg  p-0 d-flex justify-content-lg-around justify-content-around align-items-center "
              style={{ fontWeight: "400" }}
            >
              <div className="text-lg-end">
                <NavLink class="navbar-brand text-lg-center " to="/">
                  <img
                    className=""
                    style={{ height: "6rem" }}
                    src={require("../img/HederFooterLogo.png")}
                    alt="icloudsoft_logo"
                  />
                </NavLink>
              </div>
              <button
                className="navbar-toggler "
                type="button "
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fa-solid fa-bars"></i>
              </button>

              <div
                class="collapse navbar-collapse mt-lg-3 ps-lg-4 ms-lg-4"
                id="navbarNavDropdown"
              >
                <ul class="navbar-nav  w-100 ps-lg-5 ms-lg-5 d-flex justify-content-lg-end align-items-lg-center text-lg-start">
                  <li
                    className="ps-3 pb-1 nav-items #submenu"
                    data-bs-toggle="collapse"
                    aria-current="true"
                  >
                    <span
                      className="dropdown-toggle nav-i "
                      style={{ cursor: "unset" }}
                      id="mega-one"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="true"
                    >
                      K-12
                    </span>
                    <ul
                      className="dropdown-menu collapse p-0"
                      aria-labelledby="mega-one"
                      style={{
                        zIndex: "6",
                        border: "none",
                        backgroundColor: "unset",
                      }}
                      id="submenu"
                    >
                      <li className="nav-items w-100  text-white">
                        <div className="container">
                          <div className="row">
                            <div
                              className="col-lg-8  shadow-lg"
                              style={{ backgroundColor: "#fff" }}
                            >
                              <div className="row d-flex justify-content-center ">
                                <NavLink
                                  className="text-decoration-none"
                                  to="/k12solution"
                                >
                                  <div className="dropdown-item  pb-4 pt-3  SolutionDropDownFontFamily k12HEADER">
                                    K-12 SOLUTION
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <div className="col-lg-4  mb-3">
                                  <NavLink
                                    className="text-decoration-none "
                                    to="/canavas"
                                  >
                                    <div
                                      className="dropdown-item HEADERK12 d-flex justify-content-start align-items-center"
                                      style={{ fontSize: "15px" }}
                                    >
                                      <img
                                        className=""
                                        style={{ height: "2.6rem" }}
                                        src={require("../img/icon.png")}
                                        alt=""
                                      />
                                      <b className="ps-2 ">CANVAS</b>
                                    </div>
                                    <p
                                      className="ps-lg-5 ms-lg-3"
                                      style={{
                                        fontSize: ".86rem",
                                        color: "#143d50",
                                        fontWeight: "400",
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      The hub of your digital classroom that
                                      serves as the foundation for teaching and
                                      learning.
                                    </p>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none"
                                    to="/lms"
                                  >
                                    <div className="dropdown-item  ps-lg-5 ms-lg-3 pb-2 HEADERK12">
                                      Canvas LMS
                                    </div>
                                    <div className=""></div>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none"
                                    to="/canvasstudio"
                                  >
                                    <div className="dropdown-item ps-lg-5 ms-lg-3 pb-2 HEADERK12">
                                      Canvas Studio
                                    </div>
                                    <div className=""></div>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none"
                                    to="/canvascatalog"
                                  >
                                    <div className="dropdown-item ps-lg-5 ms-lg-3 pb-2 HEADERK12">
                                      Canvas Catalog
                                    </div>
                                    <div className=""></div>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none"
                                    to="/canvascredentials"
                                  >
                                    <div className="dropdown-item ps-lg-5 ms-lg-3 pb-2 HEADERK12">
                                      Canvas Credentials
                                    </div>
                                    <div className=""></div>
                                  </NavLink>

                                  <div className="pt-3">
                                    <button className="UbuntuFONTFAMILY ps-lg-5 ms-lg-2 HEADERTRYCANVASBUTTON ">
                                      <b
                                        className="p-2"
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {" "}
                                        TRY CANVAS FREE{" "}
                                        <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i>
                                      </b>
                                    </button>
                                  </div>
                                </div>

                                <div
                                  className="col-lg-4 mb-3"
                                  style={{ fontSize: "14px" }}
                                >
                                  <NavLink
                                    className="text-decoration-none "
                                    to="/mastery"
                                  >
                                    <div
                                      className="dropdown-item HEADERK12 d-flex justify-content-start align-items-center"
                                      style={{ fontSize: "15px" }}
                                    >
                                      <img
                                        className=""
                                        style={{ height: "2.6rem" }}
                                        src={require("../img/ms1.png")}
                                        alt=""
                                      />
                                      <b className="ps-2"> MASTERY</b>
                                    </div>
                                    <p
                                      className="ps-lg-5 ms-lg-3"
                                      style={{
                                        fontSize: ".86rem",
                                        color: "#143d50",
                                        fontWeight: "400",
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      {" "}
                                      The assessment tools and quality content
                                      educators need to accelerate learning
                                      forward.
                                    </p>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none "
                                    to="/masteryconnect"
                                  >
                                    <div className="dropdown-item ps-lg-5 ms-lg-3 pb-2 HEADERK12">
                                      Mastery Connect
                                    </div>
                                    <div className=""></div>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none"
                                    to="/masteryitembank"
                                  >
                                    <div className="dropdown-item ps-lg-5 ms-lg-3 pb-2 HEADERK12">
                                      Mastery Item Bank
                                    </div>
                                    <div className=""></div>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none"
                                    to="/masteryviewformativeassessments"
                                  >
                                    <div className="dropdown-item ps-lg-5 ms-lg-3 pb-2 HEADERK12">
                                      Mastery View Formative <br /> Assessments
                                    </div>
                                    <div className=""></div>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none"
                                    to="/masteryviewpredictiveassessments"
                                  >
                                    <div className="dropdown-item ps-lg-5 ms-lg-3 pb-2 HEADERK12">
                                      Mastery View Predictive <br /> Assessments
                                    </div>
                                    <div className=""></div>
                                  </NavLink>
                                </div>

                                <div
                                  className="col-lg-4  mb-3 "
                                  style={{ fontSize: "14px" }}
                                >
                                  <NavLink
                                    className="text-decoration-none "
                                    to="/elevate"
                                  >
                                    <div
                                      className="dropdown-item HEADERK12 d-flex justify-content-start align-items-center"
                                      style={{ fontSize: "15px" }}
                                    >
                                      <img
                                        className=""
                                        style={{ height: "2.6rem" }}
                                        src={require("../img/ms2.png")}
                                        alt=""
                                      />
                                      <b className="ps-2"> ELEVATE</b>
                                    </div>
                                    <p
                                      className="ps-lg-5 ms-lg-3"
                                      style={{
                                        fontSize: ".86rem",
                                        color: "#143d50",
                                        fontWeight: "400",
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      Powerful analytical tools that provide
                                      actionable insights for making impactful
                                      decisions.
                                    </p>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none"
                                    to="/elevatek_12"
                                  >
                                    <div className="dropdown-item ps-lg-5 ms-lg-3 pb-2 HEADERK12">
                                      Elevate K-12 Analytics
                                    </div>
                                    <div className=""></div>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none"
                                    to="/elevatedataquality"
                                  >
                                    <div className="dropdown-item ps-lg-5 ms-lg-3 pb-2 HEADERK12">
                                      Elevate Data Quality
                                    </div>
                                    <div className=""></div>
                                  </NavLink>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-lg-4"
                              style={{ backgroundColor: "#E0EAF0" }}
                            >
                              <div className="pt-4 ps-lg-5 ms-lg-3">
                                <h2
                                  className="ps-lg-3"
                                  style={{
                                    fontSize: ".875rem",
                                    fontWeight: "800",
                                    color: "#143d50",
                                    lineHeight: "1.5",
                                  }}
                                >
                                  SOLUTIONS BY NEED
                                </h2>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/learningmanagement"
                                >
                                  <div className="dropdown-item  pb-2 SOLHEADER">
                                    {" "}
                                    Learning Management
                                  </div>

                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/assessment"
                                >
                                  <div className="dropdown-item pb-2 SOLHEADER">
                                    Assessment
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/content"
                                >
                                  <div className="dropdown-item pb-2 SOLHEADER">
                                    Content
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/onlineprograms"
                                >
                                  <div className="dropdown-item pb-2 SOLHEADER">
                                    Online Programs
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/analytics"
                                >
                                  <div className="dropdown-item pb-2 SOLHEADER">
                                    Analytics
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/professionaldevelopment"
                                >
                                  <div className="dropdown-item pb-2 SOLHEADER">
                                    Professional Development
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/integrationswithpartnertools"
                                >
                                  <div className="dropdown-item SOLHEADER">
                                    Integrations With Partner Tools
                                  </div>
                                  <div className=""></div>
                                </NavLink>
                              </div>

                              <div className="pt-4 pb-4 ps-lg-5 ms-lg-3">
                                <h2
                                  className="ps-lg-3"
                                  style={{
                                    fontSize: ".875rem",
                                    fontWeight: "800",
                                    color: "#143d50",
                                    lineHeight: "1.5",
                                  }}
                                >
                                  SOLUTIONS BY ROLE
                                </h2>
                                <NavLink
                                  className="text-decoration-none"
                                  to="/foradministrators"
                                >
                                  <div className="dropdown-item pb-2 SOLHEADER">
                                    For Administrators
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/forittechnologists"
                                >
                                  <div className="dropdown-item pb-2 SOLHEADER">
                                    For IT/Technologists
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/foreducators"
                                >
                                  <div className="dropdown-item pb-2 SOLHEADER">
                                    For Educators
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/forstudentsparents"
                                >
                                  <div className="dropdown-item pb-2 SOLHEADER">
                                    For Students & Parents
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/wifi-access-point"
                                >
                                  <div className="dropdown-item pb-2 SOLHEADER">
                                    Analytics
                                  </div>
                                  <div className=""></div>
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="ps-3 pb-1 nav-items #submenu"
                    data-bs-toggle="collapse"
                    aria-current="true"
                  >
                    <span
                      className="dropdown-toggle nav-i "
                      style={{ cursor: "unset" }}
                      id="mega-one"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="true"
                    >
                      HIGHER EDUCATION
                    </span>
                    <ul
                      className="dropdown-menu  collapse h-100 p-0"
                      aria-labelledby="mega-one"
                      style={{
                        zIndex: "6",
                        border: "none",
                        backgroundColor: "unset",
                      }}
                      id="submenu"
                    >
                      <li className="nav-items  text-white">
                        <div className="container ">
                          <div className="row  d-flex justify-content-end ">
                            <div
                              className="col-lg-6 p-lg-3 shadow-lg"
                              style={{ backgroundColor: "#fff" }}
                            >
                              <div className="row d-flex justify-content-center ">
                                <NavLink
                                  className="text-decoration-none"
                                  to="/Networksolution"
                                >
                                  <div className="dropdown-item text-navy ps-lg-5 ms-lg-3 pb-4 fw-bold k12HIGHEREDUCATIONHEADER">
                                    HIGHER EDUCATION SOLUTIONS
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <div
                                  className="col-lg-5 mb-3 "
                                  style={{ fontSize: "14px" }}
                                >
                                  <NavLink
                                    className="text-decoration-none "
                                    to="/highcanavas"
                                  >
                                    <div
                                      className="dropdown-item HEADERK12 d-flex justify-content-start align-items-center"
                                      style={{ fontSize: "15px" }}
                                    >
                                      <img
                                        className=""
                                        style={{ height: "2.6rem" }}
                                        src={require("../img/icon.png")}
                                        alt=""
                                      />
                                      <b className="ps-2"> CANVAS </b>
                                    </div>
                                    <p
                                      className="ps-lg-5 ms-lg-3"
                                      style={{
                                        fontSize: ".75rem",
                                        color: "#4e6f7e",
                                        fontWeight: "400",
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      {" "}
                                      Modern tools that elevate the higher ed
                                      experience whether virtual, hybrid, or
                                      in-person.{" "}
                                    </p>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none"
                                    to="/highcanavaslms"
                                  >
                                    <div className="dropdown-item  ps-lg-5 ms-lg-3 pb-1 HEADERKHIGHEREDUCATION">
                                      {" "}
                                      Canvas LMS{" "}
                                    </div>
                                    <div className=""></div>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none"
                                    to="/highcanavasstudio"
                                  >
                                    <div className="dropdown-item ps-lg-5 ms-lg-3 pb-1 HEADERKHIGHEREDUCATION">
                                      {" "}
                                      Canvas Studio{" "}
                                    </div>
                                    <div className=""></div>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none"
                                    to="/highcanavascatalog"
                                  >
                                    <div className="dropdown-item ps-lg-5 ms-lg-3 pb-1 HEADERKHIGHEREDUCATION">
                                      {" "}
                                      Canvas Catalog{" "}
                                    </div>
                                    <div className=""></div>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none"
                                    to="/highcanvascredentials"
                                  >
                                    <div className="dropdown-item ps-lg-5 ms-lg-3 pb-1 HEADERKHIGHEREDUCATION">
                                      {" "}
                                      Canvas Credentials{" "}
                                    </div>
                                    <div className=""></div>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none"
                                    to="/highcanvasstudentpathways"
                                  >
                                    <div className="dropdown-item ps-lg-5 ms-lg-3 pb-1 HEADERKHIGHEREDUCATION">
                                      {" "}
                                      Canvas Student Pathways{" "}
                                    </div>
                                    <div className=""></div>
                                  </NavLink>

                                  <div className="pt-3">
                                    <button className="UbuntuFONTFAMILY ps-lg-5 ms-lg-2  HEADERTRYCANVASBUTTONHEADERKHIGHEREDUCATION">
                                      <b
                                        className="p-2"
                                        style={{
                                          fontSize: ".875rem",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {" "}
                                        TRY CANVAS FREE{" "}
                                        <i class="ms-1 fa-sharp fa-solid fa-arrow-right"></i>
                                      </b>
                                    </button>
                                  </div>
                                </div>

                                <div className="col-lg-5 mb-3">
                                  <NavLink
                                    className="text-decoration-none "
                                    to="/canavas"
                                  >
                                    <div
                                      className="dropdown-item HEADERK12 d-flex justify-content-start align-items-center"
                                      style={{ fontSize: "15px" }}
                                    >
                                      <img
                                        className=""
                                        style={{ height: "2.6rem" }}
                                        src={require("../img/ms3.png")}
                                        alt=""
                                      />
                                      <b className="ps-2"> Impact </b>
                                    </div>
                                    <p
                                      className="ps-lg-5 ms-lg-3 pb-4"
                                      style={{
                                        fontSize: ".75rem",
                                        color: "#4e6f7e",
                                        fontWeight: "400",
                                        lineHeight: "1.5",
                                        borderBottom: "1px solid #4e6f7e",
                                      }}
                                    >
                                      {" "}
                                      Data-driven support and communication
                                      solution to maximize the use of campus
                                      technology.{" "}
                                    </p>
                                  </NavLink>

                                  <NavLink
                                    className="text-decoration-none "
                                    to="/canavas"
                                  >
                                    <b className="ps-3 HEADERK123">
                                      {" "}
                                      Services{" "}
                                    </b>
                                    <p
                                      className="ps-3"
                                      style={{
                                        fontSize: ".75rem",
                                        color: "#4e6f7e",
                                        fontWeight: "400",
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      {" "}
                                      We offer rollout plans, product training,
                                      content development, and more.{" "}
                                    </p>
                                  </NavLink>

                                  <div className="pt-2 ps-2">
                                    <button
                                      className=""
                                      style={{
                                        borderRadius: "20px 20px 20px 20px",
                                        backgroundColor: "#1D6C8C",
                                        border: "none",
                                        color: "white",
                                        padding: "10px 15px",
                                      }}
                                    >
                                      <b
                                        className="p-3"
                                        style={{ fontSize: "1rem" }}
                                      >
                                        All Products
                                      </b>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-lg-4 p-lg-3"
                              style={{ backgroundColor: "#E0EAF0" }}
                            >
                              <div className="pt-4 ps-lg-5 ms-lg-3">
                                <h2
                                  className="ps-lg-3"
                                  style={{
                                    fontSize: ".875rem",
                                    fontWeight: "800",
                                    color: "#143d50",
                                    lineHeight: "1.5",
                                  }}
                                >
                                  SOLUTIONS BY NEED
                                </h2>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/learningmanagement"
                                >
                                  <div className="dropdown-item  pb-1 SOLHEADER1">
                                    {" "}
                                    Learning Management
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/assessment"
                                >
                                  <div className="dropdown-item pb-1 SOLHEADER1">
                                    Assessment
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/content"
                                >
                                  <div className="dropdown-item pb-1 SOLHEADER1">
                                    Content
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/onlineprograms"
                                >
                                  <div className="dropdown-item pb-1 SOLHEADER1">
                                    Online Programs
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/analytics"
                                >
                                  <div className="dropdown-item pb-1 SOLHEADER1">
                                    Analytics
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/professionaldevelopment"
                                >
                                  <div className="dropdown-item pb-1 SOLHEADER1">
                                    Professional Development
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/integrationswithpartnertools"
                                >
                                  <div className="dropdown-item SOLHEADER1">
                                    Integrations With Partner Tools
                                  </div>
                                  <div className=""></div>
                                </NavLink>
                              </div>

                              <div className="pt-2 pb-4 ps-lg-5 ms-lg-3">
                                <h2
                                  className="ps-lg-3"
                                  style={{
                                    fontSize: ".875rem",
                                    fontWeight: "800",
                                    color: "#143d50",
                                    lineHeight: "1.5",
                                  }}
                                >
                                  SOLUTIONS BY ROLE
                                </h2>
                                <NavLink
                                  className="text-decoration-none"
                                  to="/foradministrators"
                                >
                                  <div className="dropdown-item pb-2 SOLHEADER1">
                                    For Administrators
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/forittechnologists"
                                >
                                  <div className="dropdown-item pb-1 SOLHEADER1">
                                    For IT/Technologists
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/foreducators"
                                >
                                  <div className="dropdown-item pb-1 SOLHEADER1">
                                    For Educators
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/forstudentsparents"
                                >
                                  <div className="dropdown-item pb-1 SOLHEADER1">
                                    For Students & Parents
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/wifi-access-point"
                                >
                                  <div className="dropdown-item pb-1 SOLHEADER1">
                                    Analytics
                                  </div>
                                  <div className=""></div>
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="ps-3 pb-1 nav-items #submenu"
                    data-bs-toggle="collapse"
                    aria-current="true"
                  >
                    <span
                      className="dropdown-toggle nav-i"
                      style={{ cursor: "unset" }}
                      id="mega-one"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="true"
                    >
                      RESOURCES{" "}
                    </span>
                    <ul
                      className="dropdown-menu collapse h-100 p-0"
                      aria-labelledby="mega-one"
                      data-bs-popper="static"
                      style={{
                        border: "none",
                        backgroundColor: "unset",
                      }}
                      id="submenu"
                    >
                      <li className="nav-items text-white">
                        <div className="container">
                          <div className="row d-flex justify-content-end ">
                            <div className="col-lg-6 p-lg-3 shadow-lg bg-white">
                              <div className="row d-flex justify-content-center">
                                <ul
                                  className="col-lg-6"
                                  style={{ listStyle: "none" }}
                                >
                                  <div className="dropdown-item text-navy pb-0 mb-2 center">
                                    <span className="">centers</span>
                                  </div>
                                  <li className="d-flex flex-column w-100">
                                    <NavLink
                                      className="text-decoration-none"
                                      to="/resources"
                                      style={{
                                        fontWeight: "boldh",
                                        color: "#143d50",
                                      }}
                                    >
                                      <div
                                        className="dropdown-item SOLHEADER1 "
                                        style={{ fontWeight: "600" }}
                                      >
                                        The Study Hall
                                      </div>
                                    </NavLink>
                                    <div className="ps-2 ms-2 text-dark">
                                      <p
                                        style={{
                                          fontSize: ".75rem",
                                          color: "#143d50",
                                          fontWeight: "400",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        Articles, videos, stories, and news
                                        about our products, edtech, and
                                        education.
                                      </p>
                                    </div>
                                  </li>
                                  <li className="d-flex flex-column w-100">
                                    <NavLink
                                      className="text-decoration-none"
                                      to="/community"
                                      style={{
                                        fontWeight: "600",
                                        color: "#143d50",
                                      }}
                                    >
                                      <div
                                        className="dropdown-item SOLHEADER1"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Our Community
                                      </div>
                                    </NavLink>
                                    <div className="ps-2 ms-2 text-dark">
                                      <p
                                        style={{
                                          fontSize: ".75rem",
                                          color: "#143d50",
                                          fontWeight: "400",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        Share ideas, ask and answer questions,
                                        access guides, and connect with peers.
                                      </p>
                                    </div>
                                  </li>
                                  <li className="d-flex flex-column w-100">
                                    <NavLink
                                      className="ps-2 ms-2 text-decoration-none Resource-title"
                                      style={{
                                        fontWeight: "600",
                                        color: "#143d50",
                                      }}
                                    >
                                      Canvas Network
                                    </NavLink>
                                    <div className="ps-2 ms-2 text-dark">
                                      <p
                                        style={{
                                          fontSize: ".75rem",
                                          color: "#143d50",
                                          fontWeight: "600",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        Modify and use online courses from
                                        educators and institutions worldwide.
                                      </p>
                                    </div>
                                  </li>
                                  <li className="d-flex flex-column w-100">
                                    <NavLink
                                      className="ps-2 ms-2 text-decoration-none Resource-title"
                                      style={{
                                        fontWeight: "600",
                                        color: "#143d50",
                                      }}
                                    >
                                      Integrations
                                    </NavLink>
                                    <div className="ps-2 ms-2 text-dark">
                                      <p
                                        style={{
                                          fontSize: ".75rem",
                                          color: "#143d50",
                                          fontWeight: "400",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        See how we integrate with your SIS,
                                        custom apps, and hundreds of external
                                        edu apps.
                                      </p>
                                    </div>
                                  </li>

                                  <div className=""></div>
                                </ul>

                                <ul
                                  className="col-lg-6"
                                  style={{ listStyle: "none" }}
                                >
                                  <div
                                    className="dropdown-item text-navy pb-0 mb-2 "
                                    style={{
                                      fontSize: ".875rem",
                                      color: "#143d50",
                                      marginTop: "10px",
                                      textTransform: "uppercase",
                                      fontWeight: "700",
                                      display: "block",
                                      letterSpacing: ".12em",
                                    }}
                                  >
                                    <span>By Type</span>
                                  </div>
                                  <li className="d-flex flex-column w-100">
                                    <NavLink
                                      className="text-decoration-none"
                                      to="/articles"
                                      style={{
                                        fontWeight: "bold",
                                        color: "#143d50",
                                      }}
                                    >
                                      <div
                                        className="dropdown-item SOLHEADER1"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Articles
                                      </div>
                                    </NavLink>
                                  </li>
                                  <li className="d-flex flex-column w-100">
                                    <NavLink
                                      className=" text-decoration-none "
                                      to="/casestudies"
                                      style={{
                                        color: "#143d50",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <div
                                        className="dropdown-item SOLHEADER1"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Case Studies
                                      </div>
                                    </NavLink>
                                  </li>
                                  <li
                                    className="d-flex flex-column"
                                    style={{ width: "100%" }}
                                  >
                                    <NavLink
                                      className="text-decoration-none"
                                      to="/ebooks"
                                      style={{
                                        color: "#143d50",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <div
                                        className="dropdown-item SOLHEADER1"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Ebooks & Buyer’s Guides
                                      </div>
                                    </NavLink>
                                  </li>
                                  <li className="d-flex flex-column w-100">
                                    <NavLink
                                      className="text-decoration-none"
                                      to="/research"
                                      style={{
                                        color: "#143d50",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <div
                                        className="dropdown-item SOLHEADER1"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Research
                                      </div>
                                    </NavLink>
                                  </li>
                                  <li className="d-flex flex-column w-100">
                                    <NavLink
                                      className="text-decoration-none"
                                      to="/videos"
                                      style={{
                                        color: "#143d50",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <div
                                        className="dropdown-item SOLHEADER1"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Videos
                                      </div>
                                    </NavLink>
                                  </li>
                                  <li className="d-flex flex-column w-100">
                                    <NavLink
                                      className="text-decoration-none"
                                      to="/infographic"
                                      style={{
                                        color: "#143d50",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <div
                                        className="dropdown-item SOLHEADER1"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Infographics
                                      </div>
                                    </NavLink>
                                  </li>
                                  <li className="d-flex flex-column w-100">
                                    <NavLink
                                      className="text-decoration-none"
                                      to="/webinars"
                                      style={{
                                        color: "#143d50",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <div
                                        className="dropdown-item SOLHEADER1"
                                        style={{ fontWeight: "600" }}
                                      >
                                        On-Demand Webinars
                                      </div>
                                    </NavLink>
                                  </li>
                                  <li className="d-flex flex-column w-100">
                                    <NavLink
                                      className=" text-decoration-none"
                                      to="/airesources"
                                      style={{
                                        color: "#143d50",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <div
                                        className="dropdown-item SOLHEADER1"
                                        style={{ fontWeight: "600" }}
                                      >
                                        AI Resources
                                      </div>
                                    </NavLink>
                                  </li>
                                  <li className="d-flex flex-column w-100 pt-2">
                                    <NavLink
                                      className=" text-decoration-none"
                                      to="/resources"
                                    >
                                      <div className="dropdown-item SOLHEADER1">
                                        <button
                                          type="button"
                                          className="btn btn-link  text-uppercase"
                                          style={{
                                            textDecoration: "none",
                                            color: "red",
                                            fontWeight: 700,
                                            padding: "0px",
                                          }}
                                        >
                                          {" "}
                                          All Resources
                                          <i className="ms-3 fa-sharp fa-solid fa-arrow-right"></i>
                                        </button>
                                      </div>
                                    </NavLink>
                                  </li>

                                  <div className=""></div>
                                </ul>
                              </div>
                            </div>

                            <div className="col-lg-5 MainWidth  p-lg-4  ">
                              <div className="resource-col">
                                <img
                                  className="w-100"
                                  style={{
                                    borderRadius: "10px 10px 0px 0px",
                                    verticalAlign: "middle",
                                  }}
                                  alt="icloudsoft-logo"
                                  src={require("../img/Resources.jpg")}
                                ></img>
                              </div>

                              <div className="pt-lg-5 bg-white d-flex flex-column justify-content-lg-center ps-3 me-lg-5 w-100 h-auto rounded-3">
                                <h2
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "800",
                                    color: "#1D6C8C",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  Global Research Report
                                </h2>

                                <h4 style={{ color: "#156380" }}>
                                  <p>
                                    2023 State of Student Success and Engagement
                                    in Higher Education
                                  </p>
                                </h4>

                                <NavLink className="active" to="/">
                                  <button
                                    type="button"
                                    className="btn btn-link p-0 mt-0 text-uppercase text-decoration-none"
                                    style={{
                                      color: "red",
                                      fontWeight: 700,
                                      marginBottom: "35px",
                                    }}
                                  >
                                    Learn More{" "}
                                    <i className="ms-3 fa-sharp fa-solid fa-arrow-right"></i>
                                  </button>

                                  <div className=""></div>
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="ps-3 pb-1 nav-items #submenu"
                    data-bs-toggle="collapse"
                    aria-current="true"
                  >
                    <span
                      className="dropdown-toggle nav-i "
                      style={{ cursor: "unset" }}
                      id="mega-one"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="true"
                    >
                      NEWS & EVENTS{" "}
                    </span>
                    <ul
                      className="dropdown-menu collapse h-100 p-0  ps-lg-5 ms-lg-5 "
                      aria-labelledby="mega-one"
                      style={{
                        zIndex: "6",
                        border: "none",
                        backgroundColor: "unset",
                      }}
                      id="submenu"
                    >
                      <li className="nav-items  text-white ps-lg-5 ms-lg-5  ">
                        <div className="container ps-lg-5 ms-lg-5">
                          <div className="row d-flex justify-content-end ps-lg-5 ms-lg-5 ">
                            <div
                              className="col-lg-5 p-lg-5 shadow-lg"
                              style={{ backgroundColor: "#fff" }}
                            >
                              <div className="row d-flex justify-content-start ">
                                <div className="news-title-head">
                                  <div
                                    className="text-navy ps-3 ms-3 fw-bold"
                                    style={{
                                      fontSize: "18px",
                                      color: "#1B6A89",
                                      fontWeight: "800",
                                    }}
                                  >
                                    EVENTS
                                  </div>
                                </div>

                                <div
                                  className=" "
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  <div className=" ">
                                    <NavLink
                                      className="text-decoration-none"
                                      to="/events"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Upcoming Events
                                      </div>
                                      <div className=""></div>
                                    </NavLink>

                                    <NavLink
                                      className="text-decoration-none"
                                      to="/instructurecon"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        InstructureCon 2024
                                      </div>
                                      <div className=""></div>
                                    </NavLink>

                                    <NavLink
                                      className="text-decoration-none"
                                      to="/Firewall"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        InstructureCon 2023
                                      </div>
                                      <div className=""></div>
                                    </NavLink>

                                    <NavLink
                                      className="text-decoration-none"
                                      to="/wifi-access-point"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Panda Pros
                                      </div>
                                      <div className=""></div>
                                    </NavLink>

                                    <NavLink
                                      className="text-decoration-none"
                                      to="/webinars"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Webinars
                                      </div>
                                      <div className=""></div>
                                    </NavLink>
                                  </div>
                                </div>

                                <div className="news-title-head">
                                  <div
                                    className=" text-navy pt-3 ps-3 ms-3 fw-bold"
                                    style={{
                                      fontSize: "18px",
                                      color: "#1B6A89",
                                      fontWeight: "800",
                                    }}
                                  >
                                    NEWS
                                  </div>
                                </div>

                                <div className=" " style={{ fontSize: "14px" }}>
                                  <div className=" ">
                                    <NavLink
                                      className="text-decoration-none"
                                      to="/Switches"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Latest News
                                      </div>
                                      <div className=""></div>
                                    </NavLink>

                                    <NavLink
                                      className="text-decoration-none"
                                      to="/Switches"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Media Resources
                                      </div>
                                      <div className=""></div>
                                    </NavLink>

                                    <NavLink
                                      className="text-decoration-none"
                                      to="/Firewall"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Brand Guide
                                      </div>
                                      <div className=""></div>
                                    </NavLink>

                                    <NavLink
                                      className="text-decoration-none"
                                      to="/Firewall"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Press Releases
                                      </div>
                                      <div className=""></div>
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-lg-5 p-lg-4 "
                              style={{ backgroundColor: "#E0EAF0" }}
                            >
                              <img
                                className=" w-100"
                                style={{ borderRadius: "10px 10px 0px 0px" }}
                                src={require("../img/demo.png")}
                                alt="icloudsoft_logo"
                              />

                              <div
                                className=" shadow-lg p-3"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <NavLink
                                  className="text-decoration-none"
                                  to="/Firewall"
                                >
                                  <div
                                    className="dropdown-item ps-3 ms-3"
                                    style={{
                                      fontSize: "14px",
                                      color: "#1B6A89",
                                      fontWeight: "600",
                                    }}
                                  >
                                    ON-DEMAND
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/wifi-access-point"
                                >
                                  <div
                                    className="dropdown-item ps-3 ms-3"
                                    style={{
                                      fontSize: "14px",
                                      color: "#1B6A89",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Relive
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/wifi-access-point"
                                >
                                  <div
                                    className="dropdown-item ps-3 ms-3"
                                    style={{
                                      fontSize: "14px",
                                      color: "#1B6A89",
                                      fontWeight: "600",
                                    }}
                                  >
                                    InstructureCon
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/wifi-access-point"
                                >
                                  <div
                                    className="dropdown-item ps-3 ms-3"
                                    style={{
                                      fontSize: "14px",
                                      color: "#1B6A89",
                                      fontWeight: "600",
                                    }}
                                  >
                                    2023
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <div className="">
                                  <NavLink className="" to="/">
                                    <button
                                      type="button"
                                      class="btn btn-link ps-3 ms-3 text-decoration-none text-uppercase "
                                      style={{
                                        color: "red",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Learn More{" "}
                                      <i class="ms-3 fa-sharp fa-solid fa-arrow-right "></i>
                                    </button>
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="ps-3 pb-1 nav-items #submenu"
                    data-bs-toggle="collapse"
                    aria-current="true"
                  >
                    <span
                      className="dropdown-toggle nav-i "
                      style={{ cursor: "unset" }}
                      id="mega-one"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="true"
                    >
                      {" "}
                      ABOUT US{" "}
                    </span>
                    <ul
                      className="dropdown-menu collapse h-100 p-0 ps-lg-5 ms-lg-5 "
                      aria-labelledby="mega-one"
                      style={{
                        zIndex: "6",
                        border: "none",
                        backgroundColor: "unset",
                      }}
                      id="submenu"
                    >
                      <li className="nav-items  text-white ps-lg-5 ms-lg-5  ">
                        <div className="container ps-lg-5 ms-lg-5">
                          <div className="row d-flex justify-content-end ps-lg-5 ms-lg-5 ">
                            <div
                              className="col-lg-4 p-lg-2 shadow-lg"
                              style={{
                                backgroundColor: "#fff",
                              }}
                            >
                              <div
                                className="row d-flex justify-content-center mt-2"
                                style={{ paddingBottom: "15vh" }}
                              >
                                <NavLink
                                  className="text-decoration-none"
                                  to="/Networksolution"
                                >
                                  <div
                                    className="dropdown-item ps-3 ms-3"
                                    style={{
                                      fontSize: ".875rem",
                                      color: "#1B6A89",
                                      fontWeight: "800",
                                    }}
                                  >
                                    THE COMPANY
                                  </div>
                                </NavLink>

                                <div className=" " style={{ fontSize: "14px" }}>
                                  <div className=" ">
                                    <NavLink
                                      className="text-decoration-none"
                                      to="/about"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        About Us
                                      </div>
                                      <div className=""></div>
                                    </NavLink>

                                    <NavLink
                                      className="text-decoration-none"
                                      to="/our-story"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Our Story
                                      </div>
                                      <div className=""></div>
                                    </NavLink>

                                    <NavLink
                                      className="text-decoration-none"
                                      to="/Firewall"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Leadership
                                      </div>
                                      <div className=""></div>
                                    </NavLink>

                                    <NavLink
                                      className="text-decoration-none"
                                      to="/wifi-access-point"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Careers
                                      </div>
                                      <div className=""></div>
                                    </NavLink>

                                    <NavLink
                                      className="text-decoration-none"
                                      to="/wifi-access-point"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Contact Us
                                      </div>
                                      <div className=""></div>
                                    </NavLink>
                                  </div>
                                </div>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/Networksolution"
                                >
                                  <div
                                    className="dropdown-item pt-3 ps-3 ms-3"
                                    style={{
                                      fontSize: ".875rem",
                                      color: "#1B6A89",
                                      fontWeight: "800",
                                    }}
                                  >
                                    PRIVACY
                                  </div>
                                </NavLink>

                                <div className=" " style={{ fontSize: "14px" }}>
                                  <div className=" ">
                                    <NavLink
                                      className="text-decoration-none"
                                      to="/Switches"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Privacy Center
                                      </div>
                                      <div className=""></div>
                                    </NavLink>

                                    <NavLink
                                      className="text-decoration-none"
                                      to="/Firewall"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Security
                                      </div>
                                      <div className=""></div>
                                    </NavLink>
                                    <NavLink
                                      className="text-decoration-none"
                                      to="/Firewall"
                                    >
                                      <div
                                        className="dropdown-item ps-3 ms-3"
                                        style={{
                                          fontSize: "14px",
                                          color: "#1B6A89",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Accesibility
                                      </div>
                                      <div className=""></div>
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-lg-5 p-lg-2 "
                              style={{ backgroundColor: "#f2f8fa" }}
                            >
                              <div className="comp-col ">
                                <NavLink
                                  className="text-decoration-none"
                                  to="/Firewall"
                                >
                                  <div
                                    className="dropdown-item pt-3"
                                    style={{
                                      fontSize: ".875rem",
                                      color: "#1B6A89",
                                      fontWeight: "800",
                                    }}
                                  >
                                    THE EDTECH COLLECTIVE
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/Firewall"
                                >
                                  <div
                                    className="dropdown-item ps-3"
                                    style={{
                                      fontSize: "14px",
                                      color: "#1B6A89",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Our Partner Ecosystem
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/Firewall"
                                >
                                  <div
                                    className="dropdown-item ps-3"
                                    style={{
                                      fontSize: "14px",
                                      color: "#1B6A89",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Become a Partner
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/Firewall"
                                >
                                  <div
                                    className="dropdown-item ps-3"
                                    style={{
                                      fontSize: "14px",
                                      color: "#1B6A89",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Channel Partner
                                  </div>
                                  <div className=""></div>
                                </NavLink>

                                <NavLink
                                  className="text-decoration-none"
                                  to="/Firewall"
                                >
                                  <div
                                    className="dropdown-item ps-3"
                                    style={{
                                      fontSize: "14px",
                                      color: "#1B6A89",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Evidence as a Service
                                  </div>
                                  <div className=""></div>
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li className="ps-3 pb-1 nav-items #submenu">
                    <button
                      className=""
                      style={{
                        borderRadius: "20px 20px 20px 20px",
                        backgroundColor: "red",
                        border: "none",
                        color: "white",
                        padding: "10px 15px",
                      }}
                    >
                      <b className="p-2" style={{ fontSize: "1rem" }}>
                        GET A DEMO
                      </b>
                    </button>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
