import React, { useState } from 'react'

const HighCanvasStudio = () => {

  const [change1, setChange1] = useState(false);
  const [change2, setChange2] = useState(false);
  const [change3, setChange3] = useState(false);
  const [change4, setChange4] = useState(false);
  const [change5, setChange5] = useState(false);


  const clickMe1 = () => {
    setChange1(!change1)
    setChange2(false)
    setChange3(false)
    setChange4(false)
    setChange5(false)
  }
  const clickMe2 = () => {
    setChange1(false)
    setChange2(!change2)
    setChange3(false)
    setChange4(false)
    setChange5(false)
  }
  const clickMe3 = () => {
    setChange1(false)
    setChange2(false)
    setChange3(!change3)
    setChange4(false)
    setChange5(false)
  }

  const clickMe4 = () => {
    setChange1(false)
    setChange2(false)
    setChange3(false)
    setChange4(!change4)
    setChange5(false)
  }

  const clickMe5 = () => {
    setChange1(false)
    setChange2(false)
    setChange3(false)
    setChange4(false)
    setChange5(!change5)
  }

  return (
    <>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#e4060f" }} >
          <div className="container p-2">
            <div className="row p-1 text-white d-flex justify-content-between">
              <div className='col-lg-2'>
                <h5 className='ps-2' style={{ fontSize: "1.325rem", fontWeight: "600", letterSpacing: "2px" }} >CANVAS</h5>
              </div>
              <div className="col-lg-3">
                <div className='ps-1'>
                  <h5 className='' style={{ fontSize: "1.125rem", fontWeight: "600" }} > Higher Education Canvas Studio </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container p-lg-5">
          <div className="row p-lg-5  d-flex justify-content-around ">
            <div className="col-lg-5">
              <div>
                <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > LIGHTS. CAMERA. INTERACTION. </p>
                <h2 className='h1FontFamily' style={{ fontSize: "3.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Canvas Studio: <span className='' style={{ color: "#e4060f", letterSpacing: "1px", fontWeight: "800" }}> Engaging Higher Education. </span></h2>
              </div>
            </div>
            <div className="col-lg-5">
              <p className='mb-5' style={{ fontSize: "1.2rem", color: "#143d50" }}> Canvas Studio is the next-generation video education platform for higher ed teaching and learning. </p>
              <div className='pt-4 '>
                <button className='SolutionDropDownFontFamily' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S CONNECT  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row p-5 pt-5 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Break the fourth wall of video learning. </h2>
                  <p className='pt-3' style={{ color: "#143d50", fontSize: "1.1rem", }} > Canvas Studio turns passive viewing into active discussion between you and your higher ed students, whether you're in class, remote, or blended. Maybe it's more of a round table than a platform. </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className='p-2'>
                  <video autoPlay loop muted className='w-100 d-none d-sm-block ' style={{ height: '270px', objectFit: "fill", borderRadius: "10px 10px 10px 10px" }}  >
                    <source src={require("../../img/demo1.mp4")} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container mt-5 mb-5 p-4">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-5 p-0">
                <img className='imgRESOPNSIVE' src={require("../../img/HighCanvasSTUDIO1.png")} alt='' />
                <div className='ps-lg-5 pt-5 ms-lg-2'>
                  <p className='fontFamilyHeader' style={{ fontSize: "1.55rem", color: '#287a9f', fontWeight: "800", lineHeight: "1.3" }} > " Our instructors find it very useful to have so many capabilities in one platform. They can connect with students, share videos, share a screen, do demonstrations." </p>
                  <h6 className='fontFamilyHeader pt-2' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "800" }} > — Judith Lea </h6>
                  <h6 className='fontFamilyHeader' style={{ fontSize: "1.13rem", color: '#287a9f', fontWeight: "400" }}> Director of Distance Education, Coastal Alabama Community College, AL </h6>
                </div>
              </div>
              <div className="col-lg-6  shadow-lg PLUS-LARGE"  >
                <h2 className='ps-5 ms-4 pt-4 h1FontFamily mt-5' style={{ color: "#156380", fontSize: "2.5rem", lineHeight: "1.2" }} > Canvas Studio's A-list features: </h2>
                <div className="table p-4 d-flex justify-content-center">
                  <div className='w-100 ' >
                    <div className='pt-1'  >
                      <div className='w-100 p-0'  >
                        <button className='text-start bg-body  w-100  p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe1() }}> <h3 className='fs-5'> <b>{change1 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span>  <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className=' ps-3' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >  Your Own Media Library </span></b></h3></button>
                        <div className=''>
                          {
                            change1 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Create, deliver, host, and organize video content in all major formats—in your own media library. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe2() }}><h3 className='fs-5'> <b> {change2 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-3' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Easy Uploads & Imports </span> </b></h3></button>
                        <div >
                          {
                            change2 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Both students and instructors can upload video from their devices (or add it from YouTube) straight to their courses. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start  w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe3() }}> <h3 className='fs-5'><b> {change3 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i></span> : <span> <i className="fa-solid fa-plus " style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className='ps-3' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > A Better View of Viewership </span> </b></h3></button>
                        <div >
                          {
                            change3 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > See who’s watched a video, for how long, and which parts. What's interesting/boring/confusing your students? </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe4() }}><h3 className='fs-5'> <b> {change4 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-3' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Collaborative Integrations </span> </b></h3></button>
                        <div >
                          {
                            change4 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Easily connect with platforms like Zoom, Microsoft Teams, and Google Hangouts. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='' >
                      <div className='w-100 p-0' >
                        <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe5() }}><h3 className='fs-5'> <b> {change5 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-3' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Better Professional Development </span> </b></h3></button>
                        <div >
                          {
                            change5 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Record or import video. Share it with your fellow educators. Receive and respond to feedback. Practice. Even interact via video. </p> : <section>  </section>
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-lg-4">
            <div className="row p-lg-5 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > ACTIVE LEARNING </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Video learning that (!) involves the learner. </h2>
                  <p className='pt-2 pb-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Canvas Studio's intuitive tools let you create and deliver interactive learning experiences that can hold students’ attention in a world of distraction. Students can also submit their own video creations in Studio and offer feedback on each other's work. Engagement all around. </p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Video learning anywhere, anytime</span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Easy video collaboration</span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} >Stream across platforms and devices</span></p>
                </div>
              </div>
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/HighCanvasSTUDIO2.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-lg-5">
            <div className="row p-lg-5 d-flex justify-content-around ">

              <div className="col-lg-5">
                <img className='w-100 mt-3' src={require("../../img/HighCanvasSTUDIO3.png")} alt='' />
                <div className=''>
                  <p className='fontFamilyHeader' style={{ fontSize: "1.40rem", color: '#287a9f', fontWeight: "800", lineHeight: "1.4" }} > " Not only can I see who watched a video, I can see how long they watched it. It provides me insights so I can know how this video is being used. " </p>
                  <h6 className='fontFamilyHeader pt-2' style={{ fontSize: "1.1rem", color: '#287a9f', fontWeight: "800" }} > — Alan Kinsey </h6>
                  <h6 className='fontFamilyHeader' style={{ fontSize: "1.1rem", color: '#287a9f', fontWeight: "400" }}> Instructional Designer, Germanna Community Colleges, Virginia </h6>
                </div>
              </div>

              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > GAUGE UNDERSTANDING </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Quizzes and grading, baked right in. </h2>
                  <p className='pt-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Canvas Studio makes it easy to incorporate instruction, assessments, and additional learning opportunities right within the video experience. </p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.24rem", fontWeight: "600" }} > Create video quizzes and add questions to the <span className='ps-4 ms-2'> timeline; have students answer them as they </span>  <span className='ps-4 ms-2'> watch. </span>  </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.24rem", fontWeight: "600" }} > Give students instant feedback on their <span className='ps-4 ms-2'> responses, and submit their grades to the </span>  <span className='ps-4 ms-2'> Gradebook. </span>  </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.1rem", fontWeight: "600" }} > Watch student-submitted videos and offer inline <span className='ps-4 ms-2'> comments. </span>  </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.1rem", fontWeight: "600" }} > Apply rubrics with SpeedGrader, and provide <span className='ps-4 ms-2'> quick, personalized feedback on students' work. </span> </span></p>
                  <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.25rem", fontWeight: "600" }} > Assign differentiated videos based on <span className='ps-4 ms-2'> MasteryPaths. </span>  </span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-lg-4">
            <div className="row p-lg-5 d-flex justify-content-around ">
              <div className="col-lg-5">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > DATA AND ANALYTICS </p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > 1. Get analytics and insights. </h2>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > 2. Actually use them. </h2>
                  <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.1rem", }} > Find out who’s watching which videos and for how long. See overall class data (this can tell you more about the video), or look at student’s viewing details (this can tell you more about this student). Then use this data to inform your instruction and improve the learning experience. </p>
                </div>
              </div>
              <div className="col-lg-5">
                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/HighCanvasSTUDIO4.png")} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#F2F8FA" }} >
          <div className="container p-lg-5">

            <div className='d-flex justify-content-center text-center'>
              <div className='' style={{ maxWidth: "40%" }} >
                <p className='' style={{ color: "#143d50", fontWeight: "800", letterSpacing: "2px" }} >MORE FEATURES</p>
                <h4 className='SolutionDropDownFontFamily pt-2' style={{ color: "#156380", fontSize: "2.2rem", fontWeight: "700", letterSpacing: "2px" }} > Roll the Canvas Studio credits: </h4>
              </div>
            </div>

            <div className="row p-lg-5 ">
              <div className="col-lg-4 col-md-6 ">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Better Lessons</h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Nothing makes learning feel personal like an educator who creates and curates their own video content.</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Across-the-Board Benefits</h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Deliver, host, and organize video effectively and efficiently across your higher ed institution.</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >An Emphasis on Accessibility</h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Upload and edit caption files and create auto-captions in your choice of 19 languages.</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 ">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Movie-Making Magic</h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Record videos with a webcam or upload content by simply dragging and dropping a file.</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Video Anywhere</h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > With built-in sharing options, you can embed Studio videos anywhere. No other platforms necessary.</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className='p-lg-3'>
                  <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >All Within the Canvas LMS</h6>
                  <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Access, create, upload, and interact with video assets, right inside the Canvas LMS. Powerful. Simple. Streamlined.</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-5">
            <div className="row p-lg-5 ">
              <div className="col-lg-4 col-md-12">
                <div>
                  <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >OUR PRODUCTS</p>
                  <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Discover the Instructure Learning Platform </h2>
                  <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > The Instructure Learning Platform delivers what you need to enrich, support, and connect every aspect of teaching and learning. </p>
                  <div className='pt-4 pb-5'>
                    <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="row">
                  <div className="col-lg">
                    <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Studio</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > It’s the next-generation video learning solution that turns one‑way, passive video into inclusive, engaging, and productive classroom discussions.</p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg pt-5">
                      <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                        <div className='p-3'>
                          <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                          <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Credentials</h6>
                          <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Canvas Credentials empowers educators with more than just a transcript. With verifiable digital credentials, they have a comprehensive learner record to demonstrate progress towards their goals.</p>
                          <div className=''>
                            <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                <div className="row">
                  <div className="col-lg ">
                    <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                      <div className='p-3'>
                        <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                        <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Catalog</h6>
                        <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Catalog is a simple, modern course catalog registration system and branded marketplace for all of your district’s professional development and community resource offerings.</p>
                        <div className=''>
                          <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg pt-5">
                      <div className='p-lg-3  shadow-lg' style={{ backgroundColor: "#fff" }} >
                        <div className='p-3'>
                          <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms3.png")} alt='' />
                          <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Impact by Instructure</h6>
                          <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Improve technology adoption, support teachers and students in their tech use, and evaluate the impact of educational technology on student engagement.</p>
                          <div className=''>
                            <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
          <div className="container p-lg-5 ">
            <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
            <div className="row d-flex justify-content-center p-lg-5">
              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/HighCanvasLMS5.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Product Demos & Videos</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "29px", fontWeight: "700" }} > Canvas Studio Guide: Higher Ed Edition </h4>
                    <div className='pt-lg-5 mt-lg-5'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/HCI2.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Ebooks & Buyer’s Guides</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "29px", fontWeight: "700" }}> Virginia Community College System: Creating Consistency with Canvas LMS </h4>
                    <div className='pt-lg-3 mt-lg-3'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-3 ">
                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                  <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/HCI3.png")} alt='' />
                  <div className='p-3'>
                    <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Product Demos & Videos</h6>
                    <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }}> Priorities for Student Success are Shifting </h4>
                    <div className='pt-lg-5 mt-lg-5'>
                      <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
          <div className="container  p-lg-5 ">
            <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
              <div className='text-lg-center pb-lg-4'>
                <h2 className='pb-2 UbuntuFONTFAMILY' style={{ fontSize: '2.6rem', fontWeight: '800' }} > Ready to set the scene for active video learning? </h2>
                <div className='pt-4 '>
                  <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default HighCanvasStudio