import React, { useState } from 'react'

const HighCanvasLms = () => {

    const [change1, setChange1] = useState(false);
    const [change2, setChange2] = useState(false);
    const [change3, setChange3] = useState(false);
    const [change4, setChange4] = useState(false);
    const [change5, setChange5] = useState(false);
    const [change6, setChange6] = useState(false);

    const clickMe1 = () => {
        setChange1(!change1)
        setChange2(false)
        setChange3(false)
        setChange4(false)
        setChange5(false)
    }
    const clickMe2 = () => {
        setChange1(false)
        setChange2(!change2)
        setChange3(false)
        setChange4(false)
        setChange5(false)
    }
    const clickMe3 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(!change3)
        setChange4(false)
        setChange5(false)
    }

    const clickMe4 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(false)
        setChange4(!change4)
        setChange5(false)
    }

    const clickMe5 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(false)
        setChange4(false)
        setChange5(!change5)
    }

    const clickMe6 = () => {
        setChange1(false)
        setChange2(false)
        setChange3(false)
        setChange4(false)
        setChange5(false)
        setChange6(!change6)
    }

    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#e4060f" }} >
                    <div className="container p-2">
                        <div className="row p-1 text-white d-flex justify-content-between">
                            <div className='col-lg-2'>
                                <h5 className='ps-2' style={{ fontSize: "1.325rem", fontWeight: "600", letterSpacing: "2px" }} >CANVAS</h5>
                            </div>
                            <div className="col-lg-3">
                                <div className='ps-1'>
                                    <h5 className='' style={{ fontSize: "1.125rem", fontWeight: "600" }} > Higher Education Canvas LMS </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container p-lg-5">
                    <div className="row p-lg-5  d-flex justify-content-around ">
                        <div className="col-lg-5">
                            <div>
                                <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > CANVAS LMS FOR HIGHER ED </p>
                                <h2 className='h1FontFamily' style={{ fontSize: "3.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Teaching and Learning. <span className='' style={{ color: "#e4060f", letterSpacing: "1px", fontWeight: "800" }}> To the Power of Canvas LMS. </span></h2>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <p className='mb-5' style={{ fontSize: "1.2rem", color: "#143d50" }}> Canvas LMS is the open, extensible learning management system that amplifies everyone’s awesomeness. </p>
                            <div className='pt-4 '>
                                <button className='SolutionDropDownFontFamily' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#E4060F", border: "none", color: "white", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> REQUEST A DEMO <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                <button className='SolutionDropDownFontFamily shadow-lg ms-2' style={{ borderRadius: "20px 20px 20px 20px", backgroundColor: "#fff", border: "none", color: "red", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> TRY CANVAS FREE <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-5 pt-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Stay ahead of the learning curve with Canvas LMS. </h2>
                                    <p className='pt-3' style={{ color: "#143d50", fontSize: "1.1rem", }} > Deliver dynamic, engaging learning experiences. For every student. Everywhere. Every day. Canvas LMS is a robust digital foundation for all aspects of higher-ed learning. </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='p-2'>
                                    <video autoPlay loop muted className='w-100 d-none d-sm-block ' style={{ height: '270px', objectFit: "fill", borderRadius: "10px 10px 10px 10px" }}  >
                                        <source src={require("../../img/demo1.mp4")} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container p-lg-5">
                        <div className="row p-lg-4 d-flex justify-content-between">
                            <div className="col-lg-5">
                                <img className='imgRESOPNSIVE' src={require("../../img/HighCanvasLMS1.png")} alt='' />
                                <div className='ps-lg-5 ms-lg-2'>
                                    <p className='fontFamilyHeader' style={{ fontSize: "1.40rem", color: '#287a9f', fontWeight: "800", lineHeight: "1.4" }} > " Canvas is kind of the unicorn of LMSs. You don't think it's out there, but then you see it, and you're like, ‘Oh, maybe this is a real thing.’ And for us, it has been. " </p>
                                    <h6 className='fontFamilyHeader pt-2' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "800" }} > — Becky Vasquez </h6>
                                    <h6 className='fontFamilyHeader' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "400" }}> Vice President and Chief Information Officer, Embry-Riddle Aeronautical University, Daytona Beach, FL </h6>
                                </div>
                            </div>
                            <div className=" col-lg-6 p-4 shadow-lg" style={{ backgroundColor: "#fff", borderRadius: "10px 10px 10px 10px" }} >
                                <h2 className='ps-5 p-3 h1FontFamily mt-4' style={{ color: "#156380", fontSize: "2.5rem", lineHeight: "1.2" }} > Welcome to next-level curriculum and instruction. </h2>
                                <div className="table p-4 d-flex justify-content-center">
                                    <div className='w-100 ' >
                                        <div className=' '  >
                                            <div className='w-100 p-0'  >
                                                <button className='text-start bg-body  w-100  p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe1() }}> <h3 className='fs-5'> <b>{change1 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span>  <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className=' ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Everything in One Place </span></b></h3></button>
                                                <div className=''>
                                                    {
                                                        change1 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Engaging course content. Quizzes and grades. Data and insights. Student interaction with educators and peers. All right there in the Canvas LMS. Ready to support in-person, online, and blended learning. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe2() }}><h3 className='fs-5'> <b> {change2 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >  Total Accessibility </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change2 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Student expectations have changed. You need an LMS that can deliver learning that meets students where they are right now. The Canvas LMS ensures that both students and instructors have access to everything they need in one place. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start  w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe3() }}> <h3 className='fs-5'><b> {change3 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ></i></span> : <span> <i className="fa-solid fa-plus " style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>} <span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Interactive Analytics </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change3 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Data can be daunting. Canvas Admin Analytics makes it easy to view Canvas usage, and allows administrators to see and understand the data that matters most to them. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe4() }}><h3 className='fs-5'> <b> {change4 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} >   Instant Content Delivery </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change4 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Quickly upload and share course information in an array of captivating ways. And use Blueprints to push curriculum throughout your institution. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe5() }}><h3 className='fs-5'> <b> {change5 === true ? <span> <i className="fa-solid fa-minus" style={{ color: "#1B6A89", fontWeight: "800" }} ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Mobile Learning </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change5 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > Canvas LMS delivers industry-leading mobile applications combined with full-featured responsive design for unmatched accessibility and assurance that your institution can reach every learner. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='w-100 p-0' >
                                                <button className='text-start   w-100 fs-5 p-2 h1FontFamily' style={{ border: "none", backgroundColor: "#fff" }} onClick={() => { clickMe6() }}><h3 className='fs-5'> <b> {change6 === true ? <span> <i className="fa-solid fa-minus " style={{ color: "#1B6A89", fontWeight: "800" }} ss ></i> </span> : <span> <i className="fa-solid fa-plus" style={{ color: "#E4060F", fontWeight: "800" }} ></i> </span>}<span className='ps-2' style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#5b5b5b", fontWeight: "400" }} > Increased Productivity </span> </b></h3></button>
                                                <div >
                                                    {
                                                        change6 === true ? <p className='p-3 ' style={{ backgroundColor: "#F2F8FA", color: "#444", fontSize: "1.125rem", fontWeight: "400" }} > There’s only one you. Spend your time on teaching and learning. Let the Canvas LMS handle the administrative stuff. </p> : <section>  </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-4">
                        <div className="row p-lg-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > INTEGRATIONS </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Higher education tools that play nicely with others. </h2>
                                    <p className='pt-3 pb-4' style={{ color: "#143d50", fontSize: "1.1rem", }} > Rubrics. Modules. Calendars. Schedules. Quizzes. Syllabi. Analytics. SpeedGrader (cue applause and wild cheering). Canvas LMS is outfitted with smart, intuitive features that will help take learning to the next level. And then there's the integrations! The Canvas API links right up with all your favorite apps and tools: G Suite, Microsoft Teams, Adobe, and hundreds more, in one centralized learning hub.</p>
                                    <div className=''>
                                        <p className='fontFamilyHeader' style={{ fontSize: "1.40rem", color: '#287a9f', fontWeight: "800", lineHeight: "1.4" }} > " We wanted to create consistency across all 23 colleges while still allowing for creativity and academic freedom, and we could do that with Canvas. " </p>
                                        <h6 className='fontFamilyHeader pt-2' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "800" }} > — Page Durham </h6>
                                        <h6 className='fontFamilyHeader' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "400" }}> Instructional Designer, Virginia Community College System, VA </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/HighCanvasLMS2.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-5">
                        <div className="row p-lg-5 d-flex justify-content-around ">

                            <div className="col-lg-5">
                                <img className='w-100 mt-3' src={require("../../img/HighCanvasLMS3.png")} alt='' />
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >MOBILE APPS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > You get an app. And you get an app. And you get an app. </h2>
                                    <p className='pt-2' style={{ color: "#143d50", fontSize: "1.1rem", }} > Canvas LMS has top-rated mobile apps for both teachers and students that give everyone access to just what they need on the go. Teachers can engage with students whenever and wherever through native mobile notifications and Canvas apps that boast best-in-class data security and accessibility—not to mention SpeedGrader, which can cut grading time by 50%. </p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} > Take Canvas anywhere </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} > Grade on the go </span></p>
                                    <p className=''><i class="fa-regular fa-circle-check " style={{ color: "red", fontSize: "22px" }} ></i><span className='ps-2' style={{ color: "#143d50", fontSize: "1.35rem", fontWeight: "600" }} > Stay connected to students </span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-4">
                        <div className="row p-lg-5 d-flex justify-content-around ">
                            <div className="col-lg-5">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} > COLLABORATIVE TOOLS </p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.5rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.1" }} > Dialog, discussion, and discourse. For the digital age. </h2>
                                    <p className='pt-2 ' style={{ color: "#143d50", fontSize: "1.11rem", }} > Teachers can communicate with individual students, groups, or the entire class through messaging, audio notes, video, and more. And students can collaborate amongst themselves via chat group, video, and other messaging tools. </p>
                                    <div className='pt-2'>
                                        <p className='fontFamilyHeader' style={{ fontSize: "1.40rem", color: '#287a9f', fontWeight: "800", lineHeight: "1.4" }} > " Canvas has allowed our university community to create a sustainable and responsive teaching and learning ecosystem that meets the many diverse needs of our students and faculty. " </p>
                                        <h6 className='fontFamilyHeader pt-2' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "800" }} > — Donalee Attardo </h6>
                                        <h6 className='fontFamilyHeader' style={{ fontSize: "1rem", color: '#287a9f', fontWeight: "400" }}> Senior Director, Academic Technology, University of Minnesota, Minneapolis-Saint Paul, MN </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img className='w-100 ' style={{ borderRadius: "10px 10px 10px 10px" }} src={require("../../img/HighCanvasLMS4.png")} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#F2F8FA" }} >
                    <div className="container p-lg-5">

                        <div className='d-flex justify-content-center text-center'>
                            <div className='' style={{ maxWidth: "40%" }} >
                                <p className='' style={{ color: "#143d50", fontWeight: "800", letterSpacing: "2px" }} >MORE FEATURES</p>
                                <h4 className='SolutionDropDownFontFamily pt-2' style={{ color: "#156380", fontSize: "2.2rem", fontWeight: "700", letterSpacing: "2px" }} >All the features you need. None you don’t.</h4>
                            </div>
                        </div>

                        <div className="row p-lg-5 ">
                            <div className="col-lg-4 col-md-6 ">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Undivided Attention</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Enrich lessons with video and audio, and even include face-to-face interactions. All from within Canvas.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >99.9% Uptime</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > A down LMS can really grind your teaching and learning gears. Canvas keeps your educational ecosystem going strong.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Grades at Lightning Speed</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Offer targeted feedback with annotations. Use rubrics for scoring. Share comments via video. Post grades to the Gradebook and quickly create eye-opening reports about student progress.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Unlimited File Size</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > If it will enrich your students’ learning experiences, in it goes. Add as much audio, visuals, and video content as you like.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Insights All Around</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > Course analytics serve up data that offer insights on students' interactions with instructional materials—useful info when it comes to adapting instruction.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='p-lg-3'>
                                    <h6 className='mt-3 SolutionDropDownFontFamily' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Cloud-Based <br />(and Born)</h6>
                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.125rem", }} > That means no downtime due to upgrades. Simply log in for instant connection from anywhere—on just about any computer or smart handheld device.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="conatiner-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-5">
                        <div className="row p-lg-5 ">
                            <div className="col-lg-4 col-md-12">
                                <div>
                                    <p className='' style={{ fontSize: ".875rem", color: "#143d50", letterSpacing: "1px", fontWeight: "800" }} >EXPLORE ALL PRODUCTS</p>
                                    <h2 className='h1FontFamily' style={{ fontSize: "2.7rem", color: "#287a9f", fontWeight: "800", lineHeight: "1.2" }} > Explore the Entire Canvas Product Family </h2>
                                    <p className='pt-3' style={{ color: "#143d50", fontSize: "1.40rem", lineHeight: "1.3" }} > Simplify teaching and learning activities, organize coursework and keep teachers, students, and families connected and communicating. Anytime, anywhere.</p>
                                    <div className='pt-4 pb-5'>
                                        <button className='UbuntuFONTFAMILY shadow-lg' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> EXPLORE ALL PRODUCTS  <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="row">
                                    <div className="col-lg">
                                        <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Studio</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > It’s the next-generation video learning solution that turns one‑way, passive video into inclusive, engaging, and productive classroom discussions.</p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg pt-5">
                                            <div className='p-lg-3 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                                <div className='p-3'>
                                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Credentials</h6>
                                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Canvas Credentials empowers educators with more than just a transcript. With verifiable digital credentials, they have a comprehensive learner record to demonstrate progress towards their goals.</p>
                                                    <div className=''>
                                                        <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg pt-5">
                                            <div className='p-lg-3  shadow-lg' style={{ backgroundColor: "#fff" }} >
                                                <div className='p-3'>
                                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/ms3.png")} alt='' />
                                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Impact by Instructure</h6>
                                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Improve technology adoption, support teachers and students in their tech use, and evaluate the impact of educational technology on student engagement.</p>
                                                    <div className=''>
                                                        <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6  pt-lg-5 mt-lg-5">
                                <div className="row">
                                    <div className="col-lg ">
                                        <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                            <div className='p-3'>
                                                <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Catalog</h6>
                                                <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Catalog is a simple, modern course catalog registration system and branded marketplace for all of your district’s professional development and community resource offerings.</p>
                                                <div className=''>
                                                    <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg ">
                                            <div className='p-lg-3 pt-5 mt-5 shadow-lg' style={{ backgroundColor: "#fff" }} >
                                                <div className='p-3'>
                                                    <img className=' mt-3' style={{ height: "3.2rem" }} src={require("../../img/icon.png")} alt='' />
                                                    <h6 className='mt-3 UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "1.975rem", fontWeight: "800" }} >Canvas Student Pathways</h6>
                                                    <p className='mt-3 SolutionDropDownFontFamily' style={{ color: "#143d50", fontSize: "1.25rem", }} > Custom, stackable pathways that help students navigate their academic and co-curricular journeys and provide them with a road map for acquiring new skills. </p>
                                                    <div className=''>
                                                        <button type="button" class="btn btn-link text-decoration-none" style={{ color: "red", fontWeight: "700" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }} >
                    <div className="container p-lg-5 ">
                        <h2 className='text-center UbuntuFONTFAMILY' style={{ color: "#156380", fontSize: "2.5rem", fontWeight: "800" }} >The Latest From Instructure</h2>
                        <div className="row d-flex justify-content-center p-lg-5">
                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/HighCanvasLMS5.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }} >Product Demos & Videos</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "29px", fontWeight: "700" }} > Canvas Across the Globe </h4>
                                        <div className='pt-lg-5'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/HCI2.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Ebooks & Buyer’s Guides</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "29px", fontWeight: "700" }}>Your Guide to Choosing a Learning Management System</h4>
                                        <div className='pt-3'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-3 ">
                                <div className='h-100 shadow-lg SolutionDropDownFontFamily' style={{ borderRadius: "10px 10px 10px 10px" }} >
                                    <img className='w-100' style={{ borderRadius: "10px 10px 0px 0px", height: "12rem" }} src={require("../../img/HCI3.png")} alt='' />
                                    <div className='p-3'>
                                        <h6 className='p-2' style={{ lineHeight: "1.5", color: "#4e6f7e", fontSize: "16px", fontWeight: "bold" }}>Product Demos & Videos</h6>
                                        <h4 className='p-2' style={{ lineHeight: "1.1", color: "#143d50", fontSize: "27px", fontWeight: "700" }}> California Community Colleges </h4>
                                        <div className='pt-lg-5 mt-2'>
                                            <button type="button" class="btn btn-link text-decoration-none " style={{ color: "#e4060f", fontWeight: "600" }} > READ MORE <i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#176684" }} >
                    <div className="container  p-lg-5 ">
                        <div className="row pt-lg-5 mt-lg-5 pb-lg-4 mb-lg-5 p-3 text-white">
                            <div className='text-lg-center pb-lg-4'>
                                <h2 className='pb-2 UbuntuFONTFAMILY' style={{ fontSize: '2.6rem', fontWeight: '800' }} >Ready to explore our learning ecosystem?</h2>
                                <div className='pt-4 '>
                                    <button className='UbuntuFONTFAMILY' style={{ borderRadius: "20px 20px 20px 20px", backgroundImage: "linear-gradient(90deg,#f2f8fa 0%,#fff 50%)", border: "none", color: "#e4060f", padding: "10px 15px" }} ><b className='p-2' style={{ fontSize: "1rem", fontWeight: "400" }}> LET'S GET STARTED <i class="ms-3 fa-sharp fa-solid fa-arrow-right"></i></b></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HighCanvasLms